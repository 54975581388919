import { CommonModule } from '@angular/common';
import { RowOutletDirective } from './directives/row-outlet.directive';
import { FooterRowOutletDirective } from './directives/footer-row-outlet.directive';
import { HeaderRowOutletDirective } from './directives/header-row-outlet.directive';
import { ItrCellDefDirective } from './directives/itr-cell-def.directive';
import { ItrCellDirective } from './directives/itr-cell.directive';
import { ItrCellOutletDirective } from './directives/itr-cell-outlet.directive';
import { ItrColumnDefDirective } from './directives/itr-column-def.directive';
import { ItrFooterCellDefDirective } from './directives/itr-footer-cell-def.directive';
import { ItrFooterCellDirective } from './directives/itr-footer-cell.directive';
import { ItrFooterRowComponent } from './components/itr-footer-row.component';
import { ItrFooterRowDefDirective } from './directives/itr-footer-row-def.directive';
import { ItrHeaderCellComponent } from './components/itr-header-cell/itr-header-cell.component';
import { ItrHeaderCellDefDirective } from './directives/itr-header-cell-def.directive';
import { ItrHeaderRowComponent } from './components/itr-header-row.component';
import { ItrHeaderRowDefDirective } from './directives/itr-header-row.directive';
import { ItrRowComponent } from './components/itr-row.component';
import { ItrRowDefDirective } from './directives/itr-row-def.directive';
import { ItrVirtualScrollComponent } from './components/itr-virtual-scroll/itr-virtual-scroll.component';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ItrTableComponent } from './components/itr-table/itr-table.component';
import { ITableModule } from '../../components/table-filter/i-table.module';
import { ItrHeaderService } from './services/itr-header.service';
import { TranslateModule } from '@ngx-translate/core';

const EXPORTED_DECLARATIONS = [
  RowOutletDirective,
  FooterRowOutletDirective,
  HeaderRowOutletDirective,
  ItrCellDefDirective,
  ItrCellDirective,
  ItrCellOutletDirective,
  ItrColumnDefDirective,
  ItrFooterCellDefDirective,
  ItrFooterCellDirective,
  ItrFooterRowComponent,
  ItrFooterRowDefDirective,
  ItrHeaderCellComponent,
  ItrHeaderCellDefDirective,
  ItrHeaderRowComponent,
  ItrHeaderRowDefDirective,
  ItrRowComponent,
  ItrRowDefDirective,
  ItrTableComponent,
  ItrVirtualScrollComponent
];

@NgModule({
  imports: [CommonModule, NgScrollbarModule, ITableModule, TranslateModule],
  exports: [EXPORTED_DECLARATIONS, TranslateModule],
  declarations: EXPORTED_DECLARATIONS,
  providers: [ItrHeaderService]
})
export class ItrTableModule {}
