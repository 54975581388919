import {
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  IterableDiffers, OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { DefaultNodeComponent, EngineService, MODEL, RxZuDiagramComponent } from "@rxzu/angular";
import { MonDisplayModel } from "../../models";
import { TagsQuery } from "../../state/tags.query";
import { Subject } from "rxjs";
import { IMarkerTag } from "@itorum/models";
import { TagsService } from "../../state/tags.service";
import { WsQuery } from "../../state/ws.query";
import { map, takeUntil, tap } from "rxjs/operators";
import { TagsState } from "../../state/tags.store";

@Component({
  selector: "itorum-mon-display",
  templateUrl: "./mon-display.component.html",
  styleUrls: ["./mon-display.component.scss"]
})
export class MonDisplayComponent extends DefaultNodeComponent implements OnInit, OnDestroy {

  @ViewChild("portsLayer", { read: ViewContainerRef, static: true }) portsLayer!: ViewContainerRef;

  isShowSearchSources = false;
  private destroy$ = new Subject();
  selectedTags: IMarkerTag[] = [];
  myValue: number;
  private tagsState: TagsState;
  autoGenerated = false;
  autogen: number;
  private autogenIndex;

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    console.log("MonDisplayComponent onmousedown =>", $event);
    $event.restrict_drawing = true;
  }

  constructor(
    @Inject(MODEL) public model: MonDisplayModel,
    engine: EngineService,
    diagram: RxZuDiagramComponent,
    iterableDiffers: IterableDiffers,
    private cd: ChangeDetectorRef,
    public tagsQuery: TagsQuery,
    private tagsService: TagsService,
    private wsQuery: WsQuery
  ) {
    super(model, engine, diagram, iterableDiffers);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.tagsQuery.getTagsState()
      .pipe(
        takeUntil(this.destroy$),
        tap(console.log))
      .subscribe((tags: TagsState) => {
        this.tagsState = tags;
        if (!this.selectedTags.length) {
          const match = this.tagsState.free.find(t => t.id === +this.model.id);
          this.selectedTags = match ? [match] : [];
          this.cd.detectChanges();
        }
      });

    console.log("MonDisplayModel =>", this.model);
    console.log("MonDisplayModel.id =>", this.model.id);
    this.wsQuery.getWsTagState(+this.model.id)
      .pipe(map(a => a?.value))
      .subscribe(val => {
        this.myValue = val;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.clearAutoGenerated();
  }

  addObservable() {
    // alert('show search list');
    this.isShowSearchSources = true;

  }

  addTag($event: IMarkerTag) {
    this.tagsService.captureTag($event);
    this.selectedTags = [...this.selectedTags, $event];
    this.autoGenerated = true;
    this.setupAutoGenerated(this.selectedTags[0]);
    this.isShowSearchSources = false;
    this.cd.detectChanges();
  }

  releaseSelected(i: number) {
    this.tagsService.releaseTag(this.selectedTags[i]);
    this.selectedTags = this.selectedTags.filter((_, index) => index !== i);
    this.cd.detectChanges();
  }

  releaseAllSelected() {
    this.selectedTags.map(this.tagsService.releaseTag);
    this.selectedTags = [];
    this.cd.detectChanges();
  }

  private setupAutoGenerated(tag: IMarkerTag) {

    const makeItGood = (top: number, bott: number) => {
      const rnd = +(top * Math.random()).toFixed(0);
      return rnd > top ? top : rnd < bott ? bott : rnd;
    };

    this.autogenIndex = setInterval(() => {

      switch (tag.id) {
        case 1: { // Мощность
          this.autogen = makeItGood(80, 60);
          break;
        }
        case 2: { // Суммарный КПД
          this.autogen = makeItGood(80, 60);
          break;
        }
        case 3: { // Ресурс до капительного ремонта
          this.autogen = makeItGood(4500, 500);
          break;
        }
        case 4: { // Установленная мощность
          this.autogen = makeItGood(4500, 3500);
          break;
        }
        case 5: { // Электрический КПД
          this.autogen = makeItGood(60, 40);
          break;
        }
        case 6: { // Температура выхлопных газов
          this.autogen = makeItGood(1000, 700);
          break;
        }
        case 7: { // Установленная тепловая мощность
          this.autogen = makeItGood(500, 300);
          break;
        }
        case 8: { // Напряжение на Двиг
          this.autogen = makeItGood(700, 400);
          break;
        }
        case 9: { // Сопративление на резисторе AX-1
          this.autogen = makeItGood(70, 30);
          break;
        }
        case 10: { // Сопративление на резисторе AX-2
          this.autogen = makeItGood(70, 30);
          break;
        }
        case 11: { // Сопративление на резисторе AX-3
          this.autogen = makeItGood(70, 30);
          break;
        }
        case 12: { // Скорость обработки
          this.autogen = makeItGood(4000, 3500);
          break;
        }
        case 13: { // Скорость вращения вала
          this.autogen = makeItGood(4000, 3500);
          break;
        }
      }

    }, 2000);
  }

  private clearAutoGenerated() {
    clearInterval(this.autogenIndex);
  }
}
