import { ObjectInspection } from './objects.model';
import { ObjectCompetency } from './object-competency.model';
import { ObjectType } from './object-type.model';
import { dateToBackend } from "../utils";
import { User } from './user.model';
import * as moment from 'moment';

interface ITaskCommonResponse {
  task_id: number;
  task_name: string;
  task_code: string;
  task_desc: string;
  start_date: string;
  end_date: string;
  create_date: string;
  update_date: string;
  complete_date: string;
  designated_date: string;
  source_task_id: number;
  task_status_id: number;
  task_status_name: string;
  competency_id: number;
  is_template: boolean;
  commission: number[];
  object_id: number;
  object_obj: {
    object_id: number;
    object_code: string;
    object_name: string;
  };
  object_type_id: number;
  object_type_obj?: {
    object_type_id: number;
    object_type_code: string;
    object_type_name: string;
  };
  object_types?: ObjectType;
  technik_obj?: | {
        user_id: number;
        first_name: string;
        middle_name?: string;
        last_name?: string;
      }
    | any;
  create_user_obj: {
    user_id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
  };
  dim_competency: ObjectCompetency;
  equipment: string;
  location: string;
}

// tslint:disable-next-line:no-empty-interface
type IGetTaskListResponse = ITaskCommonResponse

interface IGetTaskResponse extends ITaskCommonResponse {
  qr_scan: boolean;
  manager_obj: {
    user_id: number;
    user_name: string;
  };
}

interface ITaskFormData {
  task_code: string;
  task_name: string;
  task_desc: string;
  qr_scan?: boolean;
  competency_id: number;
  is_template: boolean;
  commission: number[];
  source_task_id: number;
  object_id: number;
  object_type_id: number;
  technik_id: number;
  dateRange: [Date, Date];
}

interface ITaskRequestCreate {
  task_code: string;
  task_name: string;
  task_desc: string;
  qr_scan: boolean;
  start_date: string;
  end_date: string;
  object_id: number;
  technik_id: number;
  competency_id: number;
  commission: number[];
  source_task_id: number;
}

interface ITaskRequestUpdate extends ITaskRequestCreate {
  task_id: number;
  taskId: number;
}

export enum ETaskStatus {
  new = 1, // 'Новая'
  inWork = 2, // 'В работе'
  inPerformed = 3, // 'Выполняется'
  completed = 4, // 'Выполнена'
  delayed = 5, // 'Просрочена'
  declined = 6, // 'Отклонена'
  moved = 7, // 'Перенесена'
  onSigning = 8, // 'На подписи'
  defectsCorrection = 9, // 'Исправление дефектов'
  finalSigning = 10, // 'На финальной подписи'
  inArchive = 11, // 'В архиве'
  draft = 12, // 'Черновик'
  issued = 13 // 'Оформлен'
}

export const StatusChangeAllowed: ETaskStatus[] = [
  ETaskStatus.completed,
  ETaskStatus.onSigning,
  ETaskStatus.defectsCorrection,
  ETaskStatus.finalSigning
];

export const StatusDeclineAllowed: ETaskStatus[] = [
  ETaskStatus.new,
  ETaskStatus.moved
];

export const SetStatusList: { id: ETaskStatus; display: string }[] = [
  { id: ETaskStatus.completed, display: 'Выполнена' },
  { id: ETaskStatus.onSigning, display: 'На подписи' },
  { id: ETaskStatus.defectsCorrection, display: 'Исправление дефектов' },
  { id: ETaskStatus.finalSigning, display: 'На финальной подписи' },
  { id: ETaskStatus.inArchive, display: 'В архиве' }
];

function formatDate(date, format = 'DD.MM.YYYY') {
  return moment(date).format(format);
}

export class TaskInspection {
  public task_id: number; // fixme wtf? why it duplicates?
  public task_code: string;
  public task_name: string;
  public task_desc: string;
  public qr_scan: boolean;
  public source_task_id: number;
  public taskId?: number; // fixme wtf? why it duplicates?
  public start_date: string;
  public end_date: string;
  public create_date: string;
  public create_date_format: string;
  public update_date: string;
  public update_date_format: string;
  public planet_date_format: string;
  public complete_date: string;
  public complete_date_format: string;
  public designated_date: string;
  public object_id: number;
  public object_type_id: number;
  public object_types?: ObjectType;
  public equipment: string;
  public location: string;

  public is_template: boolean;
  public competency_id: number;
  public commission: number[];

  public user: User = new User({});
  public userShortName: string;
  public inspectObject: ObjectInspection = new ObjectInspection({});
  public task_status_id: number;
  public task_status_name: string;
  public manager: User = new User({});
  public create_user: User = new User({});
  public dim_competency: ObjectCompetency;

  constructor(data?: {
    task_id: number;
    taskId?: number;
    task_code: string;
    task_name: string;
    task_desc: string;
    start_date: string;
    competency_id: number;
    commission: number[];
    object_id: number;
    object_type_id: number;
    end_date: string;
    create_date: string;
    update_date: string;
    designated_date: string;
    complete_date: string;
    task_status_id: number;
    task_status_name: string;
    qr_scan?: boolean;
    source_task_id?: number;
    is_template: boolean;
    equipment: string;
    location: string;
  }) {
    if (data) {
      this.updateData(data);
    }
    if (!this.task_status_name) {
      this.status = ETaskStatus.new;
    }
  }

  static fromGetTaskListResponse(data: IGetTaskListResponse): TaskInspection {
    const task = new TaskInspection(data);
    data.technik_obj = data.technik_obj ? data.technik_obj : {};
    (<any>data.object_obj) = data.object_obj ? data.object_obj : {};
    (<any>data.object_type_obj) = data.object_type_obj
      ? data.object_type_obj
      : {};
    (<any>data.create_user_obj) = data.create_user_obj
      ? data.create_user_obj
      : {};

    task.user = new User(data.technik_obj);
    task.create_user = new User(data.create_user_obj);
    task.userShortName = task.user.shortName;
    task.inspectObject = new ObjectInspection({
      object_id: data.object_obj ? data.object_obj.object_id : null,
      object_code: data.object_obj ? data.object_obj.object_code : null,
      object_name: data.object_obj ? data.object_obj.object_name : null,

      object_type_id: data.object_type_id ? data.object_type_id : null,
      object_type_code: data.object_type_obj
        ? data.object_type_obj.object_type_code
        : null,
      object_type_name: data.object_type_obj
        ? data.object_type_obj.object_type_name
        : null
    });
    task.dim_competency = new ObjectCompetency(
      data.dim_competency ? data.dim_competency : <any>{}
    );
    task.object_types = new ObjectType(
      data.object_types ? data.object_types : <any>{}
    );
    return task;
  }

  static fromGetTaskResponse(data: IGetTaskResponse): TaskInspection {
    const task = new TaskInspection(data);
    data.technik_obj = data.technik_obj ? data.technik_obj : {};
    (<any>data.object_obj) = data.object_obj ? data.object_obj : {};
    (<any>data.object_type_obj) = data.object_type_obj
      ? data.object_type_obj
      : {};
    task.user = new User(data.technik_obj);
    task.manager = new User(data.manager_obj);

    task.inspectObject = new ObjectInspection({
      object_id: data.object_obj ? data.object_obj.object_id : null,
      object_code: data.object_obj ? data.object_obj.object_code : null,
      object_name: data.object_obj ? data.object_obj.object_name : null,

      object_type_id: data.object_type_id ? data.object_type_id : null,
      object_type_code: data.object_type_obj
        ? data.object_type_obj.object_type_code
        : null,
      object_type_name: data.object_type_obj
        ? data.object_type_obj.object_type_name
        : null
    });
    task.dim_competency = new ObjectCompetency(
      data.dim_competency ? data.dim_competency : <any>{}
    );
    task.object_types = new ObjectType(
      data.object_types ? data.object_types : <any>{}
    );
    return task;
  }

  private updateData(data: {
    task_id?: number;
    taskId?: number;
    task_code: string;
    task_name: string;
    task_desc: string;
    competency_id: number;
    is_template: boolean;
    commission: number[];
    object_id: number;
    object_type_id: number;
    start_date?: string;
    end_date?: string;
    create_date?: string;
    update_date?: string;
    complete_date?: string;
    designated_date?: string;
    task_status_id?: number;
    task_status_name?: string;
    qr_scan?: boolean;
    source_task_id?: number;
    equipment?: string;
    location?: string;
  }) {
    this.task_id = (data.task_id && +data.task_id) || this.task_id;
    this.taskId = (data.task_id && +data.task_id) || this.task_id;
    this.task_code =
      typeof data.task_code === 'undefined' ? this.task_code : data.task_code;
    this.task_name =
      typeof data.task_name === 'undefined' ? this.task_name : data.task_name;
    this.task_desc =
      typeof data.task_desc === 'undefined' ? this.task_desc : data.task_desc;
    this.competency_id =
      typeof data.competency_id === 'undefined'
        ? this.competency_id
        : data.competency_id;
    this.is_template =
      typeof data.is_template === 'undefined'
        ? this.is_template
        : data.is_template;
    this.object_id =
      typeof data.object_id === 'undefined' ? this.object_id : data.object_id;
    this.object_type_id =
      typeof data.object_type_id === 'undefined'
        ? this.object_type_id
        : data.object_type_id;
    this.commission =
      typeof data.commission === 'undefined'
        ? this.commission
        : data.commission;
    this.task_status_id =
      typeof data.task_status_id === 'undefined'
        ? this.task_status_id
        : data.task_status_id;
    this.task_status_name =
      typeof data.task_status_name === 'undefined'
        ? this.task_status_name
        : data.task_status_name;
    this.start_date =
      typeof data.start_date === 'undefined'
        ? this.start_date
        : data.start_date;
    this.end_date =
      typeof data.end_date === 'undefined' ? this.end_date : data.end_date;
    this.create_date =
      typeof data.create_date === 'undefined'
        ? this.create_date
        : data.create_date;
    this.create_date_format = this.create_date
      ? formatDate(this.create_date)
      : '';
    this.update_date =
      typeof data.update_date === 'undefined'
        ? this.update_date
        : data.update_date;
    this.update_date_format = this.update_date
      ? formatDate(this.update_date)
      : '';
    this.planet_date_format =
      this.start_date && this.end_date
        ? `${formatDate(this.start_date)} - ${formatDate(this.end_date)}`
        : null;
    this.complete_date =
      typeof data.complete_date === 'undefined'
        ? this.complete_date
        : data.complete_date;
    this.complete_date_format = this.complete_date
      ? formatDate(this.complete_date)
      : '-';
    this.designated_date = data.designated_date
      ? new Date(data.designated_date).toISOString()
      : null;
    this.qr_scan =
      typeof data.qr_scan === undefined ? this.qr_scan : !!data.qr_scan;
    this.source_task_id = data.source_task_id;
    this.equipment =
      typeof data.equipment === 'undefined' ? this.equipment : data.equipment;
    this.location =
      typeof data.location === 'undefined' ? this.location : data.location;
  }

  public get dateRange(): [Date, Date] {
    if (!this.start_date && !this.end_date) {
      return null;
    }
    return [new Date(this.start_date), new Date(this.end_date)];
  }

  public set dateRange(dateRange: [Date, Date]) {
    if (Array.isArray(dateRange) && dateRange.length > 1) {
      this.start_date = isNaN(dateRange[0].getTime())
        ? null
        : dateRange[0].toISOString();
      this.end_date = isNaN(dateRange[1].getTime())
        ? null
        : dateRange[1].toISOString();
    } else {
      this.start_date = null;
      this.end_date = null;
    }
  }

  public get status(): ETaskStatus {
    switch (this.task_status_name) {
      case 'В работе':
        return ETaskStatus.inWork;
      case 'Исполняется':
        return ETaskStatus.inPerformed;
      case 'Выполнена':
        return ETaskStatus.completed;
      case 'Просрочена':
        return ETaskStatus.delayed;
      case 'Отклонена':
        return ETaskStatus.declined;
      case 'Перенесена':
        return ETaskStatus.moved;
      case 'На подписи':
        return ETaskStatus.onSigning;
      case 'Исправление дефектов':
        return ETaskStatus.defectsCorrection;
      case 'На финальной подписи':
        return ETaskStatus.finalSigning;
      case 'В архиве':
        return ETaskStatus.inArchive;
      case 'Новая':
      default:
        return ETaskStatus.new;
    }
  }

  public set status(status: ETaskStatus) {
    switch (status) {
      case ETaskStatus.new:
        this.task_status_name = 'Новая';
        break;
      case ETaskStatus.inWork:
        this.task_status_name = 'В работе';
        break;
      case ETaskStatus.inPerformed:
        this.task_status_name = 'Исполняется';
        break;
      case ETaskStatus.completed:
        this.task_status_name = 'Выполнена';
        break;
      case ETaskStatus.delayed:
        this.task_status_name = 'Просрочена';
        break;
      case ETaskStatus.declined:
        this.task_status_name = 'Отклонена';
        break;
      case ETaskStatus.moved:
        this.task_status_name = 'Перенесена';
        break;
      case ETaskStatus.onSigning:
        this.task_status_name = 'На подписи';
        break;
      case ETaskStatus.defectsCorrection:
        this.task_status_name = 'Исправление дефектов';
        break;
      case ETaskStatus.finalSigning:
        this.task_status_name = 'На финальной подписи';
        break;
      case ETaskStatus.inArchive:
        this.task_status_name = 'В архиве';
        break;
      case ETaskStatus.draft:
        this.task_status_name = 'Черновик';
        break;
      case ETaskStatus.issued:
        this.task_status_name = 'Оформлен';
        break;
    }
  }

  public updateByFormData(data: ITaskFormData) {
    this.updateData(data);
    this.dateRange = data.dateRange;
    this.inspectObject.object_id = data.object_id;
    this.inspectObject.object_type_id = data.object_type_id;
    this.user.user_id = data.technik_id;
  }

  public requestCreate(): any {
    return {
      task_code: this.task_code,
      task_name: this.task_name,
      task_desc: this.task_desc,
      qr_scan: this.qr_scan,
      start_date:
        this.start_date && dateToBackend(new Date(this.start_date), 'dayStart'),
      end_date:
        this.end_date && dateToBackend(new Date(this.end_date), 'dayEnd'),
      designated_date: this.designated_date,
      object_id: this.inspectObject.object_id,
      object_type_id: this.inspectObject.object_type_id,
      technik_id: this.user.user_id,
      competency_id: this.competency_id,
      commission: this.commission,
      source_task_id: this.source_task_id,
      is_template: this.is_template,
      equipment: this.equipment,
      location: this.location
    };
  }

  public requestUpdate(): ITaskRequestUpdate {
    const request = <ITaskRequestUpdate>this.requestCreate();
    request.task_id = this.task_id;
    request.taskId = this.task_id;
    return request;
  }
}
