import { fullFieldsToChar } from "../utils";

export interface ObjectClassResponseInterface {
  object_class_id: number;
  object_class_code: string;
  object_class_name: string;
  object_class_name_2: string;
  delete_flag: boolean;
}

export class ObjectClass {
  object_class_id: number;
  object_class_code: string;
  object_class_name: string;
  object_class_name_2: string;
  delete_flag: boolean;

  constructor(obj?: ObjectClassResponseInterface) {
    if (!obj) {
      return;
    }
    this.object_class_id = obj.object_class_id;
    this.object_class_code = obj.object_class_code;
    this.object_class_name = obj.object_class_name;
    this.object_class_name_2 = obj.object_class_name_2;
    this.delete_flag = obj.delete_flag ? obj.delete_flag : false;
  }

  get object_class_full() {
    return fullFieldsToChar(
      [
        this.object_class_code,
        this.object_class_name,
        this.object_class_name_2
      ],
      '/'
    );
  }
}
