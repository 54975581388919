import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { DefaultLinkComponent, LabelModel, LinkModel, MODEL } from "@rxzu/angular";

@Component({
  selector: 'itorum-mon-link',
  templateUrl: './mon-link.component.html',
  styleUrls: ['./mon-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonLinkComponent extends DefaultLinkComponent implements OnInit {
  @ViewChild('labelLayer', { read: ViewContainerRef, static: true }) labelLayer!: ViewContainerRef;

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    console.log('MonLinkComponent onmousedown =>', $event);
    $event.restrict_drawing = true;
  }

  constructor(
    @Inject(MODEL) public model: LinkModel,
    cdRef: ChangeDetectorRef
  ) {
    super(model, cdRef);
    this.model.setLabel(new LabelModel({ namespace: 'monitorHelper' }));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
