class TechnicInitializer {
  technik_id: number;
  technik_fio: string;
  email: string;
  status_name: string;
  phone: string;
  manager_names: string[];

  constructor(obj) {
    if (obj['technik_id'] && typeof obj['technik_id'] === 'number') {
      this.technik_id = obj['technik_id'];
    }
    if (obj['technik_fio'] && typeof obj['technik_fio'] === 'string') {
      this.technik_fio = obj['technik_fio'];
    }
    if (obj['email'] && typeof obj['email'] === 'string') {
      this.email = obj['email'];
    }
    if (obj['status'] && typeof obj['status'] === 'string') {
      this.status_name = obj['status'];
    }
    if (obj['phone'] && typeof obj['phone'] === 'string') {
      this.phone = obj['phone'];
    } else {
      this.phone = '';
    }
    if (obj['manager_fio'] && typeof obj['manager_fio'] === 'string') {
      this.manager_names = obj['manager_fio'].split(', ');
    } else {
      this.manager_names = [];
    }
  }
}

export class Technic extends TechnicInitializer {
  constructor(user) {
    super(user);
  }
}
