import { Component, HostBinding, NgZone, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ToastPackage, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import { decode } from 'html-entities';

@Component({
  selector: 'itorum-toastr-invite-dialog',
  templateUrl: './toastr-invite-dialog.component.html',
  styleUrls: ['./toastr-invite-dialog.component.scss'],
  // animations: [
  //   trigger('flyInOut', [
  //     state('inactive', style({ opacity: 0 })),
  //     state('active', style({ opacity: 1 })),
  //     state('removed', style({ opacity: 0 })),
  //     transition(
  //       'inactive => active',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     ),
  //     transition(
  //       'active => removed',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     )
  //   ])
  // ],
})
export class ToastrInviteDialogComponent implements OnInit {
  timeout: any;
  private bell: HTMLAudioElement;
  text: any;
  title: any;

  // /** controls animation */
  // @HostBinding('@flyInOut')
  // state = {
  //   value: 'inactive',
  //   params: {
  //     easeTime: this.toastPackage.config.easeTime,
  //     easing: 'ease-in'
  //   }
  // };
  private janus_msg: any;
  private sfutest: any;
  // private join: Function;

  constructor(
    private router: Router,
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    protected ngZone?: NgZone
  ) {
    this.text = toastPackage.message;
    // console.log('this.text', this.text);
    // console.log('this.text decoded', decode(this.text));
    this.title = toastPackage.title;
  }

  ngOnInit(): void {
  }

  outsideTimeout(func: () => any, timeout: number) {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(
        () =>
          (this.timeout = setTimeout(
            () => this.runInsideAngular(func),
            timeout
          ))
      );
    } else {
      this.timeout = setTimeout(() => func(), timeout);
    }
  }

  okay() {
    // alert('OK');
    this.onInviteAccept(this.janus_msg);
    this.remove();
  }

  cancel() {
    // alert('Cancel');
    this.onInviteReject(this.janus_msg);
    this.remove();
  }

  /**
   * tells toastrService to remove this toast after animation time
   */
  remove() {
    // if (this.state.value === 'removed') {
    //   return;
    // }
    clearTimeout(this.timeout);
    // this.state = { ...this.state, value: 'removed' };
    this.outsideTimeout(
      () => this.toastrService.remove(this.toastPackage.toastId),
      +this.toastPackage.config.easeTime
    );
  }

  private runInsideAngular(func: () => any) {
    if (this.ngZone) {
      this.ngZone.run(() => func());
    } else {
      func();
    }
  }

  /**
   * set janus objects to respond out
   * @param msg
   * @param sfutest
   * @param {HTMLAudioElement} bell ringing on site;
   */
  setData(msg, sfutest, bell) {
    this.janus_msg = msg;
    this.sfutest = sfutest;
    this.bell = bell;

    this.sendInviteAck(msg);
  }

  private sendInviteAck(message) {
    console.log('========sendInviteAck======', message);
    this.bell.play();
    if (!message) {
      console.error('no message passed into sendInviteAck');
      return;
    }
    const inviteAck = {
      control: 'inviteAck',
      room: message.room,
      uid: message.uid
    };

    this.sfutest.send({ message: inviteAck });
  }

  private onInviteAccept(message) {
    console.log('========sendInviteAccept======', message);
    this.bell.pause();
    if (!message) {
      console.error('no message passed into onInviteAccept');
      return;
    }

    if (!message.room) {
      console.error('no room id accepted');
      return;
    }
    this.bell.pause();
    // this.join(+message.room); // todo make test
    const inviteAccept = {
      control: 'inviteAccept',
      room: +message.room,
      uid: message.uid
    };

    this.sfutest.send({ message: inviteAccept });
    this.router.navigateByUrl(`/main/conference/room/${message.room}`);
  }

  private onInviteReject = message => {
    console.log('========sendInviteReject======', message);
    this.bell.pause();
    if (!message) {
      console.error('no message passed into onInviteReject');
      return;
    }
    this.bell.pause();
    const inviteReject = {
      control: 'inviteReject',
      room: message.room,
      uid: message.uid
    };

    this.sfutest.send({ message: inviteReject });
  }

}

