/**
 * Created by hazarin on 18.04.18.
 */
import { ObjectType } from './object-type.model';
import { ObjectWbsTitle } from './object-wbs-title.model';
import { ObjectZone } from './object-zone.model';

/**
 * @deprecated
 */
abstract class ObjectsInitializer {
  parent_object_id: number;
  parent_object_code: string;
  parent_object_name: string;
  object_id: number;
  object_code: string;
  object_name: string;
  object_full_name?: string;
  object_name_2?: string;
  sketch?: string;
  object_class_id?: number;
  title_id?: number;
  zone_id?: number;
  object_class_name?: string;
  object_desc: string;
  object_label: string;
  object_label_id = 'Не задан';
  object_label_name: string;
  /** @deprecated use objectType */
  object_type_id: number;

  /** @deprecated use objectType */
  object_type_code: string;

  /** @deprecated use objectType */
  object_type_name: string;

  org_name: string;
  department_name: string;
  delete_flag: boolean;
  qr: string;
  competences: string;
  competenceList?: {
    competency_code: string;
    competency_name: string;
    competency_name_2: string;
    competency_id: number;
  }[];
  competenceString: string;
  zone_ids?: number[];

  public objectType: ObjectType;

  constructor(obj) {
    if (
      obj['parent_object_id'] &&
      typeof obj['parent_object_id'] === 'number'
    ) {
      this.parent_object_id = obj['parent_object_id'];
    }
    if (
      obj['parent_object_code'] &&
      typeof obj['parent_object_code'] === 'string'
    ) {
      this.parent_object_code = obj['parent_object_code'];
    }
    if (
      obj['parent_object_name'] &&
      typeof obj['parent_object_name'] === 'string'
    ) {
      this.parent_object_name = obj['parent_object_name'];
    }
    if (obj['object_id'] && typeof obj['object_id'] === 'number') {
      this.object_id = obj['object_id'];
    }
    if (obj['object_code'] && typeof obj['object_code'] === 'string') {
      this.object_code = obj['object_code'];
    }
    if (obj['object_name'] && typeof obj['object_name'] === 'string') {
      this.object_name = obj['object_name'];
    }
    if (obj['object_name_2'] && typeof obj['object_name_2'] === 'string') {
      this.object_name_2 = obj['object_name_2'];
    }
    if (obj['sketch'] && typeof obj['sketch'] === 'string') {
      this.sketch = obj['sketch'];
    }
    if (obj['object_class_id'] && typeof obj['object_class_id'] === 'number') {
      this.object_class_id = obj['object_class_id'];
    } else {
      this.object_class_id = null;
    }
    if (obj['title_id']) {
      this.title_id = obj['title_id'];
    }
    if (obj['zone_id']) {
      this.zone_id = obj['zone_id'];
    }
    if (obj['zone_ids']) {
      this.zone_ids = obj['zone_ids'];
    }
    if (
      obj['object_class_name'] &&
      typeof obj['object_class_name'] === 'string'
    ) {
      this.object_class_name = obj['object_class_name'];
    }
    if (obj['object_desc'] && typeof obj['object_desc'] === 'string') {
      this.object_desc = obj['object_desc'];
    }
    if (obj['object_type_id'] && typeof obj['object_type_id'] === 'number') {
      this.object_type_id = obj['object_type_id'];
    }
    if (
      obj['object_type_code'] &&
      typeof obj['object_type_code'] === 'string'
    ) {
      this.object_type_code = obj['object_type_code'];
    }
    if (
      obj['object_type_name'] &&
      typeof obj['object_type_name'] === 'string'
    ) {
      this.object_type_name = obj['object_type_name'];
    }
    if (obj['org_name'] && typeof obj['org_name'] === 'string') {
      this.org_name = obj['org_name'];
    }
    if (obj['department_name'] && typeof obj['department_name'] === 'string') {
      this.department_name = obj['department_name'];
    }
    if (typeof obj['delete_flag'] === 'boolean') {
      this.delete_flag = obj['delete_flag'];
    }
    if (typeof obj['qr'] === 'string') {
      this.delete_flag = Boolean(obj['qr']); // fixme maybe crash here, why qr is String?
    }
    if (obj['competences'] && typeof obj['competences'] === 'string') {
      this.competences = obj['competences'];
    }
    if (obj.competenceList) {
      this.competenceList = obj.competenceList;
      this.competenceString = this.competenceList
        .map(c => `${c.competency_code}, ${c.competency_name}`)
        .join(', ');
    }
    this.object_label = `${this.object_code} ${this.object_name || ''}`;
    if (this.object_type_id && this.object_name) {
      this.object_label_id = `${this.object_type_id} ${this.object_name}`;
    }
    this.object_label_name = this.object_type_code
      ? `${this.object_type_code} ${this.object_type_name}`
      : null;
    this.object_full_name = this.object_name + ' / ' + this.object_code;
  }
}

/**
 * @deprecated
 */
export class ObjectInspection extends ObjectsInitializer {
  constructor(obj: {
    parent_object_id?: number;
    parent_object_code?: string;
    parent_object_name?: string;
    object_id?: number;
    object_class_name?: string;
    object_code?: string;
    object_name?: string;
    object_full_name?: string;
    object_name_2?: string;
    sketch?: string;
    object_desc?: string;
    object_type_id?: number;
    object_class_id?: number;
    title_id?: ObjectWbsTitle;
    zone_id?: ObjectZone;
    object_type_code?: string;
    object_type_name?: string;
    org_name?: string;
    department_name?: string;
    delete_flag?: boolean;
    qr?: string;
    competences?: string;
    competenceList?: {
      competency_code: string;
      competency_name: string;
      competency_name_2: string;
      competency_id: number;
    }[];
    zone_ids?: number[];
  }) {
    super(obj);

    this.objectType = new ObjectType(obj);
  }
}
