import { Directive, ViewContainerRef, ElementRef } from '@angular/core';
import { RowOutlet } from '../table.interfaces';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[rowOutlet]' })
export class RowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef
  ) {}
}
