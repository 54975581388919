/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ControlIdSetphotoBody { 
    file?: Blob;
    type?: ControlIdSetphotoBody.TypeEnum;
}
export namespace ControlIdSetphotoBody {
    export type TypeEnum = 'control_photo' | 'etalone_photo';
    export const TypeEnum = {
        ControlPhoto: 'control_photo' as TypeEnum,
        EtalonePhoto: 'etalone_photo' as TypeEnum
    };
}