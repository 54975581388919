import { Component, OnInit } from '@angular/core';
import { IDoesFilterPassParams, IFilterParams, RowNode } from '@ag-grid-community/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { ISimpleFilterModel } from '@ag-grid-community/core/dist/es6/filter/provided/simpleFilter';

interface IItrFilterModel extends ISimpleFilterModel {
  filter: string;
}

@Component({
  selector: 'itorum-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit, IFilterAngularComp {

  private static readonly rowHeight: number = 28;

  private model: Array<string>;
  private uniqueCheck: object;
  private filterParams: IFilterParams;
  private fieldId: string;
  /**
   * contains all unique column values for filtering
   * @private
   */
  private filterList: Array<string>;
  private ngUnsubscribe: Subject<any>;

  /**
   * Indicator to check if all items are selected
   */
  isEverythingSelected: boolean;
  //   this.filterParams.rowModel.forEachNode((node: RowNode) => {
  //     if (!node.data) {
  //       return;
  //     }
  //
  //     let value = node.data[this.fieldId];
  //     this.addUniqueValueIfMissing(value);
  //   });
  // }
  containerHeight: number;

  /**
   * Using an object to store our selected values. The lookup will be
   * faster on a simple object, then using an array to filter or using a Map.
   */
  selectedValuesMap: object;
  onFilterListChange$: BehaviorSubject<string[]>;
  onFilterValuesChanged$: Observable<string[]>;
  hideFilter: Function;

  constructor() { }

  agInit(params: IFilterParams): void {
    // console.log('agInit params ==>', params);
    this.filterParams = params;
    this.uniqueCheck = {};
    this.selectedValuesMap = {};
    this.filterList = [];

    this.fieldId = params.colDef.field;

    this.onFilterListChange$ = new BehaviorSubject<string[]>([]);
    this.ngUnsubscribe = new Subject<any>();

    this.onFilterValuesChanged$ = this.onFilterListChange$
      .pipe(
        debounceTime(250),
        tap(values => this.sortValues(values)),
        tap(values => this.setContainerHeight())
      );

    this.setUniqueValues();
    this.selectEverything();
  }

  setUniqueValues() {
    this.filterParams.rowModel.forEachNode((node: RowNode) => {
      // console.log('RowNode', node);
      if (!node.data) {
        return;
      }

      const value = node.data[this.fieldId];

      this.addUniqueValueIfMissing(value);
    });
  }

  /**
   * optional
   * @param value
   */
  toggleItem(value: string) {
    // console.log('this.selectedValuesMap', this.selectedValuesMap);
    // console.log('this.model', this.model);
    if (this.selectedValuesMap[value]) {
      delete this.selectedValuesMap[value];
      this.model = this.model.filter(val => val !== value);
    } else {
      this.selectedValuesMap[value] = 1;
      this.model.push(value);
    }

    this.isEverythingSelected =
      Object.keys(this.selectedValuesMap).length === this.filterList.length;

    this.onFilterChanged();
  }

  toggleEverything() {
    this.isEverythingSelected = !this.isEverythingSelected;
    if (this.isEverythingSelected) {
      this.selectEverything();
    } else {
      this.unselectEverything();
    }
    this.onFilterChanged();
  }

  onNewRowsLoaded() {
    this.setUniqueValues();
    // console.log('onNewRowsLoaded');
  }

  isFilterActive(): boolean {
    return Object.keys(this.selectedValuesMap).length > 0 && !this.isEverythingSelected;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const node = params.node;
    if (!node.data) {
      return false;
    }

    const nodeValue = params.data[this.fieldId];
    if (!this.isValueValid(nodeValue)) {
      return false;
    }

    // check if current row node value exists in our selectedValuesMap
    return Boolean(this.selectedValuesMap[nodeValue]);
  }

  getModel(): IItrFilterModel {
    return { filterType: 'custom', filter: this.model.join(', ') };
  }

  onFilterChanged() {
    this.filterParams.filterChangedCallback();
  }

  setModel(model: string): void {
    // console.log('setModel invoking ==>', model); // model sets as null
    this.selectEverything();
    this.selectedValuesMap[model] = 1;
    this.addUniqueValueIfMissing(model);
    this.onFilterChanged();
  }
  destroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

// ---------------------------------------------------------

  private setContainerHeight() {
    this.containerHeight =
      this.filterList.length * CustomFilterComponent.rowHeight;
  }

  private sortValues(values: string[]) {
    values.sort();
  }

  private selectEverything() {
    // console.log('selectEverything invoked');
    this.filterList.forEach(value => (this.selectedValuesMap[value] = 1));
    this.fillModel(this.filterList);
    this.isEverythingSelected = true;
  }

  private unselectEverything() {
    this.model = [];
    this.selectedValuesMap = {};
    this.isEverythingSelected = false;
  }

  private addUniqueValueIfMissing(value: string) {
    if (!this.isValueValid(value)) {
      return;
    }

    if (this.uniqueCheck[value]) {
      return;
    }
    // console.log('valid unique value of row ==>', value);

    this.filterList.push(value);
    this.onFilterListChange$.next([...this.filterList]);
    this.uniqueCheck[value] = 1;
  }

  private isValueValid(value: string) {
    if (value === '' || value === void 0 || value === null) {
      return false;
    }

    return true;
  }

  afterGuiAttached(params) {
    this.hideFilter = params.hidePopup;
  }

  dropFilter() {
    this.unselectEverything();
    this.onFilterChanged();
    this.hideFilter();
  }

  applyFilter() {
    this.onFilterChanged();
    this.hideFilter();
  }

  getFrameworkComponentInstance(): any {
    return this;
  }

  getModelAsString(model: any): string {
    // console.log('getModelAsString ==>', model);
    return model.toString();
  }

  ngOnInit(): void {
  }

  private fillModel(filterList: Array<string>) {
    // console.log('fillModel filterList==>', filterList);
    this.model = [];
    filterList.forEach((value) => {
      if (this.selectedValuesMap[value]) {
        this.model.push(value);
      }
    });

  }
}
