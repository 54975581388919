import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

const datetime_format: ISystemSettingsElement = {
  param_codename: 'datetime_format',
  param_name: 'Формат вывода даты и времени',
  // param_value: 'dd MMMM yyyy hh:mm',
  param_value: 'dd.MM.yyy HH:mm'
};

const date_format: ISystemSettingsElement = {
  param_codename: 'date_format',
  param_name: 'Формат вывода даты и времени',
  param_value: 'dd.MM.yyy'
};

const time_format: ISystemSettingsElement = {
  param_codename: 'time_format',
  param_name: 'Формат вывода даты и времени',
  param_value: 'HH:mm'
};

export interface ISystemSettingsElement {
  id?: number;
  param_codename: string;
  param_name: string;
  param_value: string;
  updatedAt?: Date;
  createdAt?: Date;
}

/**
 * @deprecated
 */
@Injectable()
export class SystemSettingsService {
  private _loaded = new BehaviorSubject<boolean>(undefined);
  private _loading = new BehaviorSubject<boolean>(undefined);
  private _system = new BehaviorSubject(
    new Map<string, ISystemSettingsElement>()
  );
  private _defaults = new BehaviorSubject(
    new Map<string, ISystemSettingsElement>()
  );
  private _combined = new BehaviorSubject(
    new Map<string, ISystemSettingsElement>()
  );

  private url = '/api/settings';

  public get loaded$(): Observable<boolean> {
    return this._loaded.asObservable();
  }
  public get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  public get settings$(): Observable<Map<string, ISystemSettingsElement>> {
    return this._combined.asObservable();
  }

  public get defaults$(): Observable<Map<string, ISystemSettingsElement>> {
    return this._defaults.asObservable();
  }

  public get system$(): Observable<Map<string, ISystemSettingsElement>> {
    return this._system.asObservable();
  }

  constructor(private apiService: ApiService) {
    const defaults = this._defaults.getValue();
    defaults.set('time_format', time_format);
    defaults.set('date_format', date_format);
    defaults.set('datetime_format', datetime_format);
    this._defaults.next(defaults);
  }

  /**** api methods  */
  private fetchAll(): Observable<ISystemSettingsElement[]> {
    return this.apiService
      .get(this.url)
      .pipe(map((res: any) => <ISystemSettingsElement[]>res.data));
  }

  public createSettingsElement(value: ISystemSettingsElement) {
    return this.apiService.post(this.url, value);
  }

  public updateAll(values: ISystemSettingsElement[]) {
    return this.apiService.put(this.url, values);
  }

  public removeOne(code_name: string) {
    return this.apiService.delete(`${this.url}/${code_name}`);
  }

  public retrieveAllOnce() {
    if (this._loading.getValue() || this._loading.getValue()) {
      return;
    }

    this._loading.next(true);
    this.fetchAll().subscribe((res: ISystemSettingsElement[]) => {
      this._loading.next(false);
      this._loaded.next(true);

      const system = new Map<string, ISystemSettingsElement>();
      res.forEach(element => system.set(element.param_codename, element));
      this._system.next(system);

      const combined = new Map<string, ISystemSettingsElement>();
      this._defaults
        .getValue()
        .forEach((value, key) => combined.set(key, value));
      system.forEach((value, key) => combined.set(key, value));
      this._combined.next(combined);
    });
  }

  public refreshList() {
    this._loaded.next(false);
    this.retrieveAllOnce();
  }

  public getValue(code_name: string) {
    return this._combined.getValue().get(code_name).param_value;
  }
}
