import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  IWebSocketResponse,
  UserService,
  NoticeService,
  WebCallService
} from "app/shared";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Notice } from "@itorum/models";
import { PermissionRolesService } from "app/common/services/permission-roles.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

// @TakeUntilDestroy()
@Component({
  selector: "itorum-call-acceptor",
  templateUrl: "./call-acceptor.component.html",
})
export class CallAcceptorComponent implements OnInit, OnDestroy {
  private destroy$: Subject<any> = new Subject();
  private isReverseRecording: boolean;
  private expectTranslate: string;
  private readyForSessionTranslate: string;
  public callPageLoading = false;
  public callingUserId: number;
  public isPopupOpen = false;
  public modalRef: MatDialogRef<any>;
  public popupNotice: Notice;
  public userIsCalling: string;
  public deviceName: string;
  public taskId: number;
  public iceServers: RTCIceServer[];

  @ViewChild("hotCall") hotCall: TemplateRef<any>;
  @ViewChild("waitCall") waitCall: TemplateRef<any>;

  constructor(
    public noticeService: NoticeService,
    private webCallService: WebCallService,
    // private modalService: BsModalService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private permissionRolesService: PermissionRolesService
  ) {
  }

  // ngAfterViewInit(): void {
  //   // this.openModal(this.hotCall);
  //   // this.openModal(this.waitCall);
  //   }

  ngOnInit() {
    this.noticeService.callUp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IWebSocketResponse) => {
        if (this.router.url === "/main/expert/call-page") {
          this.router.navigate(["main/expert/my-technics"]);
        }
        this.startCallingEvent(res);
      });

    this.noticeService.callDown$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IWebSocketResponse) => {
        this.closeCallingEvent(res);
      });

    this.noticeService.waitUp$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IWebSocketResponse) => {
        this.startWaitingEvent(res);
      });

    this.webCallService.callStop$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IWebSocketResponse) => {
        this.noticeService.stopSound();
        this.hideModal();
      });

    this.translateService
      .get("callAcceptor.expect")
      .pipe(takeUntil(this.destroy$))
      .subscribe((translate: string) => {
        this.expectTranslate = translate;
      });
    this.translateService
      .get("callAcceptor.readyForSession")
      .pipe(takeUntil(this.destroy$))
      .subscribe((translate: string) => {
        this.readyForSessionTranslate = translate;
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  startWaitingEvent(e: IWebSocketResponse): void {
    if (!this.isExpert()) {
      console.log(
        "Пользователь не является экспертом, уведомление не приходит"
      );
      return;
    }

    if (!this.isPopupOpen) {
      this.popupNotice = e.notice;
      this.popupNotice.msg_text =
        this.popupNotice.msg_text ||
        `${this.popupNotice.from_fio} ${this.expectTranslate}. ${this.readyForSessionTranslate}`;
      this.openModal(this.waitCall);
    } else {
      console.log(
        `Вам пришло уведомление, но на экране был попап с другим звонком`,
        { e }
      );
    }
  }

  startCallingEvent(e: IWebSocketResponse): void {
    if (!this.isExpert()) {
      console.log("Пользователь не является экспертом, звонок не приходит");
      return;
    }
    this.isReverseRecording = e.isReverseRecording;
    this.iceServers = e.iceServers;

    if (!this.isPopupOpen) {
      this.userIsCalling = e.from;
      this.callingUserId = e.fromUserId;
      this.deviceName = e.deviceName;
      this.taskId = e.taskId;
      if (e.skipConfirm) {
        this.onConfirmCall(this.userIsCalling, e.fromUserId);
      } else {
        this.openModal(this.hotCall);
      }
    } else {
      console.log(
        `Вам позвонил еще ${e.from}, но на экране был попап с другим звонком`
      );
    }
  }

  closeCallingEvent(e: IWebSocketResponse): void {
    if (this.isPopupOpen) {
      this.hideModal();
    } else {
      console.log("что-то пошло не так", e);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.isPopupOpen = true;
    this.modalRef = this.dialog.open(template);
  }

  hideModal(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.isPopupOpen = false;
    this.callPageLoading = false;
  }

  /**
   * Отказ горячего звонка в попапе звонка
   * @param {string} call_from
   */
  onCancelCall(call_from: string) {
    this.noticeService.cancelCall(call_from);
    this.hideModal();
  }

  /**
   * Принятие горячего звонка в попапе звонка
   * @param {string} userCalling
   */
  async onConfirmCall(userCalling: string, fromUserId: number) {
    this.callPageLoading = true;
    this.noticeService.stopSound();

    await this.router
      .navigateByUrl("main/expert/call-page");

    await this.webCallService.callPageReady();

    try {
      await this.webCallService.onCallAccept({
        callingUserName: userCalling,
        isReverseRecording: this.isReverseRecording,
        iceServers: this.iceServers,
        fromUserId,
        deviceName: this.deviceName,
        taskId: this.taskId
      });

      this.hideModal();
    } catch (e) {
      this.onCancelCall(this.userIsCalling);
    }

    this.noticeService.notices$.subscribe(notices => {
      for (const notice of notices) {
        if (notice["from_fio"] === userCalling) {
          this.noticeService.deleteNotice(notice["notice_id"]);
        }
      }
    });
  }

  /**
   * Принятие готовности в попапе уведомления
   * @param {Notice} notice
   */
  onConfirmWait(notice: Notice) {
    this.noticeService.acceptReady(notice);
    this.hideModal();
  }

  /**
   * Отказ готовности в попапе уведомления
   * @param {Notice} notice
   */
  onCancelWait(notice: Notice) {
    this.noticeService.updateNotices(notice);
    this.hideModal();
  }

  private isExpert() {
    return this.userService.can(this.permissionRolesService.EUserRole.REMOTE_EXPERT);
  }
}
