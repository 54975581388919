export const splitTime = (timeInSeconds) => {
  if(timeInSeconds === null) {
    return {min: null, sec: null}
  }

  const min = Math.trunc(timeInSeconds / 60);
  const sec = timeInSeconds % 60;

  return { min, sec };
}

export const toSec = (minutes: number) => {
  if (!minutes) {
    return;
  }
  return minutes * 60;
}
