<div class="itr-table__settings">
  <div class="itr-table__settings__left">
    <div
      class="itr-table__settings__icon"
      [ngClass]="{ 'itr-table__settings__icon__active': _itemHeight === 38 }"
      (click)="_updateItemHeight('38')"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #231f20;
          }
        </style>
        <rect x="8" y="9" class="st0" width="14.2" height="1" />
        <rect x="8" y="12" class="st0" width="14.2" height="1" />
        <rect x="8" y="15" class="st0" width="14.2" height="1" />
        <rect x="8" y="18" class="st0" width="14.2" height="1" />
        <rect x="8" y="21" class="st0" width="14.2" height="1" />
      </svg>
    </div>
    <div
      class="itr-table__settings__icon"
      [ngClass]="{ 'itr-table__settings__icon__active': _itemHeight === 50 }"
      (click)="_updateItemHeight('50')"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #231f20;
          }
        </style>
        <g>
          <rect x="8" y="9" class="st0" width="14.2" height="2" />
          <rect x="8" y="12.6" class="st0" width="14.2" height="2" />
          <rect x="8" y="16.3" class="st0" width="14.2" height="2" />
          <rect x="8" y="20" class="st0" width="14.2" height="2" />
        </g>
      </svg>
    </div>
    <div
      class="itr-table__settings__icon"
      [ngClass]="{ 'itr-table__settings__icon__active': _itemHeight === 68 }"
      (click)="_updateItemHeight('68')"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #231f20;
          }
        </style>
        <g>
          <rect x="8" y="9" class="st0" width="14.2" height="3" />
          <rect x="8" y="14" class="st0" width="14.2" height="3" />
          <rect x="8" y="19" class="st0" width="14.2" height="3" />
        </g>
      </svg>
    </div>
  </div>
  <div class="itr-table__settings__right">
    <div class="itr-table__settings__icon" (click)="_openSettingsModal()">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style="enable-background:new 0 0 30 30;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #010101;
          }
        </style>
        <g>
          <circle class="st0" cx="15.2" cy="15.3" r="1.8" />
          <path
            class="st0"
            d="M25.4,13.5L22.9,13c-0.2-0.5-0.4-1-0.6-1.5l1.5-2.2c0.1-0.2,0.1-0.5-0.1-0.7l-1.8-1.8
                       c-0.2-0.2-0.5-0.2-0.7-0.1L19,8.2c-0.5-0.3-1-0.5-1.5-0.6L16.9,5c0-0.2-0.3-0.4-0.5-0.4h-2.6c-0.2,0-0.5,0.2-0.5,0.4l-0.5,2.6
                       c-0.5,0.2-1,0.4-1.5,0.6L9.2,6.8C9,6.6,8.7,6.7,8.5,6.8L6.6,8.7C6.5,8.9,6.4,9.2,6.6,9.4l1.5,2.2c-0.3,0.5-0.5,1-0.6,1.5l-2.6,0.5
                       c-0.2,0-0.4,0.3-0.4,0.5v2.6c0,0.2,0.2,0.5,0.4,0.5l2.6,0.5c0.2,0.5,0.4,1,0.6,1.5l-1.5,2.1c-0.1,0.2-0.1,0.5,0.1,0.7l1.8,1.8
                       C8.7,24,9,24,9.2,23.9l2.1-1.5c0.5,0.2,1,0.5,1.5,0.6l0.5,2.6c0,0.2,0.3,0.4,0.5,0.4h2.6c0.2,0,0.5-0.2,0.5-0.4l0.5-2.6
                       c0.5-0.2,1-0.4,1.5-0.6l2.2,1.5c0.2,0.1,0.5,0.1,0.7-0.1l1.8-1.8c0.2-0.2,0.2-0.5,0.1-0.7l-1.5-2.2c0.3-0.5,0.5-1,0.6-1.5l2.6-0.5
                       c0.2,0,0.4-0.3,0.4-0.5V14C25.9,13.8,25.7,13.5,25.4,13.5z M15.2,19.8c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5
                       c2.5,0,4.5,2,4.5,4.5C19.6,17.8,17.6,19.8,15.2,19.8z"
          />
        </g>
      </svg>
    </div>
  </div>
</div>

<div style="width: 100%; overflow-x: auto">
    <div class="virtual_scroll_wrapper" #virtual_scroll_wrapper>
        <ng-container headerRowOutlet></ng-container>
        <itr-virtual-scroll
          #virtualScroll
          [items]="_fullData"
          [childHeight]="_itemHeight"
          [pageSize]="pageSize"
          (update)="_updateData($event)"
          (pageIndex)="pageIndex.emit($event)"
          (nextPage)="nextPage.emit($event)"
          (scrollTop)="scrollTop = $event"
        >
          <ng-container rowOutlet></ng-container>
        </itr-virtual-scroll>
</div>
</div>
<ng-container footerRowOutlet></ng-container>
<ng-template #settingsModal>
  <div class="fancybox add-points-modal">
    <div class="modal-close-btn" (click)="_hideSettingsModal()"></div>
    <form class="form" novalidate>
      <h2 class="title">{{ 'tableSettings.title' | translate }}</h2>

      <table class="table-settings">
        <thead>
          <tr>
            <th>
              {{ 'tableSettings.availableData' | translate }}
            </th>
            <th>
              {{ 'tableSettings.show' | translate }}
            </th>
            <th>
              {{ 'tableSettings.commonColumns' | translate }}
            </th>
            <th>
              {{ 'tableSettings.wrapping' | translate }}
              {{ 'tableSettings.text' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cell of _cellsConfig; let indx = index">
            <ng-container *ngIf="cell.field.label">
              <th>
                {{ cell.field.label | translate }}
              </th>
              <td>
                <div class="custom-checkbox">
                  <label for="checkbox__visible-{{ indx }}" class="role-list__container">
                    <input
                      type="checkbox"
                      id="checkbox__visible-{{ indx }}"
                      (change)="cell.isVisible = !cell.isVisible"
                      [checked]="cell.isVisible"
                    />
                    <span class="role-list__label"></span>
                  </label>
                </div>
              </td>
              <td
                [ngClass]="{
                  'itr-table__settings__form__main__cell__hide': !cell.isVisible
                }"
              >
                <div class="custom-checkbox">
                  <label for="checkbox__visible-{{ indx }}" class="role-list__container">
                    <input
                      type="checkbox"
                      id="checkbox__visible-{{ indx }}"
                      (change)="cell.isVisible = !cell.isVisible"
                      [checked]="cell.isVisible"
                    />
                    <span class="role-list__label"></span>
                  </label>
                </div>
              </td>
              <td
                [ngClass]="{
                  'itr-table__settings__form__main__cell__hide': !cell.isVisible
                }"
              >
                <div class="custom-checkbox">
                  <label for="checkbox__visible-{{ indx }}" class="role-list__container">
                    <input
                      type="checkbox"
                      id="checkbox__visible-{{ indx }}"
                      (change)="cell.isVisible = !cell.isVisible"
                      [checked]="cell.isVisible"
                    />
                    <span class="role-list__label"></span>
                  </label>
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>

      <div class="buttons">
        <button type="button" class="cancel" (click)="_hideSettingsModal()">
          {{ 'main.cancel' | translate }}
        </button>
        <button type="button" class="save" (click)="_settingsModalsubmit()">
          {{ 'main.save' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
