import { combineLatest, map } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild
} from "@angular/core";
import { ArrayToggle, uuid } from "@itorum/models";
import { Observable, BehaviorSubject } from "rxjs";


@Component({
  selector: "itorum-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"]
})
export class TableFilterComponent implements OnInit {
  @Input() public list: Observable<any>;

  @Input()
  get visibility(): boolean {
    return this._visibility;
  }

  set visibility(visibility: boolean) {
    this._visibility = visibility;
    // this.subjSearch.next('');
  }

  public _visibility = false;

  @Input() public enableSearch = false;
  @Input() public searchText = "Поиск";
  @Input() public bindValue = "value";
  @Input() public bindLabel = "label";
  @Input() public right = false;
  @Input() public static = false;
  @Input() variants = true;

  @Input() set searchInit(val: string) {
    if (val) {
      this.subjSearch.next(val);
    }
  }

  @Input() field: string;

  @ViewChild("objectTypesSearch") public objectTypesSearch;

  @Output() selected: EventEmitter<ArrayToggle<any>> = new EventEmitter<ArrayToggle<any>>();
  @Output() reset = new EventEmitter();
  @Output() search = new EventEmitter();

  public selectedList = new ArrayToggle<any>();
  public subjSearch = new BehaviorSubject<string>("");

  ngOnInit() {
    if (!this.list) {
      return;
    }
    this.list = this.list.pipe(
      map(list => {
        list = list.filter(item => !!item);
        return list.map(item => {
          const ItemModel = {
            [this.bindLabel]: "",
            [this.bindValue]: "",
            id: uuid()
          };
          if (typeof item === "string") {
            ItemModel[this.bindLabel] = item;
            ItemModel[this.bindValue] = item;
          } else if (typeof item === "object") {
            ItemModel[this.bindLabel] = item[this.bindLabel];
            ItemModel[this.bindValue] = item[this.bindValue];
          } else {
            throw new Error("unsupported type");
          }
          return ItemModel;
        });
      }),
      combineLatest(this.subjSearch, (list: any[], search: string) => {
        if (search) {
          const regEx = new RegExp(search, "i");
          return list.filter((item: any) => regEx.test(item[this.bindLabel]));
        }
        return list;
      }));
  }

  save() {
    this.selected.emit(this.selectedList);
    if (!this.static) {
      this.search.emit({
        field: this.field,
        value: this.subjSearch.getValue()
      });
    }
  }

  cancel() {
    this.subjSearch.next("");
    this.reset.emit(this.selectedList.clear());
    if (!this.static) {
      this.search.emit({
        field: this.field,
        value: this.subjSearch.getValue()
      });
    }
  }

  resetSearch() {
    this.subjSearch.next("");
  }
}
