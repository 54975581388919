import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  IterableDiffers,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { DefaultNodeComponent, EngineService, MODEL, RxZuDiagramComponent } from "@rxzu/angular";
import { MonPointerModel } from "../../models";

@Component({
  selector: 'itorum-mon-pointer',
  templateUrl: './mon-pointer.component.html',
  styleUrls: ['./mon-pointer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonPointerComponent extends DefaultNodeComponent implements OnInit {

  @ViewChild("portsLayer", { read: ViewContainerRef, static: true }) portsLayer!: ViewContainerRef;

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    console.log('MonPointerComponent onmousedown =>', $event);
    $event.restrict_drawing = true;
  }

  constructor(
    @Inject(MODEL) public model: MonPointerModel,
    engine: EngineService,
    diagram: RxZuDiagramComponent,
    iterableDiffers: IterableDiffers,
    private cd: ChangeDetectorRef,
  ) {
    super(model, engine, diagram, iterableDiffers);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
