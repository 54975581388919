import { Directive, ViewContainerRef } from '@angular/core';
import { ItrCellDefDirective } from './itr-cell-def.directive';

@Directive({ selector: '[itrCellOutlet]' })
export class ItrCellOutletDirective {
  static mostRecentCellOutlet: ItrCellOutletDirective | null = null;

  cells: ItrCellDefDirective[];
  context: any;

  constructor(public _viewContainer: ViewContainerRef) {
    ItrCellOutletDirective.mostRecentCellOutlet = this;
  }
}
