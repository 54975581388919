export interface OrganizationTypeResponceIntreface {
  id_type_name: number;
  type_name: string;
}
export class OrganizationType {
  id_type_name: number;
  type_name: string;

  constructor(obj: OrganizationTypeResponceIntreface) {
    this.id_type_name = obj.id_type_name;
    this.type_name = obj.type_name;
  }
}
