import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ItrDatetimepickerComponent } from './itr-datetimepicker';
import { ItrTimeMaskDirective } from './directives/itr-time-mask.directive';
import { TranslateModule } from '@ngx-translate/core';

/**
 * @deprecated
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TranslateModule
  ],
  declarations: [ItrDatetimepickerComponent, ItrTimeMaskDirective],
  exports: [ItrDatetimepickerComponent, ItrTimeMaskDirective]
})
export class ItrDatetimepickerModule {}
