import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { themes } from './theme.consts';

@Injectable()
export class ThemeService {

  public theme: any = {};

  constructor(
    @Inject(DOCUMENT) private document: any
  ) { }

  setTheme(themeName = 'base') {
    this.theme = themes[themeName];
    for (const key of Object.keys(this.theme)) {
      this.document.body.style.setProperty(key, this.theme[key]);
    }
  }

  getProperty(key) {
    return this.theme[key];
  }

}
