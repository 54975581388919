export function getTableUnknownColumnError(id: string) {
  return Error(`Could not find column with id "${id}".`);
}
export function getTableDuplicateColumnNameError(name: string) {
  return Error(`Duplicate column definition name provided: "${name}".`);
}
export function getTableMultipleDefaultRowDefsError() {
  return Error(
    `There can only be one default row without a when predicate function.`
  );
}
export function getTableMissingMatchingRowDefError(data: any) {
  return Error(
    `Could not find a matching row definition for the` +
      `provided row data: ${JSON.stringify(data)}`
  );
}
export function getTableMissingRowDefsError() {
  return Error(
    'Missing definitions for header, footer, and row; ' +
      'cannot determine which columns should be rendered.'
  );
}
export function getTableUnknownDataSourceError() {
  return Error(
    `Provided data source did not match an array, Observable, or DataSource`
  );
}
