export interface IMeasureInfoModel {
  measureName?: string;
  measureUnit?: string;
  measureBenchmark?: string;
  positiveDeviation?: any;
  negativeDeviation?: any;
  deviationControl?: boolean;
}

export interface IMeasureInfoModelResponse {
  measure_name?: string;
  measure_unit?: string;
  measure_benchmark?: any;
  positive_deviation?: any;
  negative_deviation?: any;
  deviation_control?: boolean;
}

// type Combine = IMeasureInfoModel | IMeasureInfoModelResponse;

export class MeasureInfo implements IMeasureInfoModel {
  public measureName?: string;
  public measureUnit?: string;
  public measureBenchmark?: any;
  public positiveDeviation?: any;
  public negativeDeviation?: any;
  public deviationControl?: boolean;

  /**
   * поверхностный конструктор
   * @param {IMeasureInfoModelResponse} data - ответ с бэкэнда
   */
  constructor(data: IMeasureInfoModelResponse) {
    if (!data) {
      return;
    }

    this.measureName = data.measure_name;
    this.measureUnit = data.measure_unit;
    this.measureBenchmark = data.measure_benchmark;
    this.positiveDeviation = data.positive_deviation;
    this.negativeDeviation = data.negative_deviation;
    this.deviationControl = data.deviation_control || false;

    // /**
    //  * Если у объекта дата нет названия измерения - все поля не будут определены (undefined)
    //  */
    // if ((data as IMeasureInfoModelResponse).measure_name) { // fixme TS must solve this
    //   console.log('data as IMeasureInfoModelResponse');
    //   this.measureName = (data as IMeasureInfoModelResponse).measure_name;
    //   this.measureUnit = (data as IMeasureInfoModelResponse).measure_unit;
    //   this.measureBenchmark = (data as IMeasureInfoModelResponse).measure_benchmark;
    //   this.positiveDeviation = (data as IMeasureInfoModelResponse).positive_deviation;
    //   this.negativeDeviation = (data as IMeasureInfoModelResponse).negative_deviation;
    //   this.deviationControl = (data as IMeasureInfoModelResponse).deviation_control || false;
    //   return;
    // }

    // if ((data as IMeasureInfoModel).measureName) {  // fixme TS must solve this
    //   console.log('data as IMeasureInfoModel');
    //   this.measureName = (data as IMeasureInfoModel).measureName;
    //   this.measureUnit = (data as IMeasureInfoModel).measureUnit;
    //   this.measureBenchmark = (data as IMeasureInfoModel).measureBenchmark;
    //   this.positiveDeviation = (data as IMeasureInfoModel).positiveDeviation;
    //   this.negativeDeviation = (data as IMeasureInfoModel).negativeDeviation;
    //   this.deviationControl = (data as IMeasureInfoModel).deviationControl || false;
    //   return;
    // }
  }

  public static createFromIMeasureInfoModel(model: IMeasureInfoModel): MeasureInfo {
    if (!model) {
      return null;
    }

    const data: IMeasureInfoModelResponse = {
      deviation_control: model.deviationControl,
      measure_benchmark: model.measureBenchmark,
      measure_name: model.measureName,
      measure_unit: model.measureUnit,
      negative_deviation: model.negativeDeviation,
      positive_deviation: model.positiveDeviation
    };

    return new MeasureInfo(data);
  }
}
