/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiJobtemplatesBody } from '../model/apiJobtemplatesBody';
import { InlineResponse20010 } from '../model/inlineResponse20010';
import { InlineResponse2007 } from '../model/inlineResponse2007';
import { InlineResponse2008 } from '../model/inlineResponse2008';
import { InlineResponse2009 } from '../model/inlineResponse2009';
import { JobTemplateIdActiontemplateBody } from '../model/jobTemplateIdActiontemplateBody';
import { JobTemplateIdTechnikBody } from '../model/jobTemplateIdTechnikBody';
import { JobtemplatesJobTemplateIdBody } from '../model/jobtemplatesJobTemplateIdBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class JobTemplatesService {

  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get job-templates
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2008>;
  public apiJobTemplatesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2008>>;
  public apiJobTemplatesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2008>>;
  public apiJobTemplatesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse2008>('get',`${this.basePath}/api/job-templates`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job-templates
   *
   * @param jobTemplateId Job template id
   * @param actionTemplateId Action template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete(jobTemplateId: number, actionTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete(jobTemplateId: number, actionTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete(jobTemplateId: number, actionTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete(jobTemplateId: number, actionTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete.');
    }

    if (actionTemplateId === null || actionTemplateId === undefined) {
      throw new Error('Required parameter actionTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdActionTemplateActionTemplateIdDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('delete',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}/action-template/${encodeURIComponent(String(actionTemplateId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create job-template action-template relation
   *
   * @param jobTemplateId Job template id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdActionTemplatePost(jobTemplateId: number, body?: JobTemplateIdActiontemplateBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
  public apiJobTemplatesJobTemplateIdActionTemplatePost(jobTemplateId: number, body?: JobTemplateIdActiontemplateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdActionTemplatePost(jobTemplateId: number, body?: JobTemplateIdActiontemplateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdActionTemplatePost(jobTemplateId: number, body?: JobTemplateIdActiontemplateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdActionTemplatePost.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2009>('post',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}/action-template`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete job-template
   *
   * @param jobTemplateId Job template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdDelete(jobTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20010>;
  public apiJobTemplatesJobTemplateIdDelete(jobTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20010>>;
  public apiJobTemplatesJobTemplateIdDelete(jobTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20010>>;
  public apiJobTemplatesJobTemplateIdDelete(jobTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse20010>('delete',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job-templates
   *
   * @param jobTemplateId Job template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdGet(jobTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
  public apiJobTemplatesJobTemplateIdGet(jobTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdGet(jobTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdGet(jobTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<InlineResponse2009>('get',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update job
   *
   * @param jobTemplateId Job template id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdPut(jobTemplateId: number, body?: JobtemplatesJobTemplateIdBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
  public apiJobTemplatesJobTemplateIdPut(jobTemplateId: number, body?: JobtemplatesJobTemplateIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdPut(jobTemplateId: number, body?: JobtemplatesJobTemplateIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
  public apiJobTemplatesJobTemplateIdPut(jobTemplateId: number, body?: JobtemplatesJobTemplateIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdPut.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2009>('put',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get job techniks
   *
   * @param jobTemplateId Job template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdTechnikGet(jobTemplateId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InlineResponse2007>>;
  public apiJobTemplatesJobTemplateIdTechnikGet(jobTemplateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InlineResponse2007>>>;
  public apiJobTemplatesJobTemplateIdTechnikGet(jobTemplateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InlineResponse2007>>>;
  public apiJobTemplatesJobTemplateIdTechnikGet(jobTemplateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdTechnikGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<InlineResponse2007>>('get',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}/technik`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set job template techniks
   *
   * @param jobTemplateId Job id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesJobTemplateIdTechnikPost(jobTemplateId: number, body?: JobTemplateIdTechnikBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public apiJobTemplatesJobTemplateIdTechnikPost(jobTemplateId: number, body?: JobTemplateIdTechnikBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiJobTemplatesJobTemplateIdTechnikPost(jobTemplateId: number, body?: JobTemplateIdTechnikBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiJobTemplatesJobTemplateIdTechnikPost(jobTemplateId: number, body?: JobTemplateIdTechnikBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (jobTemplateId === null || jobTemplateId === undefined) {
      throw new Error('Required parameter jobTemplateId was null or undefined when calling apiJobTemplatesJobTemplateIdTechnikPost.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post',`${this.basePath}/api/job-templates/${encodeURIComponent(String(jobTemplateId))}/technik`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create job-templates
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobTemplatesPost(body?: ApiJobtemplatesBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
  public apiJobTemplatesPost(body?: ApiJobtemplatesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
  public apiJobTemplatesPost(body?: ApiJobtemplatesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
  public apiJobTemplatesPost(body?: ApiJobtemplatesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2009>('post',`${this.basePath}/api/job-templates`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
