import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { IMarkerTag } from "@itorum/models";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: 'itorum-sources-search',
  templateUrl: './sources-search.component.html',
  styleUrls: ['./sources-search.component.scss']
})
export class SourcesSearchComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private allTags: IMarkerTag[];
  @Input() set free(tags: IMarkerTag[]) {
    console.log('SourcesSearchComponent free =>', tags);
    this.allTags = tags;
    this.filteredSources.next(this.allTags);
  }
  @Output() doClose = new EventEmitter<boolean>();
  @Output() addTag = new EventEmitter<IMarkerTag>();

  sourceCtrl = new FormControl();
  sourceFilterCtrl = new FormControl();
  filteredSources = new BehaviorSubject<IMarkerTag[]>([]);
  // private src: {name: string}[] = [
  //   {name: 'Temperature'},
  //   {name: 'Pressure'},
  //   {name: 'Voltage'},
  // ]

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    // console.log('SourcesSearchComponent onmousedown =>', $event);
    $event.restrict_drawing = true;
  }

  constructor() { }

  ngOnInit(): void {
    this.sourceFilterCtrl.valueChanges
      .pipe(takeUntil(
        this.destroy$),
        // tap(console.log)
      )
      .subscribe((value) => {
        this.filteredSources.next(this.allTags.filter(tag => tag.name.toLowerCase().includes(value)));
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addToObserveList() {
    console.log('this.sourceCtrl.value =>', this.sourceCtrl.value);
    this.addTag.emit(this.sourceCtrl.value);
    this.sourceCtrl.setValue(null);
  }
}
