import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { UserService } from "app/shared";
import { RemoteExpert2Service } from "@itorum/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private remoteExpert2Service: RemoteExpert2Service
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    console.group('AuthGuard');
    console.log('ActivatedRouteSnapshot =>', route);
    console.log('ActivatedRouteSnapshot =>', route.data['id']);
    console.log('RouterStateSnapshot =>', state);
    console.groupEnd();

    if (state.url.includes('/account/invite/')) {
      //check token
      // return this.remoteExpert2Service.apiRemoteExpert2RoomRoomUidCheckGet(state.url.split('/')[2])
      //   .pipe(map((check) => {
      //     if (check.status) {
      //       return true;
      //     } else {
      //       this.router.navigateByUrl('account').catch(err => console.log(err));
      //       return false;
      //     }
      //   }));
        // .subscribe(res => console.log('token guard checking result is =>', res));
      return true;
    }

    if (state.url.includes('undefined')) {
      return false;
    }


    const isGuest = this.userService.isGuest;
    const isAccPath = route.url[0]['path'] === 'account';
    if (!isGuest && isAccPath) {
      this.router.navigateByUrl('main').catch(err => console.log(err));
      return false;
    }
    if (isGuest && !isAccPath) {
      this.router.navigateByUrl('account').catch(err => console.log(err));
      return false;
    }
    return true;
  }
}
