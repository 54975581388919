/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { InlineResponse20021 } from "../model/inlineResponse20021";
import { InlineResponse20022 } from "../model/inlineResponse20022";
import { StepStuffBody } from "../model/stepStuffBody";
import { StuffStuffIdBody } from "../model/stuffStuffIdBody";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";


@Injectable()
export class StepStuffService {

  protected basePath = "https://dev.itorum-mr.ru";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get stuffs by step id
   *
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdStuffGet(stepId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20022>;
  public apiStepStepIdStuffGet(stepId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20022>>;
  public apiStepStepIdStuffGet(stepId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20022>>;
  public apiStepStepIdStuffGet(stepId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (stepId === null || stepId === undefined) {
      throw new Error("Required parameter stepId was null or undefined when calling apiStepStepIdStuffGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20022>("get", `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}/stuff`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create stuff
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStuffPost(body?: StepStuffBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20021>;
  public apiStepStuffPost(body?: StepStuffBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
  public apiStepStuffPost(body?: StepStuffBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
  public apiStepStuffPost(body?: StepStuffBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20021>("post", `${this.basePath}/api/step/stuff`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete stuff
   *
   * @param stuffId Stuff id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStuffStuffIdDelete(stuffId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiStepStuffStuffIdDelete(stuffId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiStepStuffStuffIdDelete(stuffId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiStepStuffStuffIdDelete(stuffId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (stuffId === null || stuffId === undefined) {
      throw new Error("Required parameter stuffId was null or undefined when calling apiStepStuffStuffIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/step/stuff/${encodeURIComponent(String(stuffId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get stuff
   *
   * @param stuffId Stuff id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStuffStuffIdGet(stuffId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20021>;
  public apiStepStuffStuffIdGet(stuffId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
  public apiStepStuffStuffIdGet(stuffId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
  public apiStepStuffStuffIdGet(stuffId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (stuffId === null || stuffId === undefined) {
      throw new Error("Required parameter stuffId was null or undefined when calling apiStepStuffStuffIdGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20021>("get", `${this.basePath}/api/step/stuff/${encodeURIComponent(String(stuffId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update stuff
   *
   * @param stuffId Stuff id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStuffStuffIdPut(stuffId: number, body?: StuffStuffIdBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20021>;
  public apiStepStuffStuffIdPut(stuffId: number, body?: StuffStuffIdBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
  public apiStepStuffStuffIdPut(stuffId: number, body?: StuffStuffIdBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
  public apiStepStuffStuffIdPut(stuffId: number, body?: StuffStuffIdBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (stuffId === null || stuffId === undefined) {
      throw new Error("Required parameter stuffId was null or undefined when calling apiStepStuffStuffIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20021>("put", `${this.basePath}/api/step/stuff/${encodeURIComponent(String(stuffId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
