import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from "@angular/core";
import { DefaultPortComponent, MODEL } from "@rxzu/angular";
import { MonPortModel } from "../../models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "itorum-mon-port",
  templateUrl: "./mon-port.component.html",
  styleUrls: ["./mon-port.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonPortComponent extends DefaultPortComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    console.log('MonPortComponent onmousedown =>', $event);
    $event.restrict_drawing = true;
  }

  constructor(
    @Inject(MODEL) public model: MonPortModel,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {
    super(model);
    model.registerCdRef(cdRef);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updatePortRootStyle();
  }


  /**
   * * Add a class to the root element of the model based on the model's direction.
   * * Subscribe to the model's hovered state and set the hovered state of all the links in the model.
   * * Subscribe to the model's lockChanges event and call the cdRef.detectChanges() method
   */
  updatePortRootStyle() {
    const rootEl = this.elRef.nativeElement;
    console.log('rootEl =>', rootEl);
    this.renderer.addClass(rootEl, 'perimeter'); // that's it

    this.model
      .getParent()
      .selectHovered()
      .subscribe((hovered) => {
        this.model.getLinksArray().forEach((link) => {
          link.setHovered(hovered);
          link.getTargetPort()?.setHovered(hovered);
          link.getSourcePort()?.setHovered(hovered);
        });
      });

    this.model.lockChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdRef.detectChanges());
  }

  detectChanges() {
    this.cdRef.detectChanges();
  }
}
