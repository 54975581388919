import { Injectable } from '@angular/core';
import { WebRtcPeer } from 'kurento-utils';
import {
  WsRequestIds,
  WsResponseIds,
  IWebSocketResponse,
  WebSocketService
} from './web-socket.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppConfigService } from "app/shared/services/app-config.service";

@Injectable()
/**
 * Saving incoming technic stream back to kurento
 * Created because kurento cant save video from android 4.0.4
 */
export class WebRecorderService {
  // canvas with cloned video
  private remoteVideoCanvas: HTMLCanvasElement;
  private videoFromDeviceEl: HTMLVideoElement;
  private webRtcPeer: WebRtcPeer;
  private mediaStreamFromDevice: MediaStream;
  private outComingStream: MediaStream;
  private iceServers: RTCIceServer[];
  private intervalHandle: number;
  private kurentoReady$ = new BehaviorSubject(false);

  public recorderStarted$$ = new Subject<void>();
  public recorderStopped$$ = new Subject<void>();

  constructor(
    private webSocketService: WebSocketService,
    public appConfigService: AppConfigService
  ) {
    this.webSocketService.recorderBus$.subscribe(
      (parsedMessage: IWebSocketResponse) => {
        switch (parsedMessage.id) {
          case WsResponseIds.RECORDER_CREATED:
            // construction of backend recorder complete, now we must send offer
            this.kurentoReady$.next(true);
            break;
          case WsResponseIds.RECORDER_STOPPED:
            this.stop();
            break;
          case WsResponseIds.RECORDER_ANSWER:
            this.webRtcPeer.processAnswer(parsedMessage.data);
            break;
          case WsResponseIds.RECORDER_RECORD_STARTED:
            console.log('recorder 404 started', parsedMessage.filename);
            break;
          case WsResponseIds.RECORDER_ICE_CANDIDATE:
            try {
              this.webRtcPeer.addIceCandidate(parsedMessage.candidate);
            } catch (e) {
              console.error(e);
            }
            break;
          default:
        }
      }
    );
  }

  public init(remoteVideoCanvas: HTMLCanvasElement) {
    if (!remoteVideoCanvas) {
      console.error('no remoteVideoCanvas in WebRecorderService#init');
    }
    this.remoteVideoCanvas = remoteVideoCanvas;
  }

  public start(
    videoFromDevice: HTMLVideoElement,
    mediaStreamFromDevice: MediaStream
  ) {
    if (!videoFromDevice) {
      console.error('no videoFromDevice in WebRecorderService#start');
    }
    if (!mediaStreamFromDevice) {
      console.error('no mediaStreamFromDevice in WebRecorderService#start');
    }

    this.mediaStreamFromDevice = mediaStreamFromDevice;
    this.videoFromDeviceEl = videoFromDevice;

    /** canvas with reverse video is ready, then wait for kurento ready and start record */
    this.kurentoReady$
      .pipe(
        filter(ready => ready),
        take(1)
      )
      .subscribe(() => {
        this.startRecord();
      });
    this.recorderStarted$$.next();
  }

  public stop() {
    this.stopRecord();
    this.resetState();
    this.recorderStopped$$.next();
  }

  public setIceServers(servers: RTCIceServer[]): void {
    this.iceServers = servers;
  }

  private cleanIceServers() {
    this.iceServers = [];
  }

  private onIceCandidate(candidate) {
    this.webSocketService.sendData({
      id: WsRequestIds.RECORDER_ICE_CANDIDATE,
      data: candidate
    });
  }

  private startRecord() {
    const canvasStream = (this.remoteVideoCanvas as any).captureStream();
    // create stream from remote video and local audio
    this.outComingStream = new MediaStream([
      canvasStream.getVideoTracks()[0],
      this.mediaStreamFromDevice.getAudioTracks()[0]
    ]);

    const options = {
      videoStream: this.outComingStream,
      onicecandidate: candidate => this.onIceCandidate(candidate),
      configuration: {
        iceServers: this.iceServers
      }
    };

    this.webRtcPeer = WebRtcPeer.WebRtcPeerSendonly(options, error => {
      if (error) {
        console.error('Error on creating the WebRtcPeer', error);
        return;
      }

      this.webRtcPeer.generateOffer((offerError, offerSdp) => {
        if (offerError) {
          console.error('Error on generating the offer', offerError);
          return;
        }

        this.webSocketService.sendData({
          id: WsRequestIds.RECORDER_OFFER,
          data: offerSdp
        });
      });

      this.debugWebRtcPeer();
    });
  }

  private debugWebRtcPeer() {
    if (this.appConfigService.appConfig.webrtcLog) {
      // no event log for production
      return;
    }
    [
      'connectionstatechange',
      'datachannel',
      'icecandidate',
      'icecandidateerror',
      'iceconnectionstatechange',
      'icegatheringstatechange',
      'negotiationneeded',
      'signalingstatechange',
      'statsended',
      'track'
    ].forEach(eventName => {
      this.webRtcPeer.peerConnection.addEventListener(eventName, evt =>
        console.log('recorder peerconnection event', eventName, evt)
      );
    });
  }

  private stopRecord() {
    this.webSocketService.sendData({
      id: WsRequestIds.RECORDER_STOP
    });
  }

  private resetState() {
    this.cleanIceServers();
    this.kurentoReady$.next(false);
    this.mediaStreamFromDevice = null;
    this.videoFromDeviceEl = null;
  }
}
