import { Injectable, NgZone } from '@angular/core';
import { Subject ,  BehaviorSubject } from 'rxjs';
import { IPencilParams } from '@shared/components/drawing/tools/services/painter.service';
import { skip } from 'rxjs/operators';

@Injectable()
export class CallPageService {
  private screenshot$$ = new BehaviorSubject<ImageBitmap>(null);
  private localCtx: CanvasRenderingContext2D;
  private sizes$$ = new Subject<[number, number]>();
  private pencilParams$$ = new Subject<IPencilParams>();
  private pencilParams: IPencilParams;
  private closeLocals$$ = new Subject<void>();

  public sizes$ = this.sizes$$.asObservable();
  public pencilParams$ = this.pencilParams$$.asObservable();
  public closeLocals$ = this.closeLocals$$.asObservable();
  public screenshot$ = this.screenshot$$.asObservable().pipe(skip(1));

  constructor(private ngZone: NgZone) {}

  public setLocalsSizes(width: number, height: number) {
    this.sizes$$.next([width, height]);
  }

  public setLocalCtx(ctx: CanvasRenderingContext2D) {
    this.localCtx = ctx;
    this.setPencilParams(null);
  }

  public setPencilParams(pencilParams: Partial<IPencilParams>) {
    if (!pencilParams) {
      this.pencilParams$$.next(null);
      this.pencilParams = null;
    } else {
      this.pencilParams = { ...this.pencilParams, ...pencilParams };
      this.pencilParams$$.next(this.pencilParams);
    }
  }

  public setCloseLocals() {
    this.closeLocals$$.next();
    // this.localCtx.clearRect(0, 0, this.localCtx.canvas.width, this.localCtx.canvas.height);
  }

  public drawImageOnCtxScaled(
    img: ImageBitmap,
    context: CanvasRenderingContext2D
  ) {
    // fit image to canvas
    const canvas = context.canvas;
    const imageAspectRatio = img.width / img.height;
    const canvasAspectRatio = canvas.width / canvas.height;
    let renderableHeight: number;
    let renderableWidth: number;
    let xStart: number;
    let yStart: number;

    // If image's aspect ratio is less than canvas's we fit on height
    // and place the image centrally along width
    if (imageAspectRatio < canvasAspectRatio) {
      renderableHeight = canvas.height;
      renderableWidth = img.width * (renderableHeight / img.height);
      xStart = (canvas.width - renderableWidth) / 2;
      yStart = 0;
    } else if (imageAspectRatio > canvasAspectRatio) {
      // If image's aspect ratio is greater than canvas's we fit on width
      // and place the image centrally along height
      renderableWidth = canvas.width;
      renderableHeight = img.height * (renderableWidth / img.width);
      xStart = 0;
      yStart = (canvas.height - renderableHeight) / 2;
    } else {
      // Happy path - keep aspect ratio
      renderableHeight = canvas.height;
      renderableWidth = canvas.width;
      xStart = 0;
      yStart = 0;
    }
    context.drawImage(img, xStart, yStart, renderableWidth, renderableHeight);
  }

  public setScreenshot(bitmap: ImageBitmap) {
    this.screenshot$$.next(bitmap);
  }

  public drawScreenShotOnLocal() {
    this.localCtx.clearRect(
      0,
      0,
      this.localCtx.canvas.width,
      this.localCtx.canvas.height
    );
    this.drawImageOnCtxScaled(this.screenshot$$.value, this.localCtx);
  }

  public translateFromVideoToCanvas(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement
  ) {
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    [canvas.width, canvas.height] = [video.videoWidth, video.videoHeight];

    return this.ngZone.runOutsideAngular(() =>
      window.setInterval(() => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      }, 1000 / 30)
    ); // 30 fps
  }

  public translateFromCanvasToVideo(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement
  ): Promise<void> {
    [video.width, video.height] = [canvas.width, canvas.height];
    const stream = (canvas as any).captureStream(20);
    video.srcObject = stream;
    return new Promise((resolve, reject) => {
      video.oncanplay = () => {
        video.play().then(resolve);
      };
    });
  }

  public getImageDataFromVideo(video: HTMLVideoElement): ImageData {
    const canvas = document.createElement('canvas');
    [canvas.width, canvas.height] = [video.videoWidth, video.videoHeight];
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    return ctx.getImageData(0, 0, canvas.width, canvas.height);
  }

  private drawGradient(canvas: HTMLCanvasElement): number {
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(
      0,
      0,
      canvas.width,
      canvas.height
    );
    gradient.addColorStop(0, 'grey');
    gradient.addColorStop(1, 'black');
    let i = 0;

    return this.ngZone.runOutsideAngular<number>(() => {
      return window.setInterval(() => {
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'red';
        ctx.fillRect(i++, 50, 20, 20);
        if (i > canvas.width) {
          i = 0;
        }
      }, 30);
    });
  }

  debugDummyVideoStream(video: HTMLVideoElement) {
    const canvas = document.createElement('canvas');
    canvas.width = 640;
    canvas.height = 480;
    this.drawGradient(canvas);
    this.translateFromCanvasToVideo(canvas, video);
  }
}
