/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import {
  AnswersAnswerIdBody,
  CreatedStepQuestionResponse,
  GetUpdateStepQuestionResponse,
  StepAnswersResponse,
  QuestionsAnswersBody,
  QuestionsQuestionIdBody,
  StepQuestionsBody,
  BASE_PATH,
  Configuration
} from "@itorum/api";


@Injectable()
export class StepQuestionService {

    protected basePath = 'https://dev.itorummr.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete answer by id
     *
     * @param answerId Answer id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsAnswersAnswerIdDelete(answerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiStepQuestionsAnswersAnswerIdDelete(answerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiStepQuestionsAnswersAnswerIdDelete(answerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiStepQuestionsAnswersAnswerIdDelete(answerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (answerId === null || answerId === undefined) {
            throw new Error('Required parameter answerId was null or undefined when calling apiStepQuestionsAnswersAnswerIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/step/questions/answers/${encodeURIComponent(String(answerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update step answers by id
     *
     * @param answerId Answer id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsAnswersAnswerIdPut(answerId: number, body?: AnswersAnswerIdBody, observe?: 'body', reportProgress?: boolean): Observable<StepAnswersResponse>;
    public apiStepQuestionsAnswersAnswerIdPut(answerId: number, body?: AnswersAnswerIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepAnswersResponse>>;
    public apiStepQuestionsAnswersAnswerIdPut(answerId: number, body?: AnswersAnswerIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepAnswersResponse>>;
    public apiStepQuestionsAnswersAnswerIdPut(answerId: number, body?: AnswersAnswerIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (answerId === null || answerId === undefined) {
            throw new Error('Required parameter answerId was null or undefined when calling apiStepQuestionsAnswersAnswerIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StepAnswersResponse>('put',`${this.basePath}/api/step/questions/answers/${encodeURIComponent(String(answerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create step answer
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsAnswersPost(body?: QuestionsAnswersBody, observe?: 'body', reportProgress?: boolean): Observable<StepAnswersResponse>;
    public apiStepQuestionsAnswersPost(body?: QuestionsAnswersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepAnswersResponse>>;
    public apiStepQuestionsAnswersPost(body?: QuestionsAnswersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepAnswersResponse>>;
    public apiStepQuestionsAnswersPost(body?: QuestionsAnswersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StepAnswersResponse>('post',`${this.basePath}/api/step/questions/answers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create step question
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsPost(body?: StepQuestionsBody, observe?: 'body', reportProgress?: boolean): Observable<CreatedStepQuestionResponse>;
    public apiStepQuestionsPost(body?: StepQuestionsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedStepQuestionResponse>>;
    public apiStepQuestionsPost(body?: StepQuestionsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedStepQuestionResponse>>;
    public apiStepQuestionsPost(body?: StepQuestionsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreatedStepQuestionResponse>('post',`${this.basePath}/api/step/questions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get step answers by question id
     *
     * @param questionId Question id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsQuestionIdAnswersGet(questionId: number, observe?: 'body', reportProgress?: boolean): Observable<StepAnswersResponse>;
    public apiStepQuestionsQuestionIdAnswersGet(questionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepAnswersResponse>>;
    public apiStepQuestionsQuestionIdAnswersGet(questionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepAnswersResponse>>;
    public apiStepQuestionsQuestionIdAnswersGet(questionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling apiStepQuestionsQuestionIdAnswersGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StepAnswersResponse>('get',`${this.basePath}/api/step/questions/${encodeURIComponent(String(questionId))}/answers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete question by id
     *
     * @param questionId Question id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsQuestionIdDelete(questionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiStepQuestionsQuestionIdDelete(questionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiStepQuestionsQuestionIdDelete(questionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiStepQuestionsQuestionIdDelete(questionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling apiStepQuestionsQuestionIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/step/questions/${encodeURIComponent(String(questionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get step question
     *
     * @param questionId Question id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsQuestionIdGet(questionId: number, observe?: 'body', reportProgress?: boolean): Observable<GetUpdateStepQuestionResponse>;
    public apiStepQuestionsQuestionIdGet(questionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUpdateStepQuestionResponse>>;
    public apiStepQuestionsQuestionIdGet(questionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUpdateStepQuestionResponse>>;
    public apiStepQuestionsQuestionIdGet(questionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling apiStepQuestionsQuestionIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUpdateStepQuestionResponse>('get',`${this.basePath}/api/step/questions/${encodeURIComponent(String(questionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update question by id
     *
     * @param questionId Question id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiStepQuestionsQuestionIdPut(questionId: number, body?: QuestionsQuestionIdBody, observe?: 'body', reportProgress?: boolean): Observable<GetUpdateStepQuestionResponse>;
    public apiStepQuestionsQuestionIdPut(questionId: number, body?: QuestionsQuestionIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUpdateStepQuestionResponse>>;
    public apiStepQuestionsQuestionIdPut(questionId: number, body?: QuestionsQuestionIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUpdateStepQuestionResponse>>;
    public apiStepQuestionsQuestionIdPut(questionId: number, body?: QuestionsQuestionIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling apiStepQuestionsQuestionIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetUpdateStepQuestionResponse>('put',`${this.basePath}/api/step/questions/${encodeURIComponent(String(questionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
