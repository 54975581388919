import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from "@angular/core";
import { LabelModel, MODEL } from "@rxzu/angular";

@Component({
  selector: 'itorum-mon-label',
  templateUrl: './mon-label.component.html',
  styleUrls: ['./mon-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonLabelComponent implements OnInit {

  @HostListener("mousedown", ["$event"])
  onMouseSown($event) {
    console.log('MonLabelComponent onmousedown =>', $event);
    $event.restrict_drawing = true;
  }

  constructor(
    @Inject(MODEL) public model: LabelModel,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.model.selectCoords().subscribe(() => {
      this.cdRef.detectChanges();
    });

    this.model
      .getParent()
      .selectHovered()
      .subscribe(() => {
        this.onHover();
      });

    this.model.setPainted(true);
  }

  onHover(): void {
    this.cdRef.detectChanges();
  }

  deleteLink() {
    const link = this.model.getParent();
    const pointPort = link.getSourcePort();
    const displayPort = link.getTargetPort();
    const point = pointPort.getParent();
    const display = displayPort.getParent();

    point.destroy();
    display.destroy();

    // pointPort.destroy();
    // displayPort.destroy();
    // link.destroy();
  }
}
