import { OrganizationType } from './organization-type.model';

export interface IOrganizationResponce {
  org_name: string;
  comment?: string;
  org_type: OrganizationType;
  organization_id: number;
}
export class Organization {
  org_name: string;
  comment?: string;
  org_type_id?: number;
  org_type: OrganizationType;
  organization_id: number;

  constructor(obj: IOrganizationResponce) {
    this.org_name = obj.org_name ? obj.org_name : this.org_name;
    this.comment = obj.comment ? obj.comment : this.comment;
    this.organization_id = obj.organization_id
      ? obj.organization_id
      : this.organization_id;
    this.org_type = obj.org_type ? new OrganizationType(obj.org_type) : <any>{};
  }
}
