class AnswerInitializer {
  answer_id: number;
  code: string;
  text: string;
  needComment: boolean;
  serialNumber: number;
  stepId: number;
  type: boolean;
  dependDeviationControl: boolean;

  constructor(obj: any) { // fixme why no interface here?
    if (obj['answer_id'] && typeof obj['answer_id'] === 'number') {
      this.answer_id = obj['answer_id'];
    }
    if (obj['code'] && typeof obj['code'] === 'string') {
      this.code = obj['code'];
    }
    if (obj['text'] && typeof obj['text'] === 'string') {
      this.text = obj['text'];
    }
    if (
      (obj['needComment'] || obj['needComment'] === false) &&
      typeof obj['needComment'] === 'boolean'
    ) {
      this.needComment = obj['needComment'];
    }

    // obj['serialNumber'] didn`t protect for zero value (NaN)
    this.serialNumber =
      typeof obj.serialNumber === 'number'
        ? obj.serialNumber
        : parseInt(obj.serialNumber, 10);

    if (
      (obj['stepId'] && typeof obj['stepId'] === 'number') ||
      typeof obj['stepId'] === 'string'
    ) {
      this.stepId = Number(obj['stepId']); // fixme, why stepId is probably string?
    }
    if (
      (obj['type'] || obj['type'] === false) &&
      typeof obj['type'] === 'boolean'
    ) {
      this.type = obj['type'];
    }
    if (
      (obj['dependDeviationControl'] ||
        obj['dependDeviationControl'] === false) &&
      typeof obj['dependDeviationControl'] === 'boolean'
    ) {
      this.dependDeviationControl = obj['dependDeviationControl'];
    }
  }
}

export class Answer extends AnswerInitializer {
  constructor(answer) {
    super(answer);
  }
}
