import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { WsState, WsStore } from "./ws.store";
import { ITagValue } from "@itorum/models";

@Injectable({
  providedIn: "root"
})
export class WsQuery extends Query<WsState> {
  constructor(
    protected tagsStore: WsStore
  ) {
    super(tagsStore);
  }

  getWsState(): Observable<WsState> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state: WsState) => {
      return state;
    });
  }

  getWsTagState(id: number): Observable<ITagValue> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state: WsState) => state.wsData.find(d => d.id === id));
  }

}
