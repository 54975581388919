import { PreloadingStrategy, Route } from '@angular/router';
import { Observable ,  timer ,  of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeShiftPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = delay =>
      delay ? timer(150).pipe(flatMap(() => load())) : load();
    return route.data && route.data.preload
      ? loadRoute(route.data.delay)
      : of(null);
  }
}
