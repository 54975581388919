interface ICoordinates {
  long?: string;
  lat?: string;
  description?: string;
}

export interface IMapMarker {
  id?: number;
  name?: string;
  code?: string;
  status?: MarkerStatus;
  favouriteTags?: Array<IMarkerTag>;
  coordinates?: ICoordinates;
  long?: string;
  lat?: string;
  parentId?: number;
  mnemonicScheme: any;
}

export enum MarkerStatus {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  IN_PROGRESS_ISSUES = 'in_progress_issues',
  STOPPED_PLAN = 'stopped_plan',
  STOPPED_ISSUES = 'stopped_issues'
}


export interface IMarkerTag {
  id?: number;
  name?: string;
  unit?: string;
  description?: string;
  lowVal?: number;
  hiVal?: number;
  type?: string;
}
