<div
  class="mon-pointer-node"
  (mouseover)="model.setHovered()"
  (mouseout)="model.setHovered(false)"
>

<!--  <div class="content">-->

<!--  </div>-->

  <div class="ports">
    <ng-template #portsLayer></ng-template>
  </div>
</div>
