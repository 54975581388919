import { Injectable, Inject } from '@angular/core';

@Injectable()
export class PrinterService {
  constructor(@Inject('window') private window: Window) {}

  public print(ctx: string) {
    const win = this.window.open();
    win.document.write(ctx);
    win.focus(); // Required for IE
    win.print();
    win.close();
  }

  public preview(ctx: string) {
    const win = this.window.open();
    win.document.write(ctx);
    win.focus(); // Required for IE
  }

  makeContextWithImage(img: HTMLImageElement) {
    return `<html><head></head><body><img src="${img.src}"/></body></html>`;
  }

  makeContextWithHTML(html: string) {
    return `<html><head></head><body>${html}</body></html>`;
  }
}
