import * as moment from 'moment';
import { formatTimeZoneDate } from "../utils";

export class Session {
  call_id: number;
  start_date: string;
  start_time: string;
  start_time_formated: string;
  start: string;
  end_time: string;
  end_time_formated: string;
  session_time: string;
  session_time_int: string;
  expert_fio: string;
  task_id: number;
  task_name: string;
  step_code: string;
  glasses: string;
  technik_fio: string;
  task: string;
  mode?: any;
  is_archived: boolean;

  constructor(obj) {
    if (obj['call_id'] && typeof obj['call_id'] === 'number') {
      this.call_id = obj['call_id'];
    }
    if (obj['start_time'] && typeof obj['start_time'] === 'string') {
      this.start_time = obj['start_time'];
      this.start_time_formated = formatTimeZoneDate(
        this.start_time,
        'Europe/Moscow',
        'DD.MM.YYYY H:mm:ss'
      );
      const time = moment.utc(
        moment(obj['end_time']).diff(moment(obj['start_time']))
      );

      this.session_time = time.format('HH:mm:ss');
      this.session_time_int = time.format('HHmmss');
    }
    if (obj['end_time'] && typeof obj['end_time'] === 'string') {
      this.end_time = obj['end_time'];
    }
    if (obj['expert_fio'] && typeof obj['expert_fio'] === 'string') {
      this.expert_fio = obj['expert_fio'];
    }
    if (obj['task_id'] && typeof obj['task_id'] === 'number') {
      this.task_id = obj['task_id'];
    }
    if (obj['task_name'] && typeof obj['task_name'] === 'string') {
      this.task_name = obj['task_name'];
    }
    if (obj['step_code'] && typeof obj['step_code'] === 'string') {
      this.step_code = obj['step_code'];
    }
    if (obj['glasses'] && typeof obj['glasses'] === 'string') {
      this.glasses = obj['glasses'];
    }
    if (obj['technik_fio'] && typeof obj['technik_fio'] === 'string') {
      this.technik_fio = obj['technik_fio'];
    }
    // if (obj['mode'] && typeof obj['mode'] === 'number') {
    //     this.mode = obj['mode'];
    //
    if (this.task_name && this.step_code) {
      this.task = `${this.task_name},${this.step_code}`;
    } else {
      this.task = null;
    }
    this.mode = obj['mode'];
    this.is_archived = obj.is_archived;
  }
}
