/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ExecutionAddstepsBody } from "../model/executionAddstepsBody";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";


@Injectable()
export class ExecutionTechCardService {

  protected basePath = "https://dev.itorum-mr.ru";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Execution tech-card
   *
   * @param mobileTest Flag mobile testing
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTechcardsExecutionAddStepsPost(mobileTest: boolean, body?: ExecutionAddstepsBody, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiTechcardsExecutionAddStepsPost(mobileTest: boolean, body?: ExecutionAddstepsBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiTechcardsExecutionAddStepsPost(mobileTest: boolean, body?: ExecutionAddstepsBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiTechcardsExecutionAddStepsPost(mobileTest: boolean, body?: ExecutionAddstepsBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (mobileTest === null || mobileTest === undefined) {
      throw new Error("Required parameter mobileTest was null or undefined when calling apiTechcardsExecutionAddStepsPost.");
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mobileTest !== undefined && mobileTest !== null) {
      queryParameters = queryParameters.set("mobileTest", <any>mobileTest);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/techcards/execution/add-steps`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
