import { Injectable } from '@angular/core';
import { TagsStore } from "./tags.store";
import { IMarkerTag } from "@itorum/models";

@Injectable({ providedIn: 'root' })
export class TagsService {

  constructor(private tagsStore: TagsStore) {
  }

  captureTag(tag: IMarkerTag) {
    this.tagsStore.update((state) => {
      return {
        captured: [...state.captured, tag],
        free: state.free.filter((f) => f.id !== tag.id)
      }
    })
  }

  releaseTag(tag: IMarkerTag) {
    this.tagsStore.update((state) => {
      return {
        captured: state.captured.filter((c) => c.id !== tag.id),
        free: [...state.free, tag]
      }
    })
  }

  initTags(tags: IMarkerTag[]) {
      console.log('initTags =>', tags);
    this.tagsStore.update(() => {
      return {
        captured: [],
        free: [...tags]
      }
    })
  }

}
