import {
  Directive,
  TemplateRef,
  SimpleChanges,
  IterableDiffers,
  OnChanges
} from '@angular/core';
import { CanStick, mixinHasStickyInput } from '../can-stick';
import { BaseRowDefDirective } from '../classes/base-row-def.class';
import { ERowType } from '../table.interfaces';

@Directive()
class ItrFooterRowDefBaseDirective extends BaseRowDefDirective {}
const _ItrFooterRowDefBase = mixinHasStickyInput(ItrFooterRowDefBaseDirective);

@Directive({
  selector: '[itrFooterRowDef]',
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['columns: itrFooterRowDef', 'sticky: itrFooterRowDefSticky']
})
export class ItrFooterRowDefDirective extends _ItrFooterRowDefBase
  implements CanStick, OnChanges {
  type = ERowType.FOOTER;
  constructor(template: TemplateRef<any>, _differs: IterableDiffers) {
    super(template, _differs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
