/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InlineResponse20010Data {
  id?: number;
  code?: string;
  type?: string;
  status?: InlineResponse20010Data.StatusEnum;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

export namespace InlineResponse20010Data {
  export type StatusEnum = "new" | "deleted";
  export const StatusEnum = {
    New: "new" as StatusEnum,
    Deleted: "deleted" as StatusEnum
  };
}
