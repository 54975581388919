<section class="p-2" style="width: 250px">
  <article class="flex justify-end w-full">
    <i class="icon-cross_clear_black hover:text-primary" (click)="doClose.emit(true)" ></i>
  </article>

  <mat-form-field>
    <mat-select [formControl]="sourceCtrl" placeholder="{{ 'device-monitor.mon-display.source' | translate }}" #singleSelect>
      <mat-option>
        <ngx-mat-select-search [formControl]="sourceFilterCtrl">
          <i ngxMatSelectSearchClear class="icon-tf_delete text-xl"></i>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let source of filteredSources | async" [value]="source">
        {{source.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <article class="flex justify-center w-full">
    <button
      mat-raised-button
      (click)="addToObserveList()"
      color="primary"
      [disabled]="!sourceCtrl.value"
    >{{ 'device-monitor.mon-display.add' | translate }}</button>
  </article>
</section>
