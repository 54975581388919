import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CanDisable } from "@angular/material/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[itorum-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "[attr.disabled]": "disabled || null",
    // "[style.width]": "'initial'",
    // "[style.height]": "'initial'"
  },
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, CanDisable {
  @Input() color = 'primary'; // todo not implemented yet
  @Input() disabled = false;


  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // a
  }

}
