import { Directive, TemplateRef, IterableDiffers } from '@angular/core';
import { BaseRowDefDirective } from '../classes/base-row-def.class';
import { ERowType } from '../table.interfaces';

@Directive({
  selector: '[itrRowDef]',
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['columns: itrRowDefColumns', 'when: itrRowDefWhen']
})
export class ItrRowDefDirective<T> extends BaseRowDefDirective {
  type = ERowType.ROW;
  when: (index: number, rowData: T) => boolean;

  constructor(template: TemplateRef<any>, _differs: IterableDiffers) {
    super(template, _differs);
  }
}
