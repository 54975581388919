import { Injectable } from "@angular/core";
import { CommonStateStore } from "app/common/state/common-state.store";
import { ICallCandidate } from "@itorum/models";

@Injectable({
  providedIn: "root"
})
export class CommonStateService {

  constructor(private commonStateStore: CommonStateStore) {
  }

  updateCallCandidates(callCandidates: ICallCandidate[]) {
    this.commonStateStore.update((state) => {
      return {
        ...state,
        callCandidates
      };
    });
  }

  updateOneCallCandidate(user: ICallCandidate) {
    this.commonStateStore.update((state) => {
      const match = state.callCandidates.find(cc => cc.id === user.id);

      if (match) {
        const filtered = state.callCandidates.filter(cc => cc.id !== user.id);
        return {
          ...state,
          callCandidates: [
            user,
            ...filtered
          ]
        };
      } else {
        return {
          ...state,
          callCandidates: [
            ...state.callCandidates,
            user
          ]
        };

      }
    });
  }
}
