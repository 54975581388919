import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CallTimerService {
  private sessionTime$$ = new Subject<string>();
  private formatter: Intl.DateTimeFormat;
  private intervalTimer: number;
  private duration: number; // in seconds
  private startTime: number;
  public sessionTime$ = this.sessionTime$$.asObservable();
  public callingUser = '';

  constructor() {
    this.formatter = new Intl.DateTimeFormat('ru', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'UTC'
    });
  }

  public get durationSeconds(): number {
    return this.duration;
  }

  public setUserName(callingUser: string) {
    this.callingUser = callingUser;
  }

  public startTimer() {
    this.startTime = Date.now();

    this.intervalTimer = window.setInterval(() => {
      this.duration = Math.round((Date.now() - this.startTime) / 1000);
      this.sessionTime$$.next(
        this.formatter.format(Date.now() - this.startTime)
      );
    }, 1000);
  }

  public stopTimer() {
    window.clearInterval(this.intervalTimer);
    this.sessionTime$$.next('');
    this.callingUser = '';
  }
}
