import { Injectable } from '@angular/core';

interface IPoint {
  x: number;
  y: number;
}

type ICanvasSizes = ClientRect & {
  /** коэффициент: отношение размеров холста к размерам блока-элемента канваса по горизонтали  */
  aspectX: number;
  /** коэффициент: отношение размеров холста к размерам блока-элемента канваса по вертикали  */
  aspectY: number;
};

export type TPencilWidth = 5 | 10 | 20;
export type TPencilTool = 'pencil' | 'circle' | 'arrow' | 'line' | 'square';
export type TPencilColors =
  | '#333'
  | '#0f58ff'
  | '#7200ab'
  | '#ff0000'
  | '#ffff00';

export interface IPencilParams {
  width: TPencilWidth;
  tool: TPencilTool;
  color: TPencilColors;
}

@Injectable()
export class PainterService {
  public pencilTool: TPencilTool = 'pencil';
  public pencilWidth: TPencilWidth = 10;
  public pencilColor: TPencilColors = '#ff0000';

  private isDrawing = false;

  private localCtx: CanvasRenderingContext2D;
  private tempCtx: CanvasRenderingContext2D;

  private previousPoint: IPoint;
  private startPoint: IPoint;
  private currentPoint: IPoint;

  private canvasSizes: ICanvasSizes;

  public initialize({
    localCtx,
    tempCtx
  }: {
    localCtx: CanvasRenderingContext2D;
    tempCtx: CanvasRenderingContext2D;
  }) {
    this.localCtx = localCtx;
    this.tempCtx = tempCtx;
  }

  private calculateCanvasSizes(canvas: HTMLCanvasElement): ICanvasSizes {
    const canvasSizes = canvas.getBoundingClientRect();
    return {
      aspectX: this.localCtx.canvas.width / canvasSizes.width,
      aspectY: this.localCtx.canvas.height / canvasSizes.height,
      top: canvasSizes.top,
      bottom: canvasSizes.bottom,
      height: canvasSizes.height,
      left: canvasSizes.left,
      right: canvasSizes.right,
      width: canvasSizes.width
    };
  }

  /**
   * координаты на канвасе, с учетом разницы размеров канваса и размера блока, и с учетом положения канваса относительно окна взятого из calculateCanvasSizes
   * @param {MouseEvent} event
   */
  private getRelativeMouseCoordinates(
    event: MouseEvent,
    canvasSizes: ICanvasSizes
  ): IPoint {
    return {
      x: (event.clientX - canvasSizes.left) * canvasSizes.aspectX,
      y: (event.clientY - canvasSizes.top) * canvasSizes.aspectY
    };
  }

  /**
   * at mouse down
   * @param {MouseEvent} event
   */
  public drawingBegin(event: MouseEvent): void {
    if (this.isDrawing) {
      return;
    }

    this.canvasSizes = this.calculateCanvasSizes(this.localCtx.canvas);
    this.isDrawing = true;
    this.startPoint = this.getRelativeMouseCoordinates(event, this.canvasSizes);
    this.previousPoint = this.startPoint;

    if (this.pencilTool === 'pencil') {
      // если карандашик, то рисуем первую точку
      this.previousPoint = this.drawPen(
        this.tempCtx,
        this.startPoint,
        this.previousPoint
      );
    } else {
      // если фигура, то очищаем временную канву для фигур
      this.clearTempCtx();
    }
  }

  /**
   * at mouse move
   * @param {MouseEvent} event
   */
  public drawingProcess(event: MouseEvent): void {
    if (!this.isDrawing) {
      return;
    }

    this.currentPoint = this.getRelativeMouseCoordinates(
      event,
      this.canvasSizes
    );

    if (this.pencilTool !== 'pencil') {
      this.clearTempCtx();
    }
    switch (this.pencilTool) {
      case 'pencil':
        this.previousPoint = this.drawPen(
          this.localCtx,
          this.previousPoint,
          this.currentPoint
        );
        break;
      case 'circle':
        this.previousPoint = this.drawEllipse(
          this.tempCtx,
          this.startPoint,
          this.currentPoint
        );
        break;
      case 'arrow':
        this.previousPoint = this.drawArrow(
          this.tempCtx,
          this.startPoint,
          this.currentPoint
        );
        break;
      case 'line':
        this.previousPoint = this.drawLine(
          this.tempCtx,
          this.startPoint,
          this.currentPoint
        );
        break;
      case 'square':
        this.previousPoint = this.drawSquare(
          this.tempCtx,
          this.startPoint,
          this.currentPoint
        );
        break;
    }
  }

  /**
   * at mouse up
   */
  public drawingEnd(): void {
    if (!this.isDrawing) {
      return;
    }
    this.isDrawing = false;

    switch (this.pencilTool) {
      case 'circle':
        this.drawEllipse(this.localCtx, this.startPoint, this.previousPoint);
        break;
      case 'arrow':
        this.drawArrow(this.localCtx, this.startPoint, this.previousPoint);
        break;
      case 'line':
        this.drawLine(this.localCtx, this.startPoint, this.previousPoint);
        break;
      case 'square':
        this.drawSquare(this.localCtx, this.startPoint, this.previousPoint);
        break;
    }
    this.clearTempCtx();
  }

  private drawPen(
    ctx: CanvasRenderingContext2D,
    from: IPoint,
    to: IPoint
  ): IPoint {
    const [_from, _to] = this.limitCoordinates(from, to);

    ctx.beginPath();
    this.ctxColoring(ctx);
    ctx.moveTo(_from.x, _from.y);
    ctx.lineTo(_to.x, _to.y);
    ctx.stroke();
    ctx.arc(_to.x, _to.y, this.pencilWidth / 2, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
    return _to; // to previous point
  }

  private drawEllipse(
    ctx: CanvasRenderingContext2D,
    from: IPoint,
    to: IPoint
  ): IPoint {
    const [_from, _to] = this.limitCoordinates(from, to);
    const radiusX = Math.abs((_to.x - _from.x) / 2);
    const radiusY = Math.abs((_to.y - _from.y) / 2);
    const centerX = Math.min(_from.x, _to.x) + radiusX;
    const centerY = Math.min(_from.y, _to.y) + radiusY;

    ctx.beginPath();
    ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);

    ctx.closePath();
    this.ctxColoring(ctx);
    ctx.stroke();
    return _to;
  }

  private drawArrow(
    ctx: CanvasRenderingContext2D,
    from: IPoint,
    to: IPoint
  ): IPoint {
    const [_from, _to] = this.limitCoordinates(from, to);
    const headLen = 10;
    const angle = Math.atan2(_to.y - _from.y, _to.x - _from.x);
    this.ctxColoring(ctx);

    // starting path of the arrow from the start square to the end square and drawing the stroke
    ctx.beginPath();
    ctx.moveTo(_from.x, _from.y);
    ctx.lineTo(_to.x, _to.y);

    ctx.stroke();

    // starting a new path from the head of the arrow to one of the sides of the point
    ctx.beginPath();
    ctx.moveTo(_to.x, _to.y);
    ctx.lineTo(
      _to.x - headLen * Math.cos(angle - Math.PI / 7),
      _to.y - headLen * Math.sin(angle - Math.PI / 7)
    );

    // path from the side point of the arrow, to the other side point
    ctx.lineTo(
      _to.x - headLen * Math.cos(angle + Math.PI / 7),
      _to.y - headLen * Math.sin(angle + Math.PI / 7)
    );

    // path from the side point back to the tip of the arrow, and then again to the opposite side point
    ctx.lineTo(_to.x, _to.y);
    ctx.lineTo(
      _to.x - headLen * Math.cos(angle - Math.PI / 7),
      _to.y - headLen * Math.sin(angle - Math.PI / 7)
    );

    // draws the paths created above
    ctx.closePath();
    ctx.stroke();
    ctx.fill();

    return _to;
  }

  private drawSquare(
    ctx: CanvasRenderingContext2D,
    from: IPoint,
    to: IPoint
  ): IPoint {
    const [_from, _to] = this.limitCoordinates(from, to);
    // starting path of the arrow from the start square to the end square and drawing the stroke
    ctx.beginPath();
    ctx.rect(_from.x, _from.y, _to.x - _from.x, _to.y - from.y);
    ctx.closePath();
    this.ctxColoring(ctx);
    ctx.stroke();

    return _to;
  }

  private drawLine(
    ctx: CanvasRenderingContext2D,
    from: IPoint,
    to: IPoint
  ): IPoint {
    const [_from, _to] = this.limitCoordinates(from, to);

    // starting path of the arrow from the start square to the end square and drawing the stroke
    ctx.beginPath();
    ctx.moveTo(_from.x, _from.y);
    ctx.lineTo(_to.x, _to.y);

    ctx.closePath();
    this.ctxColoring(ctx);
    ctx.stroke();
    ctx.fill();

    return _to;
  }

  private clearTempCtx() {
    this.tempCtx.clearRect(
      0,
      0,
      this.tempCtx.canvas.width,
      this.tempCtx.canvas.height
    );
  }

  private ctxColoring(ctx: CanvasRenderingContext2D) {
    ctx.strokeStyle = this.pencilColor;
    ctx.lineWidth = this.pencilWidth;
    ctx.fillStyle = this.pencilColor;
  }

  private limitCoordinates(from: IPoint, to: IPoint): [IPoint, IPoint] {
    return [
      {
        x: Math.max(0, Math.min(from.x, this.localCtx.canvas.width)),
        y: Math.max(0, Math.min(from.y, this.localCtx.canvas.height))
      },
      {
        x: Math.max(0, Math.min(to.x, this.localCtx.canvas.width)),
        y: Math.max(0, Math.min(to.y, this.localCtx.canvas.height))
      }
    ];
  }

  public setPencilColor(color: TPencilColors) {
    this.pencilColor = color || this.pencilColor;
  }

  public setPencilWidth(width: TPencilWidth) {
    this.pencilWidth = width || this.pencilWidth;
  }

  public setPencilTool(tool: TPencilTool) {
    this.pencilTool = tool || this.pencilTool;
  }
}
