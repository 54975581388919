import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldSelectComponent } from './select/select.component';
import { FieldInputComponent } from './input/input.component';
import { FieldAutocompleteComponent } from './autocomplete/autocomplete.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

/**
 * @deprecated
 */
@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatAutocompleteModule,
    BsDatepickerModule
  ],
  exports: [
    FieldSelectComponent,
    FieldInputComponent,
    FieldAutocompleteComponent
  ],
  declarations: [
    FieldSelectComponent,
    FieldInputComponent,
    FieldAutocompleteComponent
  ]
})
export class FieldsModule {}
