<div
  *ngIf="visibility"
  class="right drop-list fixed-size filter-popup"
  style="display: block"
  [ngClass]="{ 'filter-popup-default': !enableSearch, 'filter-right': right }"
>
  <div *ngIf="enableSearch" class="filter-popup-header flex align-items-center">
    <i class="icon-search"></i>
    <input
      #objectTypesSearch
      type="text"
      class="c-input borderless"
      [placeholder]="'main.search' | translate"
      [value]="subjSearch.value"
      (input)="subjSearch.next(objectTypesSearch.value)"
    />
    <button
      [class.hidden]="!subjSearch.value"
      type="reset"
      class="filter-popup-header-close"
      (click)="resetSearch()"
    ></button>
  </div>
  <perfect-scrollbar
    *ngIf="variants && list"
    style="max-width: 600px; max-height: 190px;"
  >
    <div style="margin-top: 15px">
      <div class="line" *ngFor="let item of list | async">
        <div class="custom-checkbox">
          <input
            type="checkbox"
            id="table-filter-{{ item.id }}"
            #input
            name="objectTypeId[]"
            [value]="item[bindValue]"
            (click)="selectedList.toggle(item[bindValue])"
            [checked]="selectedList.contains(item[bindValue])"
          />
          <label for="table-filter-{{ item.id }}">
            {{ item[bindLabel] | translate }}
          </label>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <div class="buttons">
    <button class="cancel" type="button" (click)="cancel()">
      {{ 'main.reset' | translate }}
    </button>
    <button class="save" type="submit" (click)="save()">ОК</button>
  </div>
</div>
