import { AbstractControl, ValidatorFn } from '@angular/forms';

export function SameValues(
  firstValue: string,
  secondValue: string
): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any } => {
    return c.get(firstValue).value === c.get(secondValue).value
      ? null
      : {
          valid: true
        };
  };
}
