import { Injectable } from '@angular/core';
import { RoomStateStore } from './room-state.store';
import { IControlChatMessageBody, IFeed } from "@itorum/models";

@Injectable({ providedIn: 'root' })
export class RoomStateService {

  constructor(private roomStateStore: RoomStateStore) {
  }

  doWidescreen(mode: boolean) {
    this.roomStateStore.update((store) => {
      return {
        ...store,
        isWidescreen: mode
      }
    })
  }

  updateFeeds(feed: IFeed) {
    console.log('try to updateFeeds =>', feed);
    this.roomStateStore.update((state) => {
      const match: IFeed[] = state.feeds.filter(f => f.rfid !== feed.rfid)
      return {
        ...state,
        feeds: [
          ...match,
          feed
        ]
      }
    })
  }

  deleteFeeds(feeds: IFeed[]) {
    this.roomStateStore.update((state) => {
      const match: IFeed[] = state.feeds.filter( f => !feeds.some(df => df.rfid === f.rfid));
      return {
        ...state,
        feeds: [
          ...match
        ]
      }

    })
  }

  doShowDraw(value: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isShowDraw: value
      }
    });
  }

  doShareState(value: boolean) {
    this.roomStateStore.update( (state) => {
        return {
          ...state,
          isNowShared: value
        }
    })
  }

  updateFile(file: File | Blob | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        file
      }
    })
  }

  updateImage(image: string | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        image
      }
    })
  }

  updateChatMessages(msg: IControlChatMessageBody) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        chatMessages: [
          ...state.chatMessages,
          msg
        ]
      }
    })
  }

  cleanChatMessages() {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        chatMessages: []
      }
    })
  }


  updateReqScreenshotSrc(url: string | undefined) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        reqScreenshotUrl: url
      }
    })
  }

  updateSideModalOpenState(isSideModalOpen: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isSideModalOpen
      }
    })
  }

  updateMuteAllPublishers(doMute: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        isAllPublishersMuted: doMute
      }
    })
  }

  doTerminateWorker(doTerminate: boolean) {
    this.roomStateStore.update((state) => {
      return {
        ...state,
        doTerminateWorker: doTerminate
      }
    })
  }

  updateIsGuardActive(isGuardActive: boolean) {
    this.roomStateStore.update( (state) => {
      return {
        ...state,
        isGuardActive
      }
    })
  }
}
