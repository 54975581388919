import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { AppConfigService } from '@shared/services/app-config.service';
import { WebRtcPeer } from 'kurento-utils';
import { ToastrService } from 'ngx-toastr';
import { Subject, BehaviorSubject, Observable, zip, from as fromPromise } from 'rxjs';
import { filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { CallTimerService } from './call-timer.service';
import { WebRecorderService } from '@src/app/shared';
import {
  IWebSocketResponse,
  WebSocketService,
  WsRequestIds,
  WsResponseIds
} from './web-socket.service';

interface RTCDataChannel {
  readyState: string;
  label: string;
  send(data: string): void;
  close(): void;
}

@Injectable()
export class WebCallService {
  private callUp$$ = new Subject();
  public readonly callUp$ = this.callUp$$.asObservable();
  private callDown$$ = new Subject();
  public readonly callDown$ = this.callDown$$.asObservable();
  public callStop$ = new Subject();

  public localCanvas$ = new Subject();
  public localVideo$ = new Subject();
  public remoteCanvas$ = new Subject();
  public remoteVideo$ = new Subject();

  private stoppers: any = {};

  /** incoming video from technic, remoteVideo in WebRtcPeer */
  private remoteVideo: HTMLVideoElement;
  /** canvas - source for stream transmitted to technic */
  private localCanvas: HTMLCanvasElement;
  private localVideo: HTMLVideoElement;
  private remoteCanvas: HTMLCanvasElement;

  private webRtcPeer: WebRtcPeer;
  /** MediaStream from localCanvas */
  private canvasStream: MediaStream;

  /** composited MediaStream */
  private outComingStream: MediaStream;
  private intervalHandle: number;

  private iceServers: RTCIceServer[];

  private callProcessing = false;

  private isRemoteAudioEnabled = true;
  private isLocalAudioEnabled = true;

  /** flag to turn on reversal record of 404 video */
  private isReverseRecording = false;
  private readyState$ = new BehaviorSubject(false);

  private fromUserId: number;
  private channel: RTCDataChannel;

  public downloadedScreenshot$ = new Subject<ImageBitmap>();
  public deviceName: string;
  public taskId: number;

  get sessionTime$() {
    return this.callTimerService.sessionTime$;
  }

  constructor(
    private webSocketService: WebSocketService,
    // private toastsManager: ToastsManager,
    private toastrService: ToastrService,
    private webRecorderService: WebRecorderService,
    private apiService: ApiService,
    public appConfigService: AppConfigService,
    public callTimerService: CallTimerService
  ) {
    // handlers of incoming websocket messages from SignalServer
    this.webSocketService.webCallBus$.subscribe(
      (parsedMessage: IWebSocketResponse) => {
        switch (parsedMessage.id) {
          case WsResponseIds.CALL_START_COMMUNICATION:
            this.startCommunication(parsedMessage as {
              id: WsResponseIds.CALL_START_COMMUNICATION;
              sdpAnswer: string;
            });
            break;
          case WsResponseIds.CALL_STOP_COMMUNICATION:
            this.callStop$.next(parsedMessage.message);
            this.stop(true, parsedMessage.message);
            break;
          case WsResponseIds.CALL_CONNECT_RELEASED:
            if (parsedMessage.message) {
              this.toastrService
                .warning('Звонок завершен по причине разрыва связи с техником');
            }
            this.stop(true);
            break;
          case WsResponseIds.CALL_ICE_CANDIDATE:
            if (!this.webRtcPeer) {
              this.callStop$.next('Звонок завершен по причине разрыва связи с техником');
              return this.stop(true, 'Звонок завершен по причине разрыва связи с техником');
            }
            this.webRtcPeer.addIceCandidate(
              parsedMessage.candidate,
              err => err && console.error(err)
            );
            break;
          default:
        }
      }
    );

    this.webSocketService.noticeBus$.subscribe(
      (parsedMessage: IWebSocketResponse) => {
        switch (parsedMessage.id) {
          case WsResponseIds.CALL_INCOMING_CANCEL:
          case WsResponseIds.CALL_INCOMING_CANCELED:
            this.callStop$.next(parsedMessage.message);
            break;
        }
      }
    );
  }

  get inCall(): boolean {
    return this.webRtcPeer && this.callProcessing;
  }

  get remoteAudioEnabled(): boolean {
    return this.webRtcPeer && this.isRemoteAudioEnabled;
  }

  get localAudioEnabled(): boolean {
    return this.outComingStream.getAudioTracks()[0].enabled;
  }

  get initiated$() {
    return this.readyState$.pipe(filter(Boolean));
  }

  setLocals({
    localCanvas,
    localVideo
  }: {
    localCanvas: HTMLCanvasElement;
    localVideo: HTMLVideoElement;
  }) {
    this.localCanvas = localCanvas;
    this.localVideo = localVideo;
    this.localCanvas$.next(localCanvas);
    this.localVideo$.next(localVideo);
    this.readyState$.next(Boolean(this.localVideo && this.remoteVideo));
  }

  setRemotes({
    remoteCanvas,
    remoteVideo
  }: {
    remoteCanvas: HTMLCanvasElement;
    remoteVideo: HTMLVideoElement;
  }) {
    this.remoteCanvas = remoteCanvas;
    this.remoteVideo = remoteVideo;
    this.remoteCanvas$.next(remoteCanvas);
    this.remoteVideo$.next(remoteVideo);
    this.readyState$.next(Boolean(this.localVideo && this.remoteVideo));
  }

  public callPageReady(): Promise<any> {
    return new Promise(resolve => {
      zip(
        this.localCanvas$,
        this.localVideo$,
        this.remoteCanvas$,
        this.remoteVideo$,
      )
      .pipe(take(1))
      .subscribe(resolve);
    });
  }

  /**
   * invokes after expert accepted incoming call
   * checks for DOM and call 'acceptIncomingCall'
   */
  public onCallAccept({
    callingUserName,
    isReverseRecording,
    iceServers,
    fromUserId,
    deviceName,
    taskId
  }) {
    this.deviceName = deviceName;
    this.taskId = taskId;
    this.fromUserId = fromUserId;
    this.isReverseRecording = isReverseRecording;
    this.iceServers = iceServers;
    if (this.isReverseRecording) {
      this.webRecorderService.setIceServers(this.iceServers);
    }
    this.acceptIncomingCall({
      fio: callingUserName,
      id: fromUserId
    });
  }

  public toggleLocalAudioMute() {
    if (this.outComingStream) {
      this.isLocalAudioEnabled = !this.isLocalAudioEnabled;
      this.outComingStream.getAudioTracks()[0].enabled = this.isLocalAudioEnabled;
      console.log(
        'toggleLocalAudioMute',
        this.outComingStream.getAudioTracks()[0].enabled
      );
    }
  }

  public toggleRemoteAudioMute() {
    if (this.webRtcPeer) {
      this.isRemoteAudioEnabled = !this.isRemoteAudioEnabled;
      this.webRtcPeer
        .getRemoteStream()
        .getAudioTracks()[0].enabled = this.isRemoteAudioEnabled;
      console.log(
        'toggleRemoteAudioMute',
        this.webRtcPeer.getRemoteStream().getAudioTracks()[0].enabled
      );
    }
  }

  public cancelCall() {
    if (this.callProcessing === false) {
      return;
    }
    this.webSocketService.sendData({ id: WsRequestIds.CALL_TIMEOUT });
    this.toastrService.warning(
      'Звонок завершен по причине разрыва связи с техником'
    );
    this.callDown$$.next();
  }

  /**
   * stop call at technic hang up
   */
  public stop(remoteStop: boolean = false, message = '') {
    if (!this.inCall) {
      return;
    }

    if (!remoteStop) {
      this.webSocketService.sendData({ id: WsRequestIds.CALL_STOP });
    }
    this.doCallEnd();
  }

  public saveImage(image: ImageBitmap) {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.createElement('CANVAS')
    );
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext('2d').drawImage(image, 0, 0);

    this.webSocketService.sendData({
      id: WsRequestIds.CALL_SAVE_IMAGE,
      call_time: this.callTimerService.durationSeconds,
      imagedata: canvas.toDataURL()
    });
  }

  public channelChatMessage(text: string): boolean {
    this.webSocketService.sendData({
      id: WsRequestIds.CALL_SAVE_MESSAGE,
      text,
      call_time: this.callTimerService.durationSeconds // время в секундах с начала установки видеосоединения
    });
    return this.channelSend({
      id: 'message',
      text
    });
  }

  public webRtcSwitchFlow(flow: 'itorumlocal' | 'itorumremote'): boolean {
    return this.channelSend({ id: 'command', text: flow });
  }

  public channelGetScreenshot() {
    return this.channelSend({
      id: 'command',
      text: 'getScreenshot'
    });
  }

  private getIceServers(): Observable<RTCIceServer[]> {
    return this.webSocketService
      .request(
        { id: WsRequestIds.CALL_GET_ICE_SERVERS },
        WsResponseIds.CALL_GET_ICE_SERVERS
      )
      .pipe(
        pluck('result'),
        map((servers: RTCIceServer[]) => servers || [])
      );
  }

  private onDataChannelMessage(evt) {
    const message = evt.data;
    try {
      const messageData = JSON.parse(message);
      switch (messageData.id) {
        case 'preview_upload':
          this.onPreviewUpload(messageData.text);
          break;
        default:
      }
      console.log('channel %creceive', 'color: blue', messageData);
    } catch (error) {
      console.log('channel %creceive', 'color: blue', message);
    }
  }

  private onDataChannelOpened(evt: Event) {
    this.channel = evt.target as any;
    console.log('data channel', this.channel.label, this.channel.readyState);
    setTimeout(
      () =>
        this.channelSend({
          id: 'command',
          text: 'hello'
        }),
      500
    );
  }

  private channelSend(message: { id: string; text: string }): boolean {
    if (!this.inCall) {
      return false;
    }

    this.channel.send(JSON.stringify(message));
    console.log('channel %csent', 'color: red', message);
    return true;
  }

  private onIceCandidate(candidate) {
    this.webSocketService.sendData({
      id: WsRequestIds.CALL_ICE_CANDIDATE,
      candidate: candidate
    });
  }

  private startAutoRefreshScreen() {
    const ctx = this.localCanvas.getContext('2d');
    this.intervalHandle = window.setInterval(() => {
      const saveFillStyle = ctx.fillStyle;

      ctx.fillStyle =
        '#' + Math.ceil(Math.random() * 100).toString(16) + 'ffff';
      ctx.fillRect(0, 0, 1, 1);

      ctx.fillStyle = saveFillStyle;
    }, 500);
  }

  private stopAutoRefreshScreen() {
    window.clearInterval(this.intervalHandle);
  }

  private doCallEnd() {
    this.callProcessing = false;

    this.stopAutoRefreshScreen();
    this.callTimerService.stopTimer();

    // clear composited MediaStream
    if (this.outComingStream) {
      this.outComingStream.getTracks().forEach(track => {
        track.stop();
      });
      this.outComingStream = null;
    }

    if (this.webRtcPeer) {
      this.webRtcPeer.dispose();
      this.webRtcPeer = null;
    }

    if (this.isReverseRecording) {
      this.webRecorderService.stop();
    }
    this.callDown$$.next();
    console.log('Call complete ok');
    setTimeout(console.groupEnd);
  }

  /**
   * really start the video call
   */
  private startCommunication(message: {
    id: WsResponseIds.CALL_START_COMMUNICATION;
    sdpAnswer: string;
  }) {
    this.callProcessing = true;
    this.startAutoRefreshScreen();
    this.callUp$$.next();
    this.webRtcPeer.processAnswer(message.sdpAnswer, async err => {
      if (err) {
        console.error(err);
        return this.cancelCall();
      }
      await this.remoteVideo.play();
      this.callTimerService.startTimer();
      this.clearLocal();
      this.cyclicApiStart();
    });
  }

  private cyclicApiStart() {
    const cyclicApiInterval = window.setInterval(() => {
      if (!this.inCall) {
        return window.clearInterval(cyclicApiInterval);
      }
      this.apiService.get('/api/user/empty').subscribe();
    }, 10 * 60 * 1000);
  }

  /**
   * create options, prepare webRtcPeer and send sdpOffer
   * after this ws message startComminication cause videocall through 'startComminication' method
   */
  private async acceptIncomingCall(from: { id: number; fio: string }) {
    try {
      console.group('acceptIncomingCall ' + from.id + '/' + from.fio);
      console.log('Reverse recording:', this.isReverseRecording);
      this.callTimerService.setUserName(from.fio);
      const localStream = await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .catch(audioError => {
          console.error('FAILED GET USER AUDIO');
          throw audioError;
        });
      if (!localStream) {
        return;
      }

      this.canvasStream = (this.localCanvas as any).captureStream();

      this.isRemoteAudioEnabled = true;
      this.isLocalAudioEnabled = true;

      this.outComingStream = new MediaStream();
      /** technic's videotrack */
      this.outComingStream.addTrack(this.canvasStream.getVideoTracks()[0]);
      /** local usermedia audiotrack */
      this.outComingStream.addTrack(localStream.getAudioTracks()[0]);

      this.webRtcPeer = <WebRtcPeer>await new Promise((resolve, reject) => {

        WebRtcPeer.WebRtcPeerSendrecv(this.getWebRtcOptions(), function(error) {
          if (error) {
            this.doCallEnd();
            console.error(error);
            reject('Error on creating the WebRtcPeer');
          }
          resolve(this);
        });
      });

      this.webRtcPeer.generateOffer((offerError, offerSdp) => {
        if (offerError) {
          console.warn('Error on generating the offer', offerError);
          return this.doCallEnd();
        }

        this.webSocketService.sendData({
          id: WsRequestIds.CALL_INCOMING_RESPONSE,
          from: this.callTimerService.callingUser,
          fromUserId: from.id,
          callResponse: 'accept',
          sdpOffer: offerSdp
        });
      });

      /** as we got videotrack from technic, if 404 mirror is enabled start it */
      if (this.isReverseRecording) {
        this.webRtcPeer.peerConnection.addEventListener('track', evt => {
          if (evt['track'].kind === 'video') {
            this.webRecorderService.start(
              this.remoteVideo,
              this.webRtcPeer.getRemoteStream()
            );
          }
        });
      }

      this.debugWebRtcPeer();
    } catch (error) {
      console.error(error);
      this.cancelCall();
    }
  }

  private getWebRtcOptions() {
    return {
      remoteVideo: this.remoteVideo,
      videoStream: this.outComingStream,
      onicecandidate: candidate => this.onIceCandidate(candidate),
      dataChannels: true,
      dataChannelConfig: {
        id: 'callDataChannel' + this.fromUserId,
        onmessage: e => this.onDataChannelMessage(e),
        onopen: e => this.onDataChannelOpened(e),
        onbufferedamountlow: e =>
          console.warn('webRtcPeer buffered amount low', e),
        onerror: e => {
          console.warn('webRtcPeer channel error', e);
          this.cancelCall();
        }
      },
      configuration: {
        iceServers: this.iceServers
      }
    };
  }

  private debugWebRtcPeer() {
    // return;
    if (this.appConfigService.appConfig.webrtcLog) {
      // no event log for production
      return;
    }
    const peerConnection: RTCPeerConnection = this.webRtcPeer.peerConnection;
    peerConnection.addEventListener('connectionstatechange', evt => {
      if ((<any>peerConnection).connectionState === 'failed') {
        this.cancelCall();
      }
      console.log('connection state:', (<any>peerConnection).connectionState);
    });
    peerConnection.addEventListener('icecandidateerror', evt => {
      console.error('icecandidateerror', peerConnection);
    });
    peerConnection.addEventListener('iceconnectionstatechange', evt => {
      console.log('iceconnectionstatechange', peerConnection.iceConnectionState);
    });
    peerConnection.addEventListener('icegatheringstatechange', evt => console.log('icegatheringstatechange', peerConnection.iceGatheringState));
    peerConnection.addEventListener('negotiationneeded', evt => console.log('negotiationneeded', peerConnection));
    peerConnection.addEventListener('signalingstatechange', evt =>
      console.log(`signaling state: ${peerConnection.signalingState}`)
    );
    peerConnection.addEventListener('statsended', async evt =>
      console.log(await (<any>peerConnection).getStats())
    );
    // peerConnection.addEventListener('track', evt => console.log('icegatracktheringstatechange', evt));
  }

  private onPreviewUpload(filename: string) {
    return this.apiService
      .get(`/api/static/${filename}`, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        switchMap((response: any) => fromPromise(createImageBitmap(response)))
      )
      .subscribe(bitmap => {
        console.log(
          `Got screenshot width "${bitmap.width}" height "${bitmap.height}"`
        );
        this.downloadedScreenshot$.next(bitmap);
      });
  }

  /**
   * fill of white
   */
  clearLocal() {
    const ctx = this.localCanvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, this.localCanvas.width, this.localCanvas.height);
  }
}
