/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApiMediaBody { 
    file?: Blob;
    type?: ApiMediaBody.TypeEnum;
}
export namespace ApiMediaBody {
    export type TypeEnum = 'action' | 'step';
    export const TypeEnum = {
        Action: 'action' as TypeEnum,
        Step: 'step' as TypeEnum
    };
}