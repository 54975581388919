import { AppConfig } from "@itorum/models";

export interface ReportFileModel {
  create_date: string;
  file_name: string;
  file_path: string;
  task_template_id: number;
  type: string;
  size: string;
}

// tslint:disable-next-line:no-empty-interface
export type FileReportResponse = ReportFileModel
export class FileReport implements ReportFileModel {
  public create_date: string;
  public file_name: string;
  public file_path: string;
  task_template_id: number;
  public type: string;
  public size: string;

  constructor(data: FileReportResponse, appConfig: AppConfig) {
    this.create_date = data.create_date || new Date().toISOString();
    this.file_name = data.file_name;
    this.file_path = appConfig.urlStaticPrefix + data.file_path;
    this.task_template_id = data.task_template_id;
    this.type = data.file_name
      .split('.')
      .pop()
      .toLowerCase();
  }
}
