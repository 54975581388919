import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class SideModalService {
  showSideModalSubject = new Subject();
  hideSideModalSubject = new Subject();
  _isShowCloseButton = new BehaviorSubject<boolean>(true);
  zeroMargin = false;
  _isShow = new BehaviorSubject<{ isShow: boolean, contentId?: string }>({isShow: false}); // only for subscription

  public show(modalContent, widthInPx?: number, contentId?: string) {
    if(widthInPx && contentId) {
      this.showSideModalSubject.next({ modalContent, widthInPx, contentId });
    } else if (widthInPx) {
      this.showSideModalSubject.next({ modalContent, widthInPx });
    } else if(contentId) {
      this.showSideModalSubject.next({ modalContent, contentId });
    } else {
      this.showSideModalSubject.next(modalContent);
    }
  }

  public hide(isDestroy: boolean = false) {
    this.hideSideModalSubject.next(isDestroy);
  }

  isShowCloseButton(): Observable<boolean> {
    return this._isShowCloseButton.asObservable();
  }

  showCloseButton() {
    this._isShowCloseButton.next(true);
  }

  hideCloseButton() {
    this._isShowCloseButton.next(false);
  }

  disableMargin() {
    this.zeroMargin = true;
  }

  enableMargin() {
    this.zeroMargin = false;
  }
}
