import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GalleryService {
  showGallerySubject = new Subject();

  constructor() {}

  public showGallery(galleryName) {
    this.showGallerySubject.next(galleryName);
  }

  public getMedia(currentId, array, direction) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]['filename'] === currentId) {
        if (direction === 'next') {
          return i + 2 > array.length ? array[0] : array[i + 1];
        } else if (direction === 'prev') {
          return i ? array[i - 1] : array[array.length - 1];
        }
      }
    }
  }
}
