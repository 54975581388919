/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ActionIdBody { 
    code?: string;
    name?: string;
    objectTypeId?: number;
    description?: string;
    isArchived?: boolean;
    status?: ActionIdBody.StatusEnum;
}
export namespace ActionIdBody {
    export type StatusEnum = 'formalized';
    export const StatusEnum = {
        Formalized: 'formalized' as StatusEnum
    };
}