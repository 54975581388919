import { Directive, ViewContainerRef, ElementRef } from '@angular/core';
import { RowOutlet } from '../table.interfaces';

/**
 * @deprecated
 */
// tslint:disable-next-line:directive-selector
@Directive({ selector: '[headerRowOutlet]' })
export class HeaderRowOutletDirective implements RowOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef
  ) {}
}
