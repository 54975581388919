<div class="itr-header-cell-wrap">
  <div class="itr-header-cell-wrap-filter">
    <div class="itr-header-cell-filter-left">
      <div *ngIf="type === 'text'" class="itr-header-cell-wrap-title">
        {{ title | translate }}
      </div>
      <a
        class="hasForm"
        href="javascript:void(0)"
        *ngIf="type !== 'text'"
        (click)="showFilter = !showFilter"
      >
        <span style="display: inline-block;" class="itr-header-cell-wrap-title">
          {{ title | translate }}
        </span>
      </a>
    </div>
    <div class="itr-header-cell-filter-right">
      <div
        class="itr-header-cell-wrap-icon"
        *ngIf="type !== 'text'"
        [ngClass]="{ 'itr-header-cell-wrap-icon__active': isShowCountSelected }"
        (click)="showFilter = !showFilter"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          style="enable-background:new 0 0 30 30;"
          xml:space="preserve"
        >
          <style type="text/css">
            .st0 {
              fill: #231f20;
            }
          </style>
          <polygon class="st0" points="20,9 8,9 13,14 13,20 15,18.6 15,14 " />
        </svg>
        <span *ngIf="isShowCountSelected">{{ countSelected }}</span>
      </div>
      <itorum-table-filter
        *ngIf="type !== 'text'"
        [visibility]="showFilter"
        [list]="obsData"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [enableSearch]="search"
        [searchInit]="searchInit"
        [right]="right"
        [variants]="type == 'filter'"
        [field]="field"
        [static]="static"
        (selected)="updateFilter($event)"
        (reset)="updateFilter($event)"
        (search)="updateSearch($event)"
      >
      </itorum-table-filter>
      <span
        *ngIf="visibleSortField"
        class="rotate-filter"
        [ngClass]="{ rotate: sortType === 'desc' }"
        (click)="sort()"
      >
        <svg
          class="itr-header-cell-rotate-icon"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 15 15"
          style="enable-background:new 0 0 15 15;"
          xml:space="preserve"
        >
          <polygon
            points="13,7.6 12.3,6.9 8,11.2 8,2 7,2 7,11.2 2.7,6.9 2,7.6 7.3,12.9 7.7,12.9 "
          />
        </svg>
      </span>
      <div class="itr-header-cell-resize" #resize></div>
    </div>
  </div>
</div>
