/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ApiactiontemplatefindPaginationOrderBy { 
    fieldName?: ApiactiontemplatefindPaginationOrderBy.FieldNameEnum;
    sortOrder?: ApiactiontemplatefindPaginationOrderBy.SortOrderEnum;
}
export namespace ApiactiontemplatefindPaginationOrderBy {
    export type FieldNameEnum = 'id' | 'created_at';
    export const FieldNameEnum = {
        Id: 'id' as FieldNameEnum,
        CreatedAt: 'created_at' as FieldNameEnum
    };
    export type SortOrderEnum = 'ASC' | 'DESC';
    export const SortOrderEnum = {
        ASC: 'ASC' as SortOrderEnum,
        DESC: 'DESC' as SortOrderEnum
    };
}