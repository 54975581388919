import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceMonitorComponent } from "./components/device-monitor/device-monitor.component";
import { ComponentProviderOptions, RxZuModule } from "@rxzu/angular";
import { MonPointerComponent } from './components/device-monitor/components/mon-pointer/mon-pointer.component';
import { MonDisplayComponent } from './components/device-monitor/components/mon-display/mon-display.component';
import { MonPortComponent } from './components/device-monitor/components/mon-port/mon-port.component';
import { MonLabelComponent } from './components/device-monitor/components/mon-label/mon-label.component';
import { MonLinkComponent } from './components/device-monitor/components/mon-link/mon-link.component';
import { HeaderPanelComponent } from './components/device-monitor/components/header-panel/header-panel.component';
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { SourcesSearchComponent } from './components/device-monitor/components/mon-display/components/sources-search/sources-search.component';
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";


const DEFAULTS: ComponentProviderOptions[] = [
  {
    type: 'node',
    component: MonPointerComponent,
    namespace: 'monitorPointer',
  },
  {
    type: 'node',
    component: MonDisplayComponent,
    namespace: 'monitorDisplay',
  },
  {
    type: 'port',
    component: MonPortComponent,
    namespace: 'monitorHelper',
  },
  {
    type: 'label',
    component: MonLabelComponent,
    namespace: 'monitorHelper',
  },
  {
    type: 'link',
    component: MonLinkComponent,
    namespace: 'monitorHelper',
  },
]




@NgModule({
  declarations: [
    DeviceMonitorComponent,
    MonPointerComponent,
    MonDisplayComponent,
    MonPortComponent,
    MonLabelComponent,
    MonLinkComponent,
    HeaderPanelComponent,
    SourcesSearchComponent
  ],
  imports: [
    CommonModule,
    RxZuModule.withComponents(DEFAULTS),
    TranslateModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports: [
    DeviceMonitorComponent
  ]
})
export class DeviceMonitorModule { }
