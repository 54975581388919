export interface ICallCandidate {
  fullName: string;
  id: number;
  organization: {id: number, name: string},
  roles: {id: number, name: string, code: string}[],
  status: CallCandidateStatus
}

export enum CallCandidateStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}
