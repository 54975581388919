import { Injectable } from '@angular/core';

@Injectable()
export class ItrHeaderService {
  public dragStartField: string;
  public dragStartElement: any;

  public dragEndField: string;
  public dragEndElement: any;

  constructor() {}

  public setDragStart(field: string, element: any) {
    this.dragStartField = field;
    this.dragStartElement = element;
  }
  public setDragEnd(field: string, element: any) {
    this.dragEndField = field;
    this.dragEndElement = element;
  }
  public getDragStart() {
    return {
      field: this.dragStartField,
      element: this.dragStartElement
    };
  }
  public getDragEnd() {
    return {
      field: this.dragEndField,
      element: this.dragEndElement
    };
  }
  public clear() {
    this.dragStartField = null;
    this.dragStartElement = null;
    this.dragEndField = null;
    this.dragEndElement = null;
  }
}
