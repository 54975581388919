import { IterableChanges, IterableDiffer, IterableDiffers, OnChanges, SimpleChanges, TemplateRef, Directive } from '@angular/core';
import { ItrColumnDefDirective } from '../directives/itr-column-def.directive';
import { ERowType } from '../table.interfaces';

export const ITR_ROW_TEMPLATE = `<ng-container itrCellOutlet></ng-container>`;

@Directive()
export abstract class BaseRowDefDirective implements OnChanges {
  columns: Iterable<string>;
  type: ERowType;

  protected _columnsDiffer: IterableDiffer<any>;

  constructor(
    public template: TemplateRef<any>,
    protected _differs: IterableDiffers
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this._columnsDiffer) {
      const columns =
        (changes['columns'] && changes['columns'].currentValue) || [];
      this._columnsDiffer = this._differs.find(columns).create();
      this._columnsDiffer.diff(columns);
    }
  }

  getColumnsDiff(): IterableChanges<any> | null {
    return this._columnsDiffer.diff(this.columns);
  }

  extractCellTemplate(column: ItrColumnDefDirective): TemplateRef<any> {
    if (this.type === ERowType.HEADER) {
      return column.headerCell.template;
    } else if (this.type === ERowType.FOOTER) {
      return column.footerCell.template;
    } else {
      return column.cell.template;
    }
  }
}
