import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EmbeddedViewRef,
  Input,
  isDevMode,
  IterableChangeRecord,
  IterableDiffer,
  IterableDiffers,
  OnInit,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  HostBinding, OnChanges, SimpleChanges, Renderer2, OnDestroy, AfterViewInit
} from '@angular/core';
import {
  getTableDuplicateColumnNameError,
  getTableMissingMatchingRowDefError,
  getTableMissingRowDefsError,
  getTableUnknownColumnError,
  getTableUnknownDataSourceError
} from '../../table-errors';
import { Subscription, Observable, of as observableOf } from 'rxjs';
import {
  mergeQueryListAndSet,
  coerceBooleanProperty,
  changeSortField,
  propertyByString,
  changeData,
  changeFieldFilter,
  initConfig,
  columnDefCells,
  _render,
  sort,
  changeSearch,
  getNativeScrollbarWidth,
  changeCellWidth,
  resetCellWidth,
  headerCellDragEnd,
  ITEM_HEIGHT_LIST,
  ITEM_HEIGHT_BIG,
  ITEM_HEIGHT_LITTLE,
  ITEM_HEIGHT_MIDDLE
} from '../../table-utils';
import { ItrVirtualScrollComponent } from '../itr-virtual-scroll/itr-virtual-scroll.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../../../../../../../libs/models/src/lib/models/user.model';
import { setTimeout } from 'core-js/web/timers';
import {
  // TakeUntilDestroy,
  untilDestroyed
} from 'ngx-take-until-destroy';
import {
  RowContext,
  RowOutlet,
  RenderRow,
  IchangeFieldFilterParams,
  ItrTableConfig,
  ItrCellSort,
  ItrCellConfig
} from '../../table.interfaces';
import { ItrHeaderRowDefDirective } from '../../directives/itr-header-row.directive';
import { RowOutletDirective } from '../../directives/row-outlet.directive';
import { HeaderRowOutletDirective } from '../../directives/header-row-outlet.directive';
import { FooterRowOutletDirective } from '../../directives/footer-row-outlet.directive';
import { ItrColumnDefDirective } from '../../directives/itr-column-def.directive';
import { ItrRowDefDirective } from '../../directives/itr-row-def.directive';
import { ItrFooterRowDefDirective } from '../../directives/itr-footer-row-def.directive';
import { UserService } from '@shared/services/user.service';
import { ItrCellOutletDirective } from '../../directives/itr-cell-outlet.directive';
import { BaseRowDefDirective } from '../../classes/base-row-def.class';

abstract class RowViewRef<T> extends EmbeddedViewRef<RowContext<T>> {}

/**
 * @deprecated
 */
@Component({
  selector: 'itr-table, table[itr-table]',
  styleUrls: ['./itr-table.component.scss'],
  templateUrl: './itr-table.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItrTableComponent<T>
  implements AfterContentChecked, OnDestroy, OnInit, OnChanges, AfterViewInit {

  /**
   * Присвоить самому себе сласс itr-table
   * (<itr-table class="itr-table" ... )
   */
  @HostBinding('class') get className() {
    return 'itr-table';
  }

  /**
   * Сообщить об изменении конфигурации ячеек
   */
  @Output() init = new EventEmitter<ItrTableConfig>();

  /**
   * Отправить изменённые данные
   */
  @Output() initData = new EventEmitter<any[]>();

  /**
   * Отправить отфильтрованные данные (по полю ввода @Input() filter)
   */
  @Output() filterUpdate = new EventEmitter<any[]>();
  @Output() render = new EventEmitter<any>();
  @Output() sortChange = new EventEmitter<ItrCellSort>();
  @Output() configChange = new EventEmitter<ItrTableConfig>();
  @Output() updateData = new EventEmitter();
  @Output() nextPage = new EventEmitter<number>();
  @Output() pageIndex = new EventEmitter<number>();
  @Output() updateSearch = new EventEmitter();

  @Input() dontSort = false;
  @Input() dontSearch = false;
  @Input() dontFilter = false;
  @Input() pageSize = 20;

  // @Input() setVirtualScrollWidth;

  @Input() dateRangeFilter;
  @Input() exportXLS: Function;

  /**
   * Данные для отображения
   */
  @Input()
  get dataSource(): Observable<T[]> | T[] {
    return this._dataSource;
  }
  set dataSource(dataSource: Observable<T[]> | T[]) {
    if (this._dataSource !== dataSource) {
      this._switchDataSource(dataSource);
      // this._dataSource = dataSource;
    }
  }

  /**
   * Добавить данных для отображения к уже имеющимся
   * @param {any[]} data
   */
  @Input()
  set additionData(data: any[]) {
    const scrollTop = this.scrollTop;
    if (data.length) {
      this.dataSource = [...(this.dataSource as any[]), data];
      // this._observeRenderChanges();
      // debugger;
      setTimeout(() => this.scrollTo(scrollTop));
    }
  }

  /**
   * Строка фильтрации данных в колонке,
   * фильтрует в строгом соответствии с отображаемой строкой (Label)
   * @param {string} filter
   */
  @Input()
  set filter(filter: string) {
    this._filterField(false);
    this._filter(filter, false, false);
    this._sort(changeSortField.value);

    if (this._$tableCellConfig) {
      this._$tableCellConfig.filter = filter || '';
      this._tableCellConfig = this._$tableCellConfig;
      setTimeout(() => this.filterUpdate.emit(this._fullData));
    }
  }

  @Input()
  get trackBy(): TrackByFunction<T> {
    return this._trackByFn;
  }
  set trackBy(fn: TrackByFunction<T>) {
    try {
      if (isDevMode() && (fn != null && typeof fn !== 'function')) {
        throw new TypeError(
          `trackBy must be a function, but received ${JSON.stringify(fn)}.`
        );
      }
      this._trackByFn = fn;
    } catch (e) {
      console.warn(e);
    }
  }
  private _trackByFn: TrackByFunction<T>;

  @Input()
  get multiTemplateDataRows(): boolean {
    return this._multiTemplateDataRows;
  }
  set multiTemplateDataRows(v: boolean) {
    this._multiTemplateDataRows = coerceBooleanProperty(v);
    if (this._rowOutlet.viewContainer.length) {
      this._forceRenderDataRows();
    }
  }
  _multiTemplateDataRows = false;

  @Input() public key;

  @ViewChild('virtual_scroll_wrapper') public wrapperElementRef: ElementRef;

  @ViewChild(ItrVirtualScrollComponent)
  _virtualScroll: ItrVirtualScrollComponent;
  @ViewChild(RowOutletDirective) _rowOutlet: RowOutletDirective;
  @ViewChild(HeaderRowOutletDirective)
  _headerRowOutlet: HeaderRowOutletDirective;
  @ViewChild(FooterRowOutletDirective)
  _footerRowOutlet: FooterRowOutletDirective;
  @ViewChild('settingsModal') _settingsModalTemplate: TemplateRef<any>;

  @ContentChildren(ItrColumnDefDirective) _contentColumnDefs: QueryList<
    ItrColumnDefDirective
  >;
  @ContentChildren(ItrRowDefDirective) _contentRowDefs: QueryList<
    ItrRowDefDirective<T>
  >;
  @ContentChildren(ItrHeaderRowDefDirective) _contentHeaderRowDefs: QueryList<
    ItrHeaderRowDefDirective
  >;
  @ContentChildren(ItrFooterRowDefDirective) _contentFooterRowDefs: QueryList<
    ItrFooterRowDefDirective
  >;

  private set _tableCellConfig(value: ItrTableConfig) {
    if (!this._currentUser || !this._currentUser.user_id) {
      return;
    }
    const key = `itr-table-${this._currentUser.user_id}-${this.key}`;
    localStorage.setItem(key, JSON.stringify(value));
  }


  private get _tableCellConfig(): ItrTableConfig | null {
    const empty = { cells: [], filter: null, sort: null, itemHeight: 28 };
    if (!this._currentUser || !this._currentUser.user_id) {
      return empty;
    }
    const key = `itr-table-${this._currentUser.user_id}-${this.key}`;
    try {
      const data = localStorage.getItem(key);
      if (!data) {
        return empty;
      }
      return JSON.parse(data) as ItrTableConfig;
    } catch (e) {
      localStorage.removeItem(key);
      return empty;
    }
  }

  public scrollTop: number;
  private _$tableCellConfig: ItrTableConfig;
  private _dataSource: Observable<T[]> | T[];
  private _dataDiffer: IterableDiffer<RenderRow<T>>;
  private _customHeaderRowDefs = new Set<ItrHeaderRowDefDirective>();
  private _headerRowDefs: ItrHeaderRowDefDirective[];
  private _rowDefs: ItrRowDefDirective<T>[];
  private _customRowDefs = new Set<ItrRowDefDirective<T>>();
  private _footerRowDefs: ItrFooterRowDefDirective[];
  private _customFooterRowDefs = new Set<ItrFooterRowDefDirective>();
  private _defaultRowDef: ItrRowDefDirective<T> | null;
  private _columnDefsByName = new Map<string, ItrColumnDefDirective>();
  private _customColumnDefs = new Set<ItrColumnDefDirective>();
  private _renderRows: RenderRow<T>[];
  private _cachedRenderRowsMap = new Map<
    T,
    WeakMap<ItrRowDefDirective<T>, RenderRow<T>[]>
  >();

  /**
   * Данные для отображения полученные из subscribe при @Input()
   */
  public _fullData: T[];

  /**
   * Кэш для данных для отображения
   */
  public _cashedfullData: T[];

  /**
   * Вспомогательный массив
   */
  private _data: T[];
  private _headerRowDefChanged = true;
  private _footerRowDefChanged = true;
  private _renderChangeSubscription: Subscription | null;
  private _settingsModalRef: BsModalRef;
  public _itemHeight = 28;
  private _currentUser: User = null;
  private _firstRender = true;

  constructor(
    protected readonly _differs: IterableDiffers,
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    public _elementRef: ElementRef,
    private userService: UserService,
    private modalService: BsModalService,
    private readonly renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Получить сохранённую ранее либо дефолтную конфигурацию таблицы
    this._$tableCellConfig = this._tableCellConfig;

    this._dataDiffer = this._differs
      .find([])
      .create((_i: number, dataRow: RenderRow<T>) => {
        return this.trackBy
          ? this.trackBy(dataRow.dataIndex, dataRow.data)
          : dataRow;
      });

    changeSortField.pipe(untilDestroyed(this)).subscribe(data => {
      this._sort.call(this, data);
      setTimeout(() => {
        if (this._virtualScroll.element.nativeElement.scrollTo) {
          this._virtualScroll.element.nativeElement.scrollTo(0, 0);
        } else {
          // console.log('scrollTo filterd');
        }
      }, 0);
    });

    changeFieldFilter.pipe(untilDestroyed(this)).subscribe(data => {
      this._changeFieldFilter.call(this, data);
      setTimeout(() => {
        if (this._virtualScroll.element.nativeElement.scrollTo) {
          this._virtualScroll.element.nativeElement.scrollTo(0, 0);
        } else {
          // console.log('scrollTo filterd');
        }
      }, 0);
    });

    changeSearch.pipe(untilDestroyed(this)).subscribe(v => {
      this.updateSearch.emit(v);
    });
    changeCellWidth.pipe(untilDestroyed(this)).subscribe(change => {
      this._changeCellWidth(change);
    });
    resetCellWidth.pipe(untilDestroyed(this)).subscribe(data => {
      this._resetCellWidth(data);
    });
    headerCellDragEnd.pipe(untilDestroyed(this)).subscribe(data => {
      this._onDrag(data);
    });
    setTimeout(() => this._fitVirtualScrollToScreen());

    this.userService.client$
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this._currentUser = user;
      });

    setTimeout(() => {
      // const wrapper_width = `${Math.round(this.setVirtualScrollWidth)}px`;
      // console.log('wrapper_width', wrapper_width);
      // this.renderer.setStyle(this.wrapperElementRef.nativeElement, 'width', wrapper_width);
    });
  }
  ngAfterContentChecked() {
    // this._isAfterContentChecked = true;
    if (
      this._currentUser &&
      this._currentUser.user_id &&
      !this._renderChangeSubscription
    ) {
      this._contentUpdate();
    }
    // this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
  // this.cd.detectChanges();
    // if (this.setVirtualScrollWidth) {
    //   // const wrapper_width = `${Math.round(this.setVirtualScrollWidth)}px`;
    //   // console.log('wrapper_width', wrapper_width);
    //   // this.renderer.setStyle(this.wrapperElementRef.nativeElement, 'width', wrapper_width);
    // }
    // this.renderer.setStyle(this.wrapperElementRef.nativeElement, 'width', )
  }

  ngAfterViewInit(): void {
    // this.setVirtualScrollWidth
    // this.cd.detectChanges();
  }

  private _onDrag(data: { start: string; end: string }) {
    const { start, end } = data;
    if (start === end || !start || !end) {
      return;
    }
    const startCell = this._getCellConfig(start);
    const endCell = this._getCellConfig(end);
    this._$tableCellConfig.cells.splice(
      this._$tableCellConfig.cells.indexOf(startCell),
      1
    );
    this._$tableCellConfig.cells.splice(
      this._$tableCellConfig.cells.indexOf(endCell),
      0,
      startCell
    );
    this._tableCellConfig = this._$tableCellConfig;
    this.refresh();
  }

  private _resetCellWidth(data: { field: string }) {
    this._setValueIntoCell(data.field, 'width', null);
  }

  private _changeCellWidth(change: { field: string; value: number }) {
    this._setValueIntoCell(change.field, 'width', change.value);
  }

  /**
   *
   * @param field
   * @param label
   * @param value
   * @private
   */
  private _setValueIntoCell(field, label, value) {
    if (!field) {
      return;
    }
    const cell = this._getCellConfig(field);
    if (!cell) {
      return;
    }
    cell[label] = value;
    this._tableCellConfig = this._$tableCellConfig;
    this.refresh();
  }
  private _contentUpdate() {
    this._$tableCellConfig = this._tableCellConfig;

    this._cacheRowDefs();
    this._cacheColumnDefs();

    if (
      !this._headerRowDefs.length &&
      !this._footerRowDefs.length &&
      !this._rowDefs.length
    ) {
      throw getTableMissingRowDefsError();
    }

    this._renderUpdatedColumns();

    if (this._headerRowDefChanged) {
      this._forceRenderHeaderRows();
      this._headerRowDefChanged = false;
    }
    if (this._footerRowDefChanged) {
      this._forceRenderFooterRows();
      this._footerRowDefChanged = false;
    }

    if (
      this.dataSource &&
      this._rowDefs.length > 0 &&
      !this._renderChangeSubscription
    ) {
      this._observeRenderChanges();
    }
  }

  private _changeFieldFilter(data: IchangeFieldFilterParams) {
    if (!data || !this._fullData) {
      return;
    }
    const cell = this._getCellConfig(data.field);
    cell.field.filter = [];
    cell.field.filter = cell.field.filter.concat(...data.arr.values);
    this._tableCellConfig = this._$tableCellConfig;
    this._filterField();
    this.configChange.emit(this._$tableCellConfig);
  }

  private _filterField(condition: boolean = true) {
    if (!this._cashedfullData) {
      return;
    }

    let fullData = this._cashedfullData.slice(0);

    if (!this.dontFilter) {
      this._tableCellConfig.cells.forEach(cell => {
        if (cell.field.filter && cell.field.filter.length) {
          fullData = fullData.filter(item => {
            const value = propertyByString(item, cell.field.value);
            if (Array.isArray(value)) {
              const findedValue = value.find(childItem => {
                if (childItem.id) {
                  childItem = childItem.id;
                }
                return cell.field.filter.indexOf(childItem) !== -1;
              });

              return !!findedValue;
            }
            if (cell.field.filter.indexOf(value) !== -1) {
              return true;
            }
            return false;
          });
        }
      });
    }

    this._fullData = fullData;

    if (condition && this._$tableCellConfig.filter) {
      this._filter(this._$tableCellConfig.filter, false, false);
    }
    if (condition && this._$tableCellConfig.sort) {
      this._sort(this._$tableCellConfig.sort);
    }

    if (this._virtualScroll) {
      this._virtualScroll.refresh();
    }
    // this.initData.emit(this._fullData);
  }

  public get fullData() {
    return this._fullData;
  }

  private _fitVirtualScrollToScreen() {
    const top = this._virtualScroll.element.nativeElement.getBoundingClientRect()
      .top;
    let bottom = 0;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    let element = null;
    let nextElement = null;
    const body = document.querySelector('body');

    while (true) {
      element = !element
        ? this._elementRef.nativeElement.parentElement
        : element.parentElement;
      nextElement = element.nextElementSibling;

      if (element && nextElement) {
        break;
      }

      if (element === body) {
        break;
      }
    }

    if (element === body) {
      bottom = 0;
    } else {
      bottom = document.body.clientHeight - nextElement.offsetTop;
    }

    this._virtualScroll.element.nativeElement.setAttribute(
      'style',
      `height: ${screenHeight - top - bottom}px; overflow-y: auto;`
    );
  }

  private _sort(resp: ItrCellSort) {
    this.sortChange.emit(resp);
    let revert = 1;
    if (!resp || !this._fullData) {
      return;
    }

    if (resp.sortType === 'desc') {
      revert = -1;
    }

    if (!this.dontSort) {
      this._fullData = sort(this._fullData, resp.field, revert);
    }

    this._$tableCellConfig.sort = resp;
    this._tableCellConfig = this._$tableCellConfig;

    this._virtualScroll?.refresh();
  }

  /**
   * Преднастройка фильтра
   * @param query
   * @param condition
   * @param fullData
   * @private
   */
  private _filter(
    query: string,
    condition: boolean = true,
    fullData: boolean = true
  ) {
    if (!query) {
      if (condition && this._cashedfullData) {
        this._fullData = this._cashedfullData.slice();
      }
      return;
    }

    const keys = [];

    this._$tableCellConfig.cells.forEach(cell => {
      if (cell.isVisible) {
        keys.push(cell.field.value);
      }
    });

    const data = fullData ? this._cashedfullData : this._fullData;
    if (!data) { return; }
    if (!this.dontSearch) {
      this._fullData = data.filter((entity: any) => {
        for (let i = 0; i < keys.length; i++) {
          const value = propertyByString(entity, keys[i]);
          if (
            value &&
            String(value)
              .toLocaleLowerCase()
              .match(query.toLocaleLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }
  }

  public _updateItemHeight(height: string) {
    this._itemHeight = +height;
    console.log('this._$tableCellConfig', this._$tableCellConfig);
    if(!this._$tableCellConfig) {
      console.warn('_$tableCellConfig not set');
      this._$tableCellConfig = this._tableCellConfig;
      console.log('set to this this._$tableCellConfig', this._$tableCellConfig);
    }
    this._$tableCellConfig.itemHeight = this._itemHeight;
    this._tableCellConfig = this._$tableCellConfig;
    // Array.prototype.slice.call(this._virtualScroll.contentElementRef.nativeElement.children).forEach(element => {
    //   element.setAttribute('style', `min-height: ${this._itemHeight}px`);
    //   // Array.prototype.slice.call(element.querySelectorAll('itr-cell > div')).forEach((childElement) => {
    //   //   childElement.setAttribute('style', `height: ${this._itemHeight}px`);
    //   // });
    // });

    this.refresh();

    // setTimeout(() => {
    //   this._virtualScroll.refresh();
    // this._virtualScroll.element.nativeElement.scrollTo(0, 0);
    // }, 0);
  }

  public scrollTo(scroll: number) {
    // console.log('(this._virtualScroll.element.nativeElement as HTMLElement).scrollTo', (this._virtualScroll.element.nativeElement as HTMLElement).scrollTo);
    if ((this._virtualScroll.element.nativeElement as HTMLElement).scrollTo) {
      setTimeout(() =>
        (this._virtualScroll.element.nativeElement as HTMLElement).scrollTo(
          0,
          scroll
        )
      );
    } else {
      // console.log('scrollTo filtered');
    }
  }

  private _switchDataSource(dataSource: Observable<T[]> | T[]) {
    this._data = [];
    if (this._renderChangeSubscription) {
      this._renderChangeSubscription.unsubscribe();
      this._renderChangeSubscription = null;
    }
    if (!dataSource) {
      if (this._dataDiffer) {
        this._dataDiffer.diff([]);
      }
      this._rowOutlet.viewContainer.clear();
    }
    this._dataSource = dataSource;
  }

  private _cacheRowDefs() {
    this._headerRowDefs = mergeQueryListAndSet(
      this._contentHeaderRowDefs,
      this._customHeaderRowDefs
    );
    this._rowDefs = mergeQueryListAndSet(
      this._contentRowDefs,
      this._customRowDefs
    );
    this._footerRowDefs = mergeQueryListAndSet(
      this._contentFooterRowDefs,
      this._customFooterRowDefs
    );

    const defaultRowDefs = this._rowDefs.filter(def => !def.when);
    this._defaultRowDef = defaultRowDefs[0];
  }

  private _cacheColumnDefs() {
    this._columnDefsByName.clear();
    const columnDefs = mergeQueryListAndSet(
      this._contentColumnDefs,
      this._customColumnDefs
    );
    columnDefs.forEach(columnDef => {
      if (this._columnDefsByName.has(columnDef.name)) {
        throw getTableDuplicateColumnNameError(columnDef.name);
      }
      this._columnDefsByName.set(columnDef.name, columnDef);
    });
  }

  private _renderUpdatedColumns() {
    const columnsDiffReducer = (acc: boolean, def: BaseRowDefDirective) => {
      return acc || !!def.getColumnsDiff();
    };
    if (this._rowDefs.reduce(columnsDiffReducer, false)) {
      this._forceRenderDataRows();
    }
    if (this._headerRowDefs.reduce(columnsDiffReducer, false)) {
      this._forceRenderHeaderRows();
    }

    if (this._footerRowDefs.reduce(columnsDiffReducer, false)) {
      this._forceRenderFooterRows();
    }
  }

  renderRows() {
    this._renderRows = this._getAllRenderRows();
    const changes = this._dataDiffer.diff(this._renderRows);
    if (!changes) {
      return;
    }

    const viewContainer = this._rowOutlet.viewContainer;
    changes.forEachOperation(
      (
        record: IterableChangeRecord<RenderRow<T>>,
        prevIndex: number,
        currentIndex: number
      ) => {
        if (record.previousIndex == null) {
          this._insertRow(record.item, currentIndex);
        } else if (currentIndex == null) {
          viewContainer.remove(prevIndex);
        } else {
          const view = <RowViewRef<T>>viewContainer.get(prevIndex);
          viewContainer.move(view, currentIndex);
        }
      }
    );
    this._updateRowIndexContext();
    changes.forEachIdentityChange(
      (record: IterableChangeRecord<RenderRow<T>>) => {
        const rowView = <RowViewRef<T>>viewContainer.get(record.currentIndex);
        rowView.context.$implicit = record.item.data;
      }
    );
    setTimeout(() => {
      this.render.emit(this._firstRender);
      _render.emit(this._firstRender);
      this._firstRender = false;
    }, 0);
  }

  private _getAllRenderRows(): RenderRow<T>[] {
    const renderRows: RenderRow<T>[] = [];
    const prevCachedRenderRows = this._cachedRenderRowsMap;
    this._cachedRenderRowsMap = new Map();
    for (let i = 0; i < this._data.length; i++) {
      const data = this._data[i];
      const renderRowsForData = this._getRenderRowsForData(
        data,
        i,
        prevCachedRenderRows.get(data)
      );

      if (!this._cachedRenderRowsMap.has(data)) {
        this._cachedRenderRowsMap.set(data, new WeakMap());
      }

      for (let j = 0; j < renderRowsForData.length; j++) {
        const renderRow = renderRowsForData[j];

        const cache = this._cachedRenderRowsMap.get(renderRow.data);
        if (cache.has(renderRow.rowDef)) {
          cache.get(renderRow.rowDef).push(renderRow);
        } else {
          cache.set(renderRow.rowDef, [renderRow]);
        }
        renderRows.push(renderRow);
      }
    }
    return renderRows;
  }

  private _getRenderRowsForData(
    data: T,
    dataIndex: number,
    cache?: WeakMap<ItrRowDefDirective<T>, RenderRow<T>[]>
  ): RenderRow<T>[] {
    const rowDefs = this._getRowDefs(data, dataIndex);

    return rowDefs.map(rowDef => {
      const cachedRenderRows =
        cache && cache.has(rowDef) ? cache.get(rowDef) : [];
      if (cachedRenderRows.length) {
        const dataRow = cachedRenderRows.shift();
        dataRow.dataIndex = dataIndex;
        return dataRow;
      } else {
        return { data, rowDef, dataIndex };
      }
    });
  }

  private _getRowDefs(data: T, dataIndex: number): ItrRowDefDirective<T>[] {
    if (+this._rowDefs.length === 1) {
      return [this._rowDefs[0]];
    }

    let rowDefs: ItrRowDefDirective<T>[] = [];
    if (this.multiTemplateDataRows) {
      rowDefs = this._rowDefs.filter(
        def => !def.when || def.when(dataIndex, data)
      );
    } else {
      const rowDef =
        this._rowDefs.find(def => def.when && def.when(dataIndex, data)) ||
        this._defaultRowDef;
      if (rowDef) {
        // rowDefs.push(rowDef);
      }
    }

    if (!rowDefs.length) {
      throw getTableMissingMatchingRowDefError(data);
    }

    return rowDefs;
  }

  private _insertRow(renderRow: RenderRow<T>, renderIndex: number) {
    const rowDef = renderRow.rowDef;
    const context: RowContext<T> = { $implicit: renderRow.data };
    this._renderRow(this._rowOutlet, rowDef, renderIndex, context);
  }

  private _renderRow(
    outlet: RowOutlet,
    rowDef: BaseRowDefDirective,
    index: number,
    context: RowContext<T> = {}
  ) {
    const rowView = outlet.viewContainer.createEmbeddedView(
      rowDef.template,
      context,
      index
    );

    if (outlet instanceof RowOutletDirective) {
      rowView.rootNodes[0].setAttribute(
        'style',
        `max-height: ${this._itemHeight}px`
      );
    }

    const cellTemplatesconfig = this._getCellTemplates(rowDef);
    const templates = cellTemplatesconfig.list;
    const columns = cellTemplatesconfig.columns;
    const virtualScroll: any = document.querySelector('itr-virtual-scroll');
    const totalPadding: any = virtualScroll.children[0];
    let nativeScrollBarWidth = 0;
    if (totalPadding.offsetHeight > virtualScroll.offsetHeight) {
      nativeScrollBarWidth = getNativeScrollbarWidth();
    }
    const tableWidth =
      this._elementRef.nativeElement.parentElement.getBoundingClientRect()
        .width - nativeScrollBarWidth;
    const tableCellElementList = new Map();

    // for (const key in templates)
    templates.forEach((template, key) => {
      const cellTemplate = template.template;
      if (ItrCellOutletDirective.mostRecentCellOutlet) {
        const value = columns[key];

        if (!this._containCellConfig(value)) {
          const cellValue = columnDefCells.get(value) as {
            visible: boolean;
            label: string;
            width: number;
            breakWordSymbol: boolean;
          };
          this._addCellConfig({
            field: {
              label: cellValue.label,
              value: value,
              filter: [],
              code: '',
              index: null
            },
            width: cellValue.width || null,
            isBigFont: true,
            isVisible: cellValue.visible,
            breakWord: false,
            breakWordSymbol: cellValue.breakWordSymbol || false
          });
        }
        if (
          this._containCellConfig(value) &&
          this._getCellConfig(value).isVisible
        ) {
          const CellView: EmbeddedViewRef<
            RowContext<T>
          > = ItrCellOutletDirective.mostRecentCellOutlet._viewContainer.createEmbeddedView(
            cellTemplate,
            context
          );
          tableCellElementList.set(value, CellView.rootNodes[0]);
        }
        this._tableCellConfig.cells.forEach(item => {
          Array.prototype.slice
            .call(
              document.querySelectorAll(`.itr-column-${item.field.value}`),
              0
            )
            .forEach(element => {
              element.classList.remove('itr-cell-small');
            });
          if (!item.isBigFont) {
            Array.prototype.slice
              .call(
                document.querySelectorAll(`.itr-column-${item.field.value}`),
                0
              )
              .forEach(element => {
                element.classList.add('itr-cell-small');
              });
          }
        });
      }
    });
    const tableCells: ItrCellConfig[] = this._$tableCellConfig.cells.filter(
      item => item.isVisible
    );
    const tableCellsFixed: ItrCellConfig[] = [];
    const tableCellsOther: ItrCellConfig[] = [];
    let tableCellsOtherWidth = tableWidth;
    let tableCellsOtherOneWidth = 0;
    tableCells.forEach(item => {
      if (item.width) {
        tableCellsFixed.push(item);
      } else {
        tableCellsOther.push(item);
      }
    });
    tableCellsFixed.forEach(item => {
      const template = tableCellElementList.get(item.field.value);
      template.style.width = item.width + 'px';
      tableCellsOtherWidth = tableCellsOtherWidth - item.width;
    });
    tableCellsOtherOneWidth = tableCellsOtherWidth / tableCellsOther.length;
    tableCellsOther.forEach(item => {
      const template = tableCellElementList.get(item.field.value);
      template.style.width = tableCellsOtherOneWidth + 'px';
    });

    setTimeout(() => {
      let isBreak = false;
      if (outlet instanceof RowOutletDirective) {
        tableCells.forEach(item => {
          const template = tableCellElementList.get(item.field.value);
          template.classList.remove('itr-cell-breakWord');
          template.classList.remove('itr-cell-breakWord-symbol');
          if (item.breakWord) {
            isBreak = true;
            template.classList.add('itr-cell-breakWord');
            if (item.breakWordSymbol) {
              template.classList.add('itr-cell-breakWord-symbol');
            }
          }
        });
      }
      if (outlet instanceof RowOutletDirective) {
        const view = rowView.rootNodes[0];
        view.classList.remove('itr-row-breakWord');
        view.classList.remove(
          `itr-row-breakWord-${ITEM_HEIGHT_LIST[ITEM_HEIGHT_BIG]}`
        );
        view.classList.remove(
          `itr-row-breakWord-${ITEM_HEIGHT_LIST[ITEM_HEIGHT_MIDDLE]}`
        );
        view.classList.remove(
          `itr-row-breakWord-${ITEM_HEIGHT_LIST[ITEM_HEIGHT_LITTLE]}`
        );

        if (isBreak) {
          view.classList.add('itr-row-breakWord');
          view.classList.add(
            `itr-row-breakWord-${ITEM_HEIGHT_LIST[this._itemHeight]}`
          );
        }
      }
    });

    this._changeDetectorRef.markForCheck();
  }

  private _containCellConfig(column_name: string) {
    let mathed = false;
    this._$tableCellConfig.cells.forEach(item => {
      if (item.field.value === column_name) {
        mathed = true;
      }
    });
    return mathed;
  }

  /**
   *
   * @param column_name
   * @private
   */
  private _getCellConfig(column_name: string) {
    return this._$tableCellConfig.cells.find(item => {
      return item.field.value === column_name;
    });
  }

  private _addCellConfig(cell: ItrCellConfig) {
    this._$tableCellConfig.cells.push(cell);
    this._tableCellConfig = this._$tableCellConfig;
  }

  public get _cellsConfig(): ItrCellConfig[] {
    return this._$tableCellConfig.cells;
  }

  private _getCellTemplates(rowDef: BaseRowDefDirective): any {
    if (!rowDef || !rowDef.columns) {
      return [];
    }
    const cells = this._$tableCellConfig.cells;
    const columns = cells.length
      ? cells.map(item => item.field.value)
      : rowDef.columns;
    const list = Array.from(columns, columnId => {
      const column = this._columnDefsByName.get(columnId);

      if (!column) {
        throw getTableUnknownColumnError(columnId);
      }

      return {
        column,
        template: rowDef.extractCellTemplate(column)
      };
    });
    return {
      list,
      columns
    };
  }

  private _updateRowIndexContext() {
    const viewContainer = this._rowOutlet.viewContainer;
    for (
      let renderIndex = 0, count = viewContainer.length;
      renderIndex < count;
      renderIndex++
    ) {
      const viewRef = viewContainer.get(renderIndex) as RowViewRef<T>;
      const context = viewRef.context as RowContext<T>;
      context.count = count;
      context.first = renderIndex === 0;
      context.last = renderIndex === count - 1;
      context.even = renderIndex % 2 === 0;
      context.odd = !context.even;

      if (this.multiTemplateDataRows) {
        context.dataIndex = this._renderRows[renderIndex].dataIndex;
        context.renderIndex = renderIndex;
      } else {
        context.index = this._renderRows[renderIndex].dataIndex;
      }
    }
  }

  private _forceRenderDataRows() {
    this._dataDiffer.diff([]);
    this._rowOutlet.viewContainer.clear();
    this.renderRows();
  }

  private _forceRenderHeaderRows() {
    if (!this._headerRowOutlet) {
      return;
    }

    if (this._headerRowOutlet.viewContainer.length > 0) {
      this._headerRowOutlet.viewContainer.clear();
    }

    this._headerRowDefs.forEach((def, i) =>
      this._renderRow(this._headerRowOutlet, def, i)
    );
  }

  private _forceRenderFooterRows() {
    if (!this._headerRowOutlet) {
      return;
    }

    if (this._footerRowOutlet.viewContainer.length > 0) {
      this._footerRowOutlet.viewContainer.clear();
    }
    this._footerRowDefs.forEach((def, i) =>
      this._renderRow(this._footerRowOutlet, def, i)
    );
  }

  private _observeRenderChanges() {
    if (!this.dataSource) {
      return;
    }

    let dataStream: Observable<T[]> | undefined;

    if (this.dataSource instanceof Observable) {
      dataStream = this.dataSource;
    } else if (Array.isArray(this.dataSource)) {
      dataStream = observableOf(this.dataSource);
    }

    if (dataStream === undefined) {
      throw getTableUnknownDataSourceError();
    }

    this._renderChangeSubscription = dataStream
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this._fullData = data;
        this._cashedfullData = data;
        this._filterField(false);
        this._$tableCellConfig.filter = '';
        changeSortField.next(this._$tableCellConfig.sort);
        changeData.next(this._cashedfullData);
        this._changeDetectorRef.markForCheck();
        this.init.emit(this._$tableCellConfig);
        initConfig.next(this._$tableCellConfig);
        setTimeout(() => {
          this._itemHeight = this._$tableCellConfig.itemHeight;
          this._updateItemHeight('' + this._itemHeight);
          this.initData.emit(this._fullData);
        }, 0);
      });
  }

  public _updateData(data) {
    this._data = data || [];
    this.renderRows();
    this.updateData.emit(data);
  }
  public _openSettingsModal() {
    this._settingsModalRef = this.modalService.show(
      this._settingsModalTemplate,
      { class: 'modal-md' }
    );
  }

  public _hideSettingsModal(): void {
    this._settingsModalRef.hide();
  }

  public _settingsModalsubmit() {
    this._tableCellConfig = this._$tableCellConfig;
    this._hideSettingsModal();
    this._headerRowDefChanged = true;
    this._footerRowDefChanged = true;
    this._dataDiffer.diff([]);
    this._rowOutlet.viewContainer.clear();
    this._headerRowOutlet.viewContainer.clear();
    this._footerRowOutlet.viewContainer.clear();
    this._cachedRenderRowsMap.clear();
    this._contentUpdate();
    this.renderRows();
    setTimeout(() => initConfig.next(this._$tableCellConfig));
  }
  public refresh() {
    this._headerRowDefChanged = true;
    this._footerRowDefChanged = true;
    this._dataDiffer.diff([]);
    this._rowOutlet.viewContainer.clear();
    this._headerRowOutlet.viewContainer.clear();
    this._footerRowOutlet.viewContainer.clear();
    this._cachedRenderRowsMap.clear();
    this._contentUpdate();
    this.renderRows();
  }
  ngOnDestroy() {
    this._rowOutlet.viewContainer.clear();
    this._headerRowOutlet.viewContainer.clear();
    this._footerRowOutlet.viewContainer.clear();
    this._cachedRenderRowsMap.clear();
    changeSortField.next(null);

    changeData.next(null);
    initConfig.next(null);
  }

  public _exportXLS(date) {
    if (date) {
      this.exportXLS(date);
    } else {
      console.error('_exportXLS date required');
    }
  }
}
