/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ControlControlIdBody1 } from "../model/controlControlIdBody1";
import { InlineResponse20025 } from "../model/inlineResponse20025";
import { StepControlBody1 } from "../model/stepControlBody1";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";


@Injectable()
export class StepControlService {

  protected basePath = "https://dev.itorum-mr.ru";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Update step control
   *
   * @param controlId Step control id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepControlControlIdPut(controlId: number, body?: ControlControlIdBody1, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20025>;
  public apiStepControlControlIdPut(controlId: number, body?: ControlControlIdBody1, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
  public apiStepControlControlIdPut(controlId: number, body?: ControlControlIdBody1, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
  public apiStepControlControlIdPut(controlId: number, body?: ControlControlIdBody1, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (controlId === null || controlId === undefined) {
      throw new Error("Required parameter controlId was null or undefined when calling apiStepControlControlIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20025>("put", `${this.basePath}/api/step/control/${encodeURIComponent(String(controlId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update step control photo
   *
   * @param controlId Step control id
   * @param file
   * @param type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepControlControlIdSetPhotoPostForm(controlId: number, file?: Blob, type?: string, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20025>;
  public apiStepControlControlIdSetPhotoPostForm(controlId: number, file?: Blob, type?: string, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
  public apiStepControlControlIdSetPhotoPostForm(controlId: number, file?: Blob, type?: string, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
  public apiStepControlControlIdSetPhotoPostForm(controlId: number, file?: Blob, type?: string, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (controlId === null || controlId === undefined) {
      throw new Error("Required parameter controlId was null or undefined when calling apiStepControlControlIdSetPhotoPost.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "multipart/form-data"
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void; };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (file !== undefined) {
      formParams = formParams.append("file", <any>file) as any || formParams;
    }
    if (type !== undefined) {
      formParams = formParams.append("type", <any>type) as any || formParams;
    }

    return this.httpClient.request<InlineResponse20025>("post", `${this.basePath}/api/step/control/${encodeURIComponent(String(controlId))}/set-photo`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create step control
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepControlPost(body?: StepControlBody1, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20025>;
  public apiStepControlPost(body?: StepControlBody1, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20025>>;
  public apiStepControlPost(body?: StepControlBody1, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20025>>;
  public apiStepControlPost(body?: StepControlBody1, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20025>("post", `${this.basePath}/api/step/control`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
