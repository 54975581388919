import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ApiService, UserService } from "app/shared";
import {
  WsRequestIds,
  WsResponseIds,
  IWebSocketResponse,
  WebSocketService
} from './web-socket.service';
import { PermissionService } from '@src/app/shared';
import { Notice } from "@itorum/models";

export type SOUND_TYPE = 'ringtone.mp3' | 'msn_notification.mp3';

export const SOUNDS = {
  CALL: 'ringtone.mp3' as SOUND_TYPE,
  NOTICE: 'msn_notification.mp3' as SOUND_TYPE
};

@Injectable()
export class NoticeService {
  private _notices$: BehaviorSubject<Notice[]> = new BehaviorSubject([]);
  private audio = new Audio();
  public notificationReceived = false;
  public callUp$: Subject<IWebSocketResponse> = new Subject();
  public callDown$: Subject<IWebSocketResponse> = new Subject();
  public waitUp$: Subject<IWebSocketResponse> = new Subject();
  public statusChanged$: Subject<IWebSocketResponse> = new Subject();

  constructor(
    private api: ApiService,
    private webSocket: WebSocketService,
    private userService: UserService,
    private permissionService: PermissionService
  ) {
    webSocket.noticeBus$.subscribe((response: IWebSocketResponse) => {
      this.parseUpcomingEvent(response);
    });

    this.userService.logout$.subscribe(() => this.clearNotices());
  }

  parseUpcomingEvent(response: IWebSocketResponse) {
    switch (response.id) {
      case WsResponseIds.CALL_INCOMING:
        console.log(`Incoming call from "${response.from}"`);
        if (!response.skipConfirm) {
          this.playSound(SOUNDS.CALL);
        }
        this.callUp$.next(response);
        break;
      case WsResponseIds.CALL_INCOMING_TIMEOUT:
        console.log(`Call was cancelled by timeout`);
        this.stopSound();
        this.callDown$.next(response);
        break;
      case WsResponseIds.CALL_INCOMING_CANCELED:
        console.log(`Call was cancelled by caller`);
        this.stopSound();
        this.callDown$.next(response);
        break;
      case WsResponseIds.CALL_INCOMING_CANCEL:
        console.log(`Call was cancelled by caller`);
        this.stopSound();
        this.callDown$.next(response);
        break;
      case WsResponseIds.NEW_NOTICE:
        console.log(`Got new notification`, response.notice);
        if (this.permissionService.getFeature('leftMenu.expert.title')) {
          this.playSound(SOUNDS.NOTICE);
          this.waitUp$.next(response);
        }
        break;
      case WsResponseIds.ACTIVE_NOTICES:
        console.log(`Got notifications array`, response.notices);
        if (this.permissionService.getFeature('leftMenu.expert.title')) {
          this.playSound(SOUNDS.NOTICE);
          this._notices$.next(response.notices);
        }
        break;
      case WsResponseIds.STATUS_CHANGE:
        console.log(
          `technic "${response.user_id}" status "${response.status}"`
        );
        this.statusChanged$.next(response);
        break;
      default:
        // console.log('EVERY TIME DEFAULT CASE WORK', response);
        break;
    }
  }

  public playSound(soundFileName: SOUND_TYPE): void {
    try {
      this.audio.src = `./assets/media/${soundFileName}`;
      this.audio.load();
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => {
      this.audio.play().catch(e => console.error(e));
    }, 0);
  }

  public stopSound() {
    this.audio.pause();
  }

  /**
   * Используем метод в попапе "горячего звонка" чтобы положить трубку
   * @param {string} from
   */
  cancelCall(from: string) {
    this.stopSound();
    this.webSocket.sendData({
      id: WsRequestIds.CALL_INCOMING_RESPONSE,
      from,
      callResponse: 'reject',
      message: 'user declined'
    });
  }

  /**
   * Отправить сообщение о подтверждении готовности к звонку
   * @param {Notice} notice
   */
  acceptReady(notice: Notice) {
    this.webSocket.sendData({
      id: WsRequestIds.CLOSE_NOTICE,
      notice_id: notice.notice_id
    });
    this.deleteNoticeById(notice);
  }

  /**
   * Удаляет уведомление из локального массива
   * @param {Notice} notice
   */
  deleteNoticeById(notice: Notice) {
    const noticeArr = this._notices$.getValue();
    const index = noticeArr.findIndex(
      noticeOfArray => noticeOfArray.notice_id === notice.notice_id
    );
    if (index !== -1) {
      noticeArr.splice(index, 1);
      this._notices$.next(noticeArr);
      console.log('Уведомление в списке было удалено');
    }
  }

  /**
   * Добавить уведомление в массив уведомлений
   * @param {Notice} notice
   */
  updateNotices(notice: Notice) {
    const noticeArr = this._notices$.getValue();
    const index = noticeArr.findIndex(
      noticeOfArray => noticeOfArray.from_user_id === notice.from_user_id
    );

    if (index !== -1) {
      console.log(
        'Уведомление от этого юзера уже существовало - обновлено на более новую версию'
      );
      noticeArr.splice(index, 1);
    }

    noticeArr.push(notice);

    this._notices$.next(noticeArr);
    console.log('Уведомление добавлено в массив уведомлений');
  }

  public get notices$(): Observable<Notice[]> {
    return this._notices$.asObservable();
  }

  deleteNotice(id: number) {
    this.api
      .post('/api/notice', {
        apitype: 'closenotice',
        notice_id: id
      })
      .subscribe(() => {
        const users = this._notices$.getValue();
        const index = users.findIndex(u => u.notice_id === id);
        users.splice(index, 1);
        this._notices$.next(users);
      });
  }

  clearNotices() {
    this._notices$.next([]);
  }
}
