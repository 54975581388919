<div class="my-toast-dialog">
  <div *ngIf="title" [innerHTML]="title"></div>
  <section class="flex justify-between items-center">
    <div>{{ 'common.toastr-call-dialog.incoming-call' | translate }}</div>
    <div class="mx-2" [innerHTML]="text"></div>
  </section>

  <div class="flex flex-row justify-between">
    <button itorum-button class="btn-alert mr-2 uppercase" (click)="cancel()">
<!--      <i class="icon-cross_clear_black pr-2"></i>-->
      {{ 'common.toastr-call-dialog.decline' | translate }}
    </button>
    <button itorum-button style="padding: 0 16px 0 16px" class="btn-small uppercase" (click)="okay()">
<!--      <i class="icon-done_black pr-2"></i>-->
      {{ 'common.toastr-call-dialog.accept' | translate }}
    </button>
  </div>
</div>
