import { CopyStepResponseDataStepInfo } from "./copyStepResponseDataStepInfo";
import { CopyStepResponseDataStepTimes } from "./copyStepResponseDataStepTimes";

export interface CopyStepResponseData {
  id?: number;
  code?: string;
  type?: string;
  status?: CopyStepResponseData.StatusEnum;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  orderNum?: number;
  questionId?: number;
  stepTimes?: CopyStepResponseDataStepTimes;
  stepInfo?: CopyStepResponseDataStepInfo;
  schemeDescription?: any;
}

export namespace CopyStepResponseData {
  export type StatusEnum = 'new' | 'deleted';
  export const StatusEnum = {
    New: 'new' as StatusEnum,
    Deleted: 'deleted' as StatusEnum
  };
}
