import {Injectable, NgZone} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { StorageService } from "@itorum/services";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private logoutMessageTimer: number;
  constructor(
    private storage: StorageService,
    private ngZone: NgZone
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    window.clearTimeout(this.logoutMessageTimer);
    this.ngZone.runOutsideAngular(() => {
      this.logoutMessageTimer = window.setTimeout(() => {
          this.ngZone.run( () => {
            this.preLogoutMessage();
          });
        },
        14 * 60 * 1000 + 40 * 1000
      );

    });

    const options: { setHeaders?: any } = {};
    const token = this.storage.getAuthToken();
    const contentType = request.headers.get('Content-Type');
    const headers: {
      'Content-Type': string;
      Authorization?: string;
    } = { 'Content-Type': contentType ? contentType : 'application/json' };

    if (token) {
      headers['Authorization'] = token;
    }
    options.setHeaders = headers;
    request = request.clone(options);

    if (contentType === 'multipart/form-data') {
      (<any>request).headers.lazyUpdate.splice(0, 1);
      (<any>request).headers.lazyInit.headers.delete('content-type');
      (<any>request).headers.lazyInit.normalizedNames.delete('content-type');
    }
    return next.handle(request);
  }

  preLogoutMessage() {
    console.log(
      'You will be logouted in 20 seconds. To prevent this change the page'
    );
  }
}
