import { AppConfig } from "../interfaces";

export interface TaskReportInterface {
  auto_formed: boolean;
  create_date: string;
  file_name: string;
  file_path: string;
  thumb: string;
  main_version: boolean;
  task_report_id: number;
}
// tslint:disable-next-line:no-empty-interface
export type TaskReportResponse = TaskReportInterface
export class TaskReport implements TaskReportInterface {
  public auto_formed: boolean;
  public create_date: string;
  public file_name: string;
  public file_path: string;
  public thumb: string;
  public main_version: boolean;
  public task_report_id: number;

  constructor(data: TaskReportResponse, appConfig: AppConfig) {
    this.auto_formed = data.auto_formed;
    this.create_date = data.create_date || new Date().toISOString();
    this.file_name = data.file_name;
    this.file_path = appConfig.urlStaticPrefix + data.file_path;
    this.thumb =
      appConfig.urlStaticPrefix + (data.thumb ? data.thumb : data.file_name);
    this.main_version = data.main_version;
    this.task_report_id = data.task_report_id;
  }
}
