import { AppConfig, MeasureInfo } from "@itorum/models";

interface ITaskResultMedia {
  answer_media_id: number;
  task_answer_id: number;
  filename: string;
  filetype: 'photo' | 'audio' | 'video'; // photo, audio or video
  thumb: string;
  sthumb?: string;
  latitude: number;
  longitude: number;
  altitude: number;
  create_date: string; // ISO date
}

interface ITaskResultMediaDefectResponce {
  creation_date: string;
  defect_id: number;
  defect_media_id: number;
  defect_media_type: 'photo' | 'audio' | 'video';
  filename: string;
  recognized_text: string;
  thumb: string;
}

interface ITaskResultMediaDefect {
  create_date: string;
  defect_id: number;
  defect_media_id: number;
  filetype: 'photo' | 'audio' | 'video';
  filename: string;
  recognized_text: string;
  thumb: string;
  sthumb: string;
  fullFileUrl?: string;
}

interface ITaskResultDefectResponce {
  create_date: string;
  defect_id: number;
  description: string;
  object_id: number;
  defect_class_id: number;
  fact_date?: string;
  org_name?: string;
  fct_defect_media: ITaskResultMediaDefect[];
}

export interface ITaskResultDefect {
  defect_id: number;
  description: string;
  media: ITaskResultMediaDefect[];
  audio: ITaskResultMediaDefect;
  object_id: number;
  defect_class_id: number;
  defect_class_name?: string;
  fact_date?: string;
  org_name?: string;
  create_date: string;
}

export interface ITaskResult {
  task_id: number;
  step_id: number;
  code: string;
  text: string;
  answer_id: number;
  answer_text: string;
  measure_value: string;
  text_comment: string;
  media?: ITaskResultMedia[];
  defects?: ITaskResultDefect[];
  ref_task?: any;
  measure_info: MeasureInfo;
  take_measure: boolean;
  early_finish?: boolean;
}

class TaskResultMedia implements ITaskResultMedia {
  answer_media_id: number;
  task_answer_id: number;
  filename: string;
  filetype: 'photo' | 'audio' | 'video';
  thumb: string;
  sthumb: string;
  latitude: number;
  longitude: number;
  altitude: number;
  create_date: string;

  constructor(data: ITaskResultMedia, public appConfig: AppConfig) {
    this.answer_media_id =
      (data.answer_media_id && +data.answer_media_id) || null;
    this.task_answer_id = (data.task_answer_id && +data.task_answer_id) || null;
    this.filename = data.filename || '';
    this.filetype =
      ['photo', 'audio', 'video'].indexOf(data.filetype) !== -1
        ? data.filetype
        : null;
    this.thumb =
      appConfig.urlStaticPrefix + (data.thumb ? data.thumb : data.filename);
    this.sthumb =
      appConfig.urlStaticPrefix +
      (data.thumb
        ? data.thumb
        : data.filetype === 'photo'
        ? 's' + data.filename
        : data.filename);
    this.latitude = (data.latitude && +data.latitude) || null;
    this.longitude = (data.longitude && +data.longitude) || null;
    this.altitude = (data.altitude && +data.altitude) || null;
    this.create_date = data.create_date || null;
  }

  public get fullFileUrl(): string {
    return this.appConfig.urlStaticPrefix + this.filename;
  }
}

export class TaskResultDefect implements ITaskResultDefect {
  defect_id: number;
  description: string;
  media: ITaskResultMediaDefect[];
  audio: ITaskResultMediaDefect;
  object_id: number;
  defect_class_id: number;
  fact_date?: string;
  org_name?: string;
  create_date: string;

  constructor(data: ITaskResultDefectResponce, appConfig: AppConfig) {
    this.defect_id = (data.defect_id && +data.defect_id) || null;
    this.description = (data.description && data.description) || null;
    this.create_date = data.create_date || null;
    this.object_id = data.object_id || null;
    this.defect_class_id = data.defect_class_id || null;
    this.fact_date = data.fact_date || null;
    this.org_name = data.org_name || null;
    if (data.fct_defect_media) {
      this.media = data.fct_defect_media.map(
        el => new TaskResultMediaDefect(<any>el, appConfig)
      );
      this.audio = this.media.find(item => item.filetype === 'audio');
      if (this.audio) {
        this.media.splice(this.media.indexOf(this.audio), 1);
      }
    } else {
      this.media = data.fct_defect_media;
    }
  }
}

export class TaskResultMediaDefect implements ITaskResultMediaDefect {
  create_date: string;
  defect_id: number;
  defect_media_id: number;
  filetype: 'photo' | 'audio' | 'video';
  filename: string;
  recognized_text: string;
  thumb: string;
  sthumb: string;

  constructor(
    data: ITaskResultMediaDefectResponce,
    public appConfig: AppConfig
  ) {
    this.create_date = data.creation_date || null;
    this.defect_id = (data.defect_id && +data.defect_id) || null;
    this.defect_media_id =
      (data.defect_media_id && +data.defect_media_id) || null;
    this.filetype =
      ['photo', 'audio', 'video'].indexOf(data.defect_media_type) !== -1
        ? data.defect_media_type
        : null;
    this.filename = data.filename || '';
    this.recognized_text = data.recognized_text || '';
    this.thumb =
      appConfig.urlStaticPrefix + (data.thumb ? data.thumb : data.filename);
    this.sthumb =
      appConfig.urlStaticPrefix +
      (data.thumb
        ? data.thumb
        : data.defect_media_type === 'photo'
        ? 's' + data.filename
        : data.filename);
  }
  public get fullFileUrl(): string {
    return this.appConfig.urlStaticPrefix + this.filename;
  }
}

export class TaskResult implements ITaskResult {
  task_id: number;
  step_id: number;
  code: string;
  text: string;
  answer_id: number;
  answer_text: string;
  measure_value: string;
  text_comment: string;
  media?: ITaskResultMedia[] = [];
  defects?: ITaskResultDefect[] = [];
  mediaAudio: ITaskResultMedia = null;
  ref_task?: any;
  measure_info: MeasureInfo;
  take_measure: boolean;
  early_finish?: boolean;

  constructor(data: ITaskResult, appConfig: AppConfig) {
    this.task_id = (data.task_id && +data.task_id) || null;
    this.step_id = (data.step_id && +data.step_id) || null;
    this.code = data.code || '';
    this.text = data.text || '';
    this.answer_id = (data.answer_id && +data.answer_id) || null;
    this.answer_text = data.answer_text || '';
    this.measure_value = data.measure_value || '';
    this.text_comment = data.text_comment || '';
    this.ref_task = data.ref_task || null;
    this.measure_info = data.measure_info;
    this.take_measure = data.take_measure;
    this.early_finish = data.early_finish;
    /* if (data.measure_info) {
            this.measure_info = new MeasureInfo(data.measure_info);
        } else {
            this.measure_info = null;
        } */

    // if (Array.isArray(data.media)) {
    //     data.media.forEach((item: ITaskResultMedia) => this.media.push(new TaskResultMedia(item)));
    // }
    if (data.media) {
      this.media = data.media.map(el => new TaskResultMedia(el, appConfig));
      this.mediaAudio = this.media.find(item => item.filetype === 'audio');
    } else {
      this.media = data.media;
    }
    // Удаляю дефекты без аудио
    this.defects = data.defects
      ? data.defects
          .map(item => new TaskResultDefect(<any>item, appConfig))
          .filter((item: ITaskResultDefect) => !!item.audio)
      : data.defects;
  }

  public get isWarn(): boolean {
    // todo: Нужно подсвечивать ответ, но пока не известно какие данные использовать для этого
    return false;
  }

  public get isError(): boolean {
    // todo: Нужно подсвечивать ответ, но пока не известно какие данные использовать для этого
    return false;
  }
}
