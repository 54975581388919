import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  HostBinding
} from '@angular/core';

export interface ChangeEvent {
  start?: number;
  end?: number;
}

@Component({
  selector: 'itr-virtual-scroll,[itrVirtualScroll]',
  templateUrl: './itr-virtual-scroll.component.html',
  styleUrls: ['./itr-virtual-scroll.component.css']
})
export class ItrVirtualScrollComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('style.overflow-y') overflowY = 'auto';
  @Input() public items: any[] = [];
  @Input() public childHeight = 68;
  @Input() pageSize = 20;
  @Output() update: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() nextPage = new EventEmitter<number>();
  @Output() pageIndex = new EventEmitter<number>();
  @Output() scrollTop = new EventEmitter<number>();

  @ViewChild('shim') public shimElementRef: ElementRef;
  @ViewChild('content') public contentElementRef: ElementRef;
  public viewHeight = 0;
  public viewItemsLength = 0;
  public lastTotalHeight = null;
  public totalHeight = 0;
  public lastStart = null;
  public lastEnd = null;
  public start = 0;
  public end = 0;
  public lastScrollTop: number = null;
  constructor(
    public readonly element: ElementRef,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  public refresh() {
    if (!Array.isArray(this.items)) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.carculateItems(true);
        this.eventsLoad();
      }, 0);
    });
  }

  private eventsLoad() {
    this.element.nativeElement.removeEventListener(
      'scroll',
      this.carculateItems.bind(this),
      false
    );
    this.element.nativeElement.addEventListener(
      'scroll',
      this.carculateItems.bind(this),
      false
    );
  }

  private carculateItems(force: boolean = false) {
    this.viewHeight = this.element.nativeElement.clientHeight;
    let childrenHeight = 0;
    let childViewItemsLength = 0;
    const scrollTop = this.element.nativeElement.scrollTop;
    let startChild = 0;
    let startChildHeight = 0;

    const children = Array.from(this.contentElementRef.nativeElement.children);
    if (!children.length) {
      this.viewItemsLength = Math.ceil(this.viewHeight / this.childHeight);
    } else {
      children.forEach((item: any) => {
        if (this.viewHeight >= childrenHeight) {
          childViewItemsLength++;
          childrenHeight = childrenHeight + item.getBoundingClientRect().height;
          if (childrenHeight <= scrollTop) {
            startChild++;
            startChildHeight = childrenHeight;
          }
        }
      });
      if (this.viewHeight > childrenHeight) {
        const otherChildrenLength = Math.floor(
          (this.viewHeight - childrenHeight) / this.childHeight
        );
        childViewItemsLength = childViewItemsLength + otherChildrenLength;
        childrenHeight =
          childrenHeight + otherChildrenLength * this.childHeight;
      }
      this.viewItemsLength = childViewItemsLength;
    }
    if (scrollTop > childrenHeight) {
      const otherChildrenLength = Math.floor(
        (scrollTop - childrenHeight) / this.childHeight
      );
      startChild = startChild + otherChildrenLength;
      startChildHeight =
        startChildHeight + otherChildrenLength * this.childHeight;
    }
    this.totalHeight =
      (this.items.length - childViewItemsLength) * this.childHeight +
      childrenHeight;
    if (this.lastTotalHeight !== this.totalHeight) {
      this.renderer.setStyle(
        this.shimElementRef.nativeElement,
        'height',
        `${this.totalHeight}px`
      );
      this.lastTotalHeight = this.totalHeight;
    }
    this.viewCarculate(true, childrenHeight, startChild, startChildHeight);
  }

  private viewCarculate(
    force: boolean = false,
    childrenHeight: number,
    startChild: number,
    startChildHeight: number
  ) {
    const scrollTop = this.element.nativeElement.scrollTop;
    if (this.lastScrollTop && !force) {
      if (
        scrollTop === this.lastScrollTop ||
        Math.abs(scrollTop - this.lastScrollTop) < 20
      ) {
        return;
      }
    }
    this.lastScrollTop = scrollTop;
    this.start = startChild;
    this.end = this.start + this.viewItemsLength;
    this.end = this.end < this.items.length ? this.end + 1 : this.items.length;
    let paddingTop = 0;
    if (childrenHeight) {
      paddingTop = startChildHeight;
    } else {
      paddingTop = this.start * this.childHeight;
    }
    this.renderer.setStyle(
      this.contentElementRef.nativeElement,
      'transform',
      `translateY(${paddingTop}px)`
    );
    const pageIndex = Math.floor(this.end / this.pageSize);
    this.pageIndex.emit(pageIndex);
    const nextPage = Math.floor((this.end + 5) / this.pageSize);
    this.nextPage.emit(nextPage);

    if (!this.start && this.start !== 0) {
      return;
    }
    if (!this.end && this.end !== 0) {
      return;
    }
    this.zone.run(() => {
      if (this.lastStart !== this.start || this.lastEnd !== this.end || force) {
        this.update.emit(this.items.slice(this.start, this.end));
      }
      this.lastStart = this.start;
      this.lastEnd = this.end;
    });
  }
}
