export interface DefectClassResponseInterface {
  defect_class_id: number;
  defect_class_name: string;
}

export class DefectClass {
  defect_class_id: number;
  defect_class_name: string;

  constructor(obj?: DefectClassResponseInterface) {
    if (!obj) {
      return;
    }
    this.defect_class_id = obj.defect_class_id;
    this.defect_class_name = obj.defect_class_name;
  }
}
