import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IMarkerTag } from "@itorum/models";

export interface TagsState {
  captured: IMarkerTag[];
  free: IMarkerTag[];
}


export const getInitialState = (): TagsState => {
  return {
    captured: [],
    free: []
  }
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({name: 'device-monitor-module'})
export class TagsStore extends Store<TagsState> {
  constructor() {
    super(getInitialState());
  }
}
