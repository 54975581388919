/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DefectCountersBody { 
    type?: DefectCountersBody.TypeEnum;
    createdAtFrom?: string;
    createdAtTo?: string;
    technikId?: number;
}
export namespace DefectCountersBody {
    export type TypeEnum = 'default' | 'year' | 'month' | 'day' | 'dow' | 'hour';
    export const TypeEnum = {
        Default: 'default' as TypeEnum,
        Year: 'year' as TypeEnum,
        Month: 'month' as TypeEnum,
        Day: 'day' as TypeEnum,
        Dow: 'dow' as TypeEnum,
        Hour: 'hour' as TypeEnum
    };
}