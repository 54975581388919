import {
  Component,
  OnInit,
  OnDestroy,
  HostBinding,
  Input,
  ViewChild,
  HostListener,
  ElementRef,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { BehaviorSubject, empty, Observable, Subscription } from 'rxjs';
import {
  columnDefCells,
  changeSortField,
  changeData,
  propertyByString,
  sort,
  initConfig,
  changeFieldFilter,
  changeSearch,
  changeCellWidth,
  resetCellWidth,
  headerCellDragStart,
  headerCellDragEnd
} from '../../table-utils';
import { ArrayToggle } from '@itorum/models';
import { BaseItrCell } from '../../classes/base-itr-cell.class';
import { ItrColumnDefDirective } from '../../directives/itr-column-def.directive';
import { ItrHeaderService } from './../../services/itr-header.service';

const NODE_NAME_LIST = {
  appTableFilter: 'itorum-TABLE-FILTER',
  headerCell: 'ITR-HEADER-CELL'
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'itr-header-cell, th[itr-header-cell]',
  templateUrl: 'itr-header-cell.component.html',
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:use-host-property-decorator
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'columnheader'
  }
})
export class ItrHeaderCellComponent extends BaseItrCell
  implements OnInit, OnDestroy {
  @HostBinding('class') className = 'itr-header-cell';
  @HostBinding('class.itr-header-cell-drag--in') get _dragIn() {
    return this.isDrag;
  }
  @HostBinding('attr.field') get _field() {
    return this.field;
  }
  @Input() type: 'text' | 'filter' | 'search' = 'text';
  @Input() bindValue = 'value';
  @Input() bindLabel = 'label';
  @Input() right = true;
  @Input() search = true;
  @Input() public multi = false;

  /**
   * Не кофигурируемый столбец
   * */
  @Input() public static = false;
  @Input() public set data(data: Array<any>) {
    if (Array.isArray(data)) {
      this.updateData(data);
    }
  }
  // tslint:disable-next-line:no-input-rename
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('sort') public visibleSortField = true;
  @Input() public manageDataFn: any = null;

  @ViewChild(TableFilterComponent) public filterRef: TableFilterComponent;
  @ViewChild('resize') public resizeElement: ElementRef;

  public field: string;
  public sortType: 'asc' | 'desc' = 'asc';
  public showFilter = false;
  public subjData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public obsData: Observable<Array<any>>;
  public countSelected: number = null;
  public title: string;

  public changeSortFieldSubscription: Subscription;
  public changeDataSubscription: Subscription;
  public initConfigSubscription: Subscription;
  public searchInit: string = null;

  /**
   * Пользователь начал ресайз колонки
   */
  public isResizeDown = false;

  /**
   * Пользователь осуществляет ресайз (тянет мышкой за край столбца)
   */
  public isResizeDownMove = false;
  public resizeShiftX = 0;
  public isDrag = false;

  /**
   * Отслеживает клик миши на странице,
   * если был в пределах ячейки - отображает фильтр столбца,
   * если нет - скрывает
   * @param event
   * @param targetElement
   */
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event?: MouseEvent, targetElement?: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showFilter = false;
    }
  }

  /**
   * Отслеживает движение мыши на странице
   * Отслежвает перетаскивание столбцов и ресайзинг столбцов
   * @param event
   * @param targetElement
   */
  @HostListener('document:mousemove', ['$event', '$event.target'])
  public onMousemove(event?: MouseEvent, targetElement?: HTMLElement): void {
    this.elementRef.nativeElement.classList.remove('itr-header-cell-drag--out');
    if (this.isResizeDown) {
      this.isResizeDownMove = true;
      const left =
        event.pageX -
        this.resizeShiftX -
        this.getCoords(this.elementRef.nativeElement).left;
      this.elementRef.nativeElement.style.width = left + 'px';
      this.document.body.style.cursor = 'col-resize';
    }
    if (this.isDrag) {
      let dragEndElement = targetElement;
      let dragEndField = null;
      if (dragEndElement.nodeName !== NODE_NAME_LIST.headerCell) {
        dragEndElement = this.getParent(
          dragEndElement,
          NODE_NAME_LIST.headerCell
        );
      }

      if (!dragEndElement || !dragEndElement.getAttribute) {
        return;
      }

      dragEndField = dragEndElement.getAttribute('field');

      if (
        !this.headerService.getDragEnd().field &&
        this.field !== dragEndField
      ) {
        this.headerService.setDragEnd(dragEndField, dragEndElement);
      } else {
        if (this.headerService.getDragEnd().field !== dragEndField) {
          this.headerService.setDragEnd(dragEndField, dragEndElement);
        }
      }
    } else if (this.headerService.getDragEnd().field === this.field) {
      this.elementRef.nativeElement.classList.add('itr-header-cell-drag--out');
    }
  }

  /**
   * Отслеживание отпускания кнопки мыши,
   * при перетаскивании колонки либо изменения её размера
   * @param event
   */
  @HostListener('document:mouseup', ['$event'])
  public onMouseup(event?: MouseEvent): void {
    this.isResizeDown = false;
    if (this.isResizeDownMove) {
      const left =
        event.pageX -
        this.resizeShiftX -
        this.getCoords(this.elementRef.nativeElement).left;
      changeCellWidth.next({ field: this.field, value: left });
      this.isResizeDownMove = false;
    }
    if (this.isDrag) {
      this.isDrag = false;
      headerCellDragEnd.emit({
        start: this.headerService.dragStartField,
        end: this.headerService.dragEndField
      });
      this.headerService.clear();
    }
    this.document.body.style.cursor = null;
  }

  /**
   * Прослущивание событий мыши, изменение ширины колонки.
   * Начало движния.
   * @param event
   * @param targetElement
   */
  @HostListener('mousedown', ['$event', '$event.target'])
  public onDragStart(event?: MouseEvent, targetElement?: HTMLElement): void {
    if (this.resizeElement.nativeElement === targetElement) {
      this.onResizeDown(event);
    } else {
      const parentTarget = this.getParent(
        targetElement,
        NODE_NAME_LIST.appTableFilter
      );
      if (
        targetElement.nodeName !== NODE_NAME_LIST.appTableFilter &&
        parentTarget.nodeName !== NODE_NAME_LIST.appTableFilter
      ) {
        headerCellDragStart.emit();
        this.isDrag = true;
        this.headerService.setDragStart(
          this.field,
          this.elementRef.nativeElement
        );
      }
    }
  }

  /**
   * Перетаскивание колонки на новую позицию в строке заголовков
   * Начало перетаскивания.
   * @param event
   */
  @HostListener('dragstart', ['$event'])
  public onDragEventStart(event?: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  constructor(
    columnDef: ItrColumnDefDirective,
    public elementRef: ElementRef,
    public route: ActivatedRoute,
    @Inject(DOCUMENT) public document: any,
    public headerService: ItrHeaderService
  ) {
    super(columnDef, elementRef);
    this.field = columnDef.name;
  }

  ngOnInit() {
    this.obsData = this.subjData.asObservable();
    this.title = columnDefCells.get(this.field).label;
    this.changeSortFieldSubscription = changeSortField.subscribe(value => {
      if (!value) {
        return;
      }
      if (value.field !== this.field) {
        this.sortType = 'asc';
      } else if (value.field === this.field) {
        this.sortType = value.sortType;
      }
    });
    if (!this.static) {
      this.changeDataSubscription = changeData.subscribe((data: any[]) => {
        if (data) {
          const arr: ArrayToggle<any> = new ArrayToggle<any>();
          data.forEach(item => {
            const value = propertyByString(item, this.field);
            if (this.multi && Array.isArray(value)) {
              value.forEach(childItem => {
                arr.pushUnique(childItem);
              });
            } else {
              arr.pushUnique(value);
            }
          });
          // console.log('manageDataFn: ', this.manageDataFn && typeof this.manageDataFn === 'function');
          // console.log('manageDataFn obj: ', this.manageDataFn);
          // console.log('arr: ', arr);
          if (this.manageDataFn && typeof this.manageDataFn === 'function') {
            const updatedArr: Array<any> = this.manageDataFn(arr.values);
            if (updatedArr && updatedArr.length) {
              this.subjData.next(updatedArr);
            }
          } else {
            this.subjData.next(sort(arr.values, false, 1));
          }
        }
      });
      this.initConfigSubscription = initConfig.subscribe(config => {
        setTimeout(() => {
          if (!config) {
            return;
          }
          const cell = config.cells.find(item => {
            return item.field.value === this.field;
          });
          if (
            cell &&
            cell.field &&
            cell.field.filter.length &&
            this.filterRef
          ) {
            cell.field.filter.forEach(filter => {
              this.filterRef.selectedList.pushUnique(filter);
            });
            this.countSelected = cell.field.filter.length;
          }
        }, 0);
      });
    } else {
      this.changeDataSubscription = new Subscription();
      this.initConfigSubscription = new Subscription();
    }

    this.route.queryParams.subscribe(params => {
      let fieldValue = params[this.field];
      if (fieldValue) {
        if (this.type === 'search') {
          this.countSelected = 1;
          this.searchInit = fieldValue;
        } else if (this.type === 'filter') {
          fieldValue = fieldValue.split(',').map(item => Number(item));
          this.countSelected = fieldValue.length;
          fieldValue.forEach(item => {
            setTimeout(() => {
              this.filterRef.selectedList.pushUnique(item);
            }, 0);
          });
        }
      }
    });
  }

  public sort() {
    this.sortType = this.sortType === 'asc' ? 'desc' : 'asc';
    changeSortField.next({ field: this.field, sortType: this.sortType });
  }

  public get isShowCountSelected(): boolean {
    return this.countSelected > 0;
  }

  public updateFilter(data: ArrayToggle<Array<any>>) {
    if (!this.static) {
      changeFieldFilter.next({
        field: this.field,
        arr: data,
        multi: this.multi
      });
    } else {
      this.updateSearch({
        field: this.field,
        value: data.values.join(',')
      });
    }
    this.showFilter = false;
    this.countSelected = this.filterRef.selectedList.values.length;
  }

  ngOnDestroy() {
    if (
      this.changeSortFieldSubscription &&
      this.changeSortFieldSubscription.unsubscribe
    ) {
      this.changeSortFieldSubscription.unsubscribe();
    }
    if (
      this.changeDataSubscription &&
      this.changeDataSubscription.unsubscribe
    ) {
      this.changeDataSubscription.unsubscribe();
    }
    if (
      this.initConfigSubscription &&
      this.initConfigSubscription.unsubscribe
    ) {
      this.initConfigSubscription.unsubscribe();
    }
  }

  updateSearch(evt) {
    if (this.type === 'text' || !evt || !evt.field) {
      return;
    }
    if (evt.value) {
      this.countSelected = 1;
    } else {
      this.countSelected = null;
    }
    // const search = changeSearch.getValue();
    changeSearch.next(evt);
  }
  updateData(data: Array<any>) {
    this.subjData.next(data);
  }

  contains(baseElement, innerElement) {
    return baseElement.contains(innerElement);
  }
  onResetResize() {
    resetCellWidth.next({ field: this.field });
  }

  /**
   * Начать пересчёт координат при перетаскивании
   * @param event
   */
  onResizeDown(event) {
    this.resizeShiftX =
      event.pageX - this.getCoords(this.resizeElement.nativeElement).left;
    this.isResizeDown = true;
  }

  /**
   * Получить координаты HTML-элемента
   * на странице
   * @param elem
   */
  getCoords(elem) {
    const box = elem.getBoundingClientRect();
    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    };
  }
  getParent(elem, nodeName: string) {
    while (elem !== document && elem.nodeName !== nodeName) {
      elem = elem.parentNode;
    }
    return elem;
  }
}
