import * as moment from 'moment';
import _date = moment.unitOfTime._date;

export interface IObjectType {
  object_type_id?: number;
  object_type_code?: string;
  object_type_name?: string;
  object_type_desc?: string;
  delete_flag?: boolean;
  create_user_id?: number;
  create_date?: _date;
  object_type_label?: string;
}

/**
 * @deprecated
 */
export class ObjectType {
  public object_type_id?: number;
  public object_type_code?: string;
  public object_type_name?: string;
  public object_type_desc?: string;
  public delete_flag?: boolean;
  public create_user_id?: number;
  public create_date?: _date;
  public object_type_label: string;

  constructor(obj?: IObjectType) {
    if (!obj) {
      return;
    }

    this.object_type_id = obj.object_type_id;
    this.object_type_code = obj.object_type_code;
    this.object_type_name = obj.object_type_name;
    this.object_type_desc = obj.object_type_desc;
    this.delete_flag = obj.delete_flag;
    this.create_user_id = obj.create_user_id;
    this.create_date = obj.create_date;

    if (obj.object_type_code && obj.object_type_name) {
      this.object_type_label = `${obj.object_type_code} ${obj.object_type_name}`;
    }
  }
}
