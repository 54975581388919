<ng-template #hotCall (clickOutside)="noticeService?.stopSound()">
  <div class="flex items-center justify-between">
    <div
      class="modal-close-btn"
      (click)="onCancelCall(this.userIsCalling)"
    ></div>

    <itr-loader *ngIf="callPageLoading"></itr-loader>

    <div class="c-img px-2">
      <img src="assets/img/avatar-green.png" alt="" />
    </div>

    <div class="flex px-2">
        <div class="c-header">{{ 'callAcceptor.callFrom' | translate }} {{ userIsCalling }}</div>
    </div>

    <div class="flex">
        <button
          [disabled]="callPageLoading"
          class="button button--red call-popup__button mr-2"
          (click)="onCancelCall(this.userIsCalling)"
        >
          <i class="icon-call-end"></i> {{ 'callAcceptor.decline' | translate }}
        </button>

        <button
          [disabled]="callPageLoading"
          class="button button--green call-popup__button"
          (click)="onConfirmCall(userIsCalling, callingUserId)"
        >
          <i class="icon-call-new"></i> {{ 'callAcceptor.answer' | translate }}
        </button>
    </div>
  </div>
</ng-template>

<ng-template #waitCall>
  <div>
<!--    <div class="modal-close-btn" (click)="onCancelWait(popupNotice)"></div>-->

    <div class="flex justify-center py-2">
      <img src="assets/img/avatar-green.png" alt="" />
    </div>

    <div class="flex justify-center py-2">
      {{ popupNotice?.msg_text }}
    </div>

    <div class="flex py-2">
      <button
        class="button button--red call-popup__button justify-center mr-2"
        (click)="onCancelWait(popupNotice)"
        >
        {{ 'main.hide' | translate }}
      </button>
      <button
        class="button button--green call-popup__button justify-center"
        (click)="onConfirmWait(popupNotice)"
        >
        {{ 'main.yes' | translate }}
      </button>

    </div>
  </div>
</ng-template>
