import { Injectable } from '@angular/core';
import { Observable, Subject, EMPTY as empty } from 'rxjs';
import { take, filter, debounceTime, timeoutWith, tap } from 'rxjs/operators';

import { AppConfigService } from "app/shared/services/app-config.service";
import { PermissionService } from 'app/shared';
import { StorageService } from '@itorum/services';
import { UserService } from "app/shared";

export enum WsRequestIds {
  CALL_STOP = 'stop',
  CALL_PROCESS = 'call',
  CALL_ICE_CANDIDATE = 'onIceCandidate',
  CALL_GET_ICE_SERVERS = 'getIceServers',
  CALL_REGISTER_EXPERT = 'registerExpert',
  CALL_SAVE_MESSAGE = 'saveMessage',
  CALL_SAVE_IMAGE = 'saveImage',
  CALL_INCOMING_RESPONSE = 'incomingCallResponse',
  CALL_TIMEOUT = 'outgoingCallTimeout',
  CLOSE_NOTICE = 'closeNotice',
  CONNECTION_PONG = 'pong',
  RECORDER_RECORD = 'recorder/record',
  RECORDER_STOP = 'recorder/stop',
  RECORDER_OFFER = 'recorder/offer',
  RECORDER_ICE_CANDIDATE = 'recorder/localCandidate',
  HEARTBEAT_CANCEL = 'heartbeatCancel',
  HEARTBEAT_RESUME = 'heartbeatResume'
}

export enum WsResponseIds {
  CALL_INCOMING = 'incomingCall',
  CALL_INCOMING_TIMEOUT = 'incomingCallTimeout',
  CALL_INCOMING_CANCELED = 'incomingCallCanceled', // syntax on back seem tod
  CALL_INCOMING_CANCEL = 'incomingCallCancel',
  CALL_REGISTER_EXPERT = 'registerResponse',
  CALL_GET_ICE_SERVERS = 'getIceServersResponse',
  CALL_ICE_CANDIDATE = 'iceCandidate',
  /** событие начала видеосвязи */
  CALL_START_COMMUNICATION = 'startCommunication',
  /** события завершения звонка по инициативе техника */
  CALL_STOP_COMMUNICATION = 'stopCommunication',
  /** завершение звонка по причине разрыва связи с техником */
  CALL_CONNECT_RELEASED = 'connectReleased',
  ACTIVE_NOTICES = 'activeNotices',
  CLOSE_NOTICE = 'closeNotice',
  NEW_NOTICE = 'newNotice',
  STATUS_CHANGE = 'userChangeStatus',
  USER_LOGOUT = 'userLogout',
  CONNECTION_PING = 'ping',
  RECORDER_CREATED = 'recorder/created',
  RECORDER_STOPPED = 'record/stopped',
  RECORDER_ICE_CANDIDATE = 'recorder/remoteCandidate',
  RECORDER_ANSWER = 'recorder/answer',
  RECORDER_RECORD_STARTED = 'recorder/recordStarted'
}

export interface IWebSocketRequest {
  id: WsRequestIds;
  [key: string]: any;
}

export interface IWebSocketResponse {
  id: WsResponseIds;
  [key: string]: any;
}

@Injectable()
export class WebSocketService {
  private wSocket: WebSocket;
  private isRetryActive = false;

  private open$$ = new Subject<Event>();
  private data$$ = new Subject<IWebSocketResponse>();
  private error$$ = new Subject<Event>();
  private close$$ = new Subject<CloseEvent>();

  private webCallBus$$ = new Subject<IWebSocketResponse>();
  private noticeBus$$ = new Subject<IWebSocketResponse>();
  private recorderBus$$ = new Subject<IWebSocketResponse>();

  get webCallBus$() {
    return this.webCallBus$$.asObservable();
  }

  get noticeBus$() {
    return this.noticeBus$$.asObservable();
  }

  get recorderBus$() {
    return this.recorderBus$$.asObservable();
  }

  get isConnected(): boolean {
    return this.wSocket && this.wSocket.readyState === this.wSocket.OPEN;
  }

  constructor(
    private userService: UserService,
    private permissionService: PermissionService,
    private appConfigService: AppConfigService,
    private storageService: StorageService
  ) {
    this.listenSocket();

    this.userService.login$.subscribe(() => {
      this.connect();
    });

    this.userService.logout$.subscribe(() => {
      this.disconnect();
    });

    window.onbeforeunload = () => this.disconnect();
  }

  private connect() {
    this.isRetryActive = false;
    if (this.isConnected) {
      return;
    }

    const protocol = location.hostname === 'localhost' ? 'ws' : 'wss';
    const wsDomain = this.appConfigService.appConfig.wsDomain ? this.appConfigService.appConfig.wsDomain : window.location.host;
    const url = `${protocol}://${wsDomain}/expert`;
    // const url = `wss://dev.itorummr.com/expert`;

    try {
      this.wSocket = new WebSocket(
        `${url}?token=${
          this.storageService.getAuthInfo().authToken
        }&device=${this.storageService.getDeviceSerial()}`
      );
      this.wSocket.onopen = evt => this.open$$.next(evt);
      this.wSocket.onmessage = evt => this.data$$.next(JSON.parse(evt.data));
      this.wSocket.onclose = evt => this.close$$.next(evt);
      this.wSocket.onerror = evt => this.error$$.next(evt);
    } catch (error) {
      console.log('reconnect websocket');
    }
  }

  private disconnect() {
    this.isRetryActive = false;
    if (this.isConnected) {
      this.wSocket.close();
      this.wSocket = null;
    }
  }

  private listenSocket() {
    this.data$$.subscribe(response => {
      switch (response.id) {
        case WsResponseIds.CALL_START_COMMUNICATION:
        case WsResponseIds.CALL_STOP_COMMUNICATION:
        case WsResponseIds.CALL_CONNECT_RELEASED:
        case WsResponseIds.CALL_ICE_CANDIDATE:
          this.webCallBus$$.next(response); // to WebCallService
          break;

        case WsResponseIds.CALL_INCOMING:
        case WsResponseIds.CALL_INCOMING_TIMEOUT:
        case WsResponseIds.CALL_INCOMING_CANCELED:
        case WsResponseIds.CALL_INCOMING_CANCEL:
        case WsResponseIds.NEW_NOTICE:
        case WsResponseIds.ACTIVE_NOTICES:
        case WsResponseIds.STATUS_CHANGE:
          this.noticeBus$$.next(response); // to NoticeService
          break;

        case WsResponseIds.RECORDER_CREATED:
        case WsResponseIds.RECORDER_STOPPED:
        case WsResponseIds.RECORDER_ANSWER:
        case WsResponseIds.RECORDER_RECORD_STARTED:
        case WsResponseIds.RECORDER_ICE_CANDIDATE:
          this.recorderBus$$.next(response);
          break;

        case WsResponseIds.CONNECTION_PING:
          this.sendData({
            id: WsRequestIds.CONNECTION_PONG
          });
          break;
        case WsResponseIds.USER_LOGOUT:
          this.userService.logoutBySocket(response);
          break;
        default:
      }

      if (!(this.appConfigService.appConfig.mode === 'PROD')) {
        switch (response.id) {
          case WsResponseIds.CONNECTION_PING:
            break;
          case WsResponseIds.CALL_ICE_CANDIDATE:
            // console.log(response.id);
            break;
          default:
            console.log(
              'socket %cin',
              'color: blue; font-weight: bold',
              response
            );
        }
      }
    });

    this.open$$.subscribe(() => {
      this.sendData({
        id: WsRequestIds.CALL_REGISTER_EXPERT,
        pingPongInterval: 5000
      });
    });

    this.close$$
      .pipe(
        tap(() =>
          this.webCallBus$$.next({ id: WsResponseIds.CALL_CONNECT_RELEASED })
        ),
        debounceTime(3000),
        filter(() => this.storageService.isLoggedIn())
      )
      .subscribe(() => {
        this.connect();
      });
  }

  /**
   * немного странная конструкция, схожа с куском реализации JSON-RPC
   * используется только для получения ICE серверов и последующего запуска звонка
   * Можно заменить на однократный запрос ICE серверов в вызове самого звонка, а сервера хранить в BehaviorSubject
   */
  public request(
    request: IWebSocketRequest,
    waitFor: WsResponseIds,
    timeout = 5000
  ): Observable<IWebSocketResponse> {
    this.sendData(request);
    return this.data$$.pipe(
      filter((response: IWebSocketResponse) => response.id === waitFor),
      take(1),
      timeoutWith(timeout, empty)
    );
  }

  public sendData(data: IWebSocketRequest) {
    if (!this.isConnected) {
      this.open$$.pipe(take(1)).subscribe(() => this.sendData(data));
    }

    if (this.wSocket.readyState === this.wSocket.CLOSED) {
      throw new Error('Web socket already closed state');
    }

    try {
      const message = typeof data === 'string' ? data : JSON.stringify(data);
      this.wSocket.send(message);
      // if (!environment.production) {
      switch (data.id) {
        case WsRequestIds.CONNECTION_PONG:
          break;
        case WsRequestIds.CALL_ICE_CANDIDATE:
          // console.log(data.id);
          break;
        default:
          console.log('socket %cout', 'color: red; font-weight: bold', data);
      }
      // }
    } catch (error) {
      console.error('Error in sendData', error);
    }
  }

  public cancelHeartBeat() {
    this.sendData({
      id: WsRequestIds.HEARTBEAT_CANCEL
    });
  }

  public resumeHeartBeat() {
    this.sendData({
      id: WsRequestIds.HEARTBEAT_RESUME
    });
  }
}
