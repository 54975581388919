import { QueryList, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
// tslint:disable-next-line:max-line-length
export const isoDateRegExp = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
export function mergeQueryListAndSet<T>(
  queryList: QueryList<T>,
  set: Set<T>
): T[] {
  return queryList.toArray().concat(Array.from(set));
}
export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}
export function propertyByString(obj, prop) {
  if (!prop) {
    return;
  }
  if (prop.indexOf('.') === -1) {
    return obj[prop];
  }
  const parts = prop.split('.'),
    last = parts.pop(),
    l = parts.length;
  let i = 1;
  let current = parts[0];

  while ((obj = obj[current]) && i < l) {
    current = parts[i];
    i++;
  }

  if (obj) {
    return obj[last];
  }
}
export function compareDate(dateTimeA, dateTimeB) {
  const momentA = moment(dateTimeA);
  const momentB = moment(dateTimeB);
  if (momentA > momentB) {
    return 1;
  } else if (momentA < momentB) {
    return -1;
  } else {
    return 0;
  }
}
export function compareIsoDate(dateTimeA, dateTimeB) {
  if (!isoDateRegExp.test(dateTimeA) && !isoDateRegExp.test(dateTimeB)) {
    return 0;
  }
  if (isoDateRegExp.test(dateTimeA) && !isoDateRegExp.test(dateTimeB)) {
    return 1;
  }
  if (!isoDateRegExp.test(dateTimeA) && isoDateRegExp.test(dateTimeB)) {
    return -1;
  }
  return compareDate(moment(dateTimeA).format(), moment(dateTimeB).format());
}
export function isISODate(str) {
  return isoDateRegExp.test(str);
}
export function sort(arr, field, revert) {
  function numberNormilize(num) {
    if (!num.length) {
      return num;
    }

    if (num.indexOf('0') !== -1) {
      let coeficient = 1;
      for (let i = 0; i < num.length; i++) {
        if (num[i] === '0') {
          coeficient = coeficient * 10;
        } else {
          break;
        }
      }
      return parseFloat(num) / coeficient;
    } else {
      return parseFloat(num);
    }
  }

  return arr.sort((a, b) => {
    let a_value, a_full_value, b_value, b_full_value, typeA, typeB;
    a_value = field ? propertyByString(a, field) || '' : a || '';
    b_value = field ? propertyByString(b, field) || '' : b || '';
    if (!a_value && b_value) {
      return 1;
    }

    if (a_value && !b_value) {
      return -1;
    }

    typeA = isNaN(parseFloat(a_value)) ? typeof a_value : 'number';
    typeB = isNaN(parseFloat(b_value)) ? typeof b_value : 'number';

    const cyrillic_EXP = /[а-яА-ЯЁё]/;
    if (isISODate(a_value) || isISODate(b_value)) {
      return compareIsoDate(a_value, b_value) * revert;
    }

    a_value = typeA === 'string' ? a_value.toLocaleLowerCase() : a_value;
    b_value = typeB === 'string' ? b_value.toLocaleLowerCase() : b_value;

    if (typeA !== 'number' && a_value.length) {
      a_value = a_value.trim ? a_value.trim() : a_value;
      a_full_value = a_value;
      a_value = a_value[0];
    }

    if (typeB !== 'number' && b_value.length) {
      b_value = b_value.trim ? b_value.trim() : b_value;
      b_full_value = b_value;
      b_value = b_value[0];
    }

    if (typeA === 'string' && typeB === 'string') {
      // is cirillic && latin sort
      if (!cyrillic_EXP.test(a_value) && cyrillic_EXP.test(b_value)) {
        return -1 * revert;
      }
      if (cyrillic_EXP.test(a_value) && !cyrillic_EXP.test(b_value)) {
        return 1 * revert;
      }
    }
    // is number sort
    if (typeA === 'number' && typeB === 'string') {
      return -1 * revert;
    }
    if (typeA === 'string' && typeB === 'number') {
      return 1 * revert;
    }

    if (typeA === 'number' && typeB === 'number') {
      a_value = numberNormilize(a_value);
      b_value = numberNormilize(b_value);
    }

    if (a_value < b_value) {
      return -1 * revert;
    } else if (a_value > b_value) {
      return 1 * revert;
    } else {
      if (typeA === 'string' && typeB === 'string' && a_value === b_value) {
        if (a_full_value < b_full_value) {
          return -1 * revert;
        } else if (a_full_value > b_full_value) {
          return 1 * revert;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  });
}

export const ITEM_HEIGHT_BIG = 68;
export const ITEM_HEIGHT_MIDDLE = 50;
export const ITEM_HEIGHT_LITTLE = 38;

export const ITEM_HEIGHT_LIST = {
  [ITEM_HEIGHT_BIG]: 'big',
  [ITEM_HEIGHT_MIDDLE]: 'middle',
  [ITEM_HEIGHT_LITTLE]: 'little'
};

export function getNativeScrollbarWidth(): number {
  const element = document.createElement('div');
  element.style.position = 'absolute';
  element.style.top = '-9999px';
  element.style.width = '100px';
  element.style.height = '100px';
  element.style.overflow = 'scroll';
  // element.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(element);
  const sw = element.offsetWidth - element.clientWidth;
  document.body.removeChild(element);
  return sw;
}
export const columnDefCells = new Map();
export const changeSortField: BehaviorSubject<any> = new BehaviorSubject<any>(null);
export const changeData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
export const changeFieldFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
export const initConfig: BehaviorSubject<any> = new BehaviorSubject<any>(null);
export const _render: EventEmitter<any> = new EventEmitter();
export const changeSearch = new BehaviorSubject<any>({ field: '0', value: '0' });
export const changeCellWidth = new BehaviorSubject<any>({
  field: null,
  value: null
});
export const resetCellWidth = new BehaviorSubject<any>({ field: null });
export const headerCellDragStart: EventEmitter<any> = new EventEmitter();
export const headerCellDragEnd: EventEmitter<any> = new EventEmitter();
