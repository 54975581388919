/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActiontemplateMasterBody } from '../model/actiontemplateMasterBody';
import { IdItemsBody } from '../model/idItemsBody';
import { ActionTemplateMasterResponce } from '../model/actionTemplateMasterResponce';
import { InlineResponse20039 } from '../model/inlineResponse20039';
import { InlineResponse20040 } from '../model/inlineResponse20040';
import { InlineResponse20041 } from '../model/inlineResponse20041';
import { MasterIdBody } from '../model/masterIdBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ActionMasterTemplateService {

    protected basePath = 'https://dev.itorummr.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get action template master items
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20039>;
    public apiActionTemplateMasterGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20039>>;
    public apiActionTemplateMasterGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20039>>;
    public apiActionTemplateMasterGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse20039>('get',`${this.basePath}/api/action-template/master`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete action template master
     *
     * @param id Action template master id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<ActionTemplateMasterResponce>;
    public apiActionTemplateMasterIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiActionTemplateMasterIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActionTemplateMasterResponce>('delete',`${this.basePath}/api/action-template/master/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get action template master items
     *
     * @param id Action template master id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterIdItemsGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20041>;
    public apiActionTemplateMasterIdItemsGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20041>>;
    public apiActionTemplateMasterIdItemsGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20041>>;
    public apiActionTemplateMasterIdItemsGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiActionTemplateMasterIdItemsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse20041>('get',`${this.basePath}/api/action-template/master/${encodeURIComponent(String(id))}/items`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete action template master items
     *
     * @param id Action template master id
     * @param itemId Action template master item id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterIdItemsItemIdDelete(id: number, itemId: number, observe?: 'body', reportProgress?: boolean): Observable<ActionTemplateMasterResponce>;
    public apiActionTemplateMasterIdItemsItemIdDelete(id: number, itemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdItemsItemIdDelete(id: number, itemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdItemsItemIdDelete(id: number, itemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiActionTemplateMasterIdItemsItemIdDelete.');
        }

        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling apiActionTemplateMasterIdItemsItemIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActionTemplateMasterResponce>('delete',`${this.basePath}/api/action-template/master/${encodeURIComponent(String(id))}/items/${encodeURIComponent(String(itemId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create action template master items
     *
     * @param id Action template master id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterIdItemsPost(id: number, body?: IdItemsBody, observe?: 'body', reportProgress?: boolean): Observable<ActionTemplateMasterResponce>;
    public apiActionTemplateMasterIdItemsPost(id: number, body?: IdItemsBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdItemsPost(id: number, body?: IdItemsBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActionTemplateMasterResponce>>;
    public apiActionTemplateMasterIdItemsPost(id: number, body?: IdItemsBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiActionTemplateMasterIdItemsPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ActionTemplateMasterResponce>('post',`${this.basePath}/api/action-template/master/${encodeURIComponent(String(id))}/items`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update action template master
     *
     * @param id Action template master id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterIdPut(id: number, body?: MasterIdBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20040>;
    public apiActionTemplateMasterIdPut(id: number, body?: MasterIdBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20040>>;
    public apiActionTemplateMasterIdPut(id: number, body?: MasterIdBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20040>>;
    public apiActionTemplateMasterIdPut(id: number, body?: MasterIdBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiActionTemplateMasterIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse20040>('put',`${this.basePath}/api/action-template/master/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create action template master
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiActionTemplateMasterPost(body?: ActiontemplateMasterBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20040>;
    public apiActionTemplateMasterPost(body?: ActiontemplateMasterBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20040>>;
    public apiActionTemplateMasterPost(body?: ActiontemplateMasterBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20040>>;
    public apiActionTemplateMasterPost(body?: ActiontemplateMasterBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse20040>('post',`${this.basePath}/api/action-template/master`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
