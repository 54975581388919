import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { ItrColumnDefDirective } from './itr-column-def.directive';
import { BaseItrCell } from '../classes/base-itr-cell.class';

/**
 * @deprecated
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'itr-cell, td[Itr-cell]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'itr-cell',
    role: 'gridcell'
  }
})
export class ItrCellDirective extends BaseItrCell implements OnInit {
  @Input() public overflow = true;
  constructor(columnDef: ItrColumnDefDirective, public elementRef: ElementRef) {
    super(columnDef, elementRef);
  }

  ngOnInit() {
    if (!this.overflow) {
      this.elementRef.nativeElement.classList.add('itr-cell__overflow');
    }
  }
}
