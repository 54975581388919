import { Directive, Input, ContentChild } from '@angular/core';
import { CanStick, mixinHasStickyInput } from '../can-stick';
import { columnDefCells, coerceBooleanProperty } from '../table-utils';
import { ItrCellDefDirective } from './itr-cell-def.directive';
import { ItrHeaderCellDefDirective } from './itr-header-cell-def.directive';
import { ItrFooterCellDefDirective } from './itr-footer-cell-def.directive';

class ItrColumnDefBase {}
const _ItrColumnDefBase = mixinHasStickyInput(ItrColumnDefBase);

@Directive({
  selector: '[itrColumnDef]',
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['sticky']
})
export class ItrColumnDefDirective extends _ItrColumnDefBase
  implements CanStick {
  @Input('itrColumnDef')
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    if (!name) {
      return;
    }
    this._name = name;
    columnDefCells.set(name, {
      visible: true
    });
    this.cssClassFriendlyName = name.replace(/[^a-z0-9_-]/gi, '-');
  }
  _name: string;

  @Input()
  get visible(): boolean {
    return this._visible;
  }
  set visible(visible: boolean) {
    this._visible = visible;
    columnDefCells.set(
      this.name,
      Object.assign({}, columnDefCells.get(this.name), { visible })
    );
  }
  _visible = true;

  @Input()
  get title(): string {
    return this._title;
  }
  set title(title: string) {
    this._title = title;
    columnDefCells.set(
      this.name,
      Object.assign({}, columnDefCells.get(this.name), { label: title })
    );
  }
  _title: string;

  @Input()
  get widthRow(): any {
    return this._width;
  }
  set widthRow(width: any) {
    this._width = parseFloat(width);
    columnDefCells.set(
      this.name,
      Object.assign({}, columnDefCells.get(this.name), { width: this._width })
    );
  }
  _width: number;

  @Input()
  get breakWordSymbol(): boolean {
    return this._breakWordSymbol;
  }
  set breakWordSymbol(breakWordSymbol: boolean) {
    columnDefCells.set(
      this.name,
      Object.assign({}, columnDefCells.get(this.name), { breakWordSymbol })
    );
  }
  _breakWordSymbol: boolean;

  @Input('stickyEnd')
  get stickyEnd(): boolean {
    return this._stickyEnd;
  }
  set stickyEnd(v: boolean) {
    const prevValue = this._stickyEnd;
    this._stickyEnd = coerceBooleanProperty(v);
    this._hasStickyChanged = prevValue !== this._stickyEnd;
  }
  _stickyEnd = false;

  @ContentChild(ItrCellDefDirective) cell: ItrCellDefDirective;
  @ContentChild(ItrHeaderCellDefDirective)
  headerCell: ItrHeaderCellDefDirective;
  @ContentChild(ItrFooterCellDefDirective)
  footerCell: ItrFooterCellDefDirective;
  cssClassFriendlyName: string;
  sticky: boolean;
  _hasStickyChanged: boolean;
  hasStickyChanged(): boolean {
    return this.sticky;
  }
  resetStickyChanged(): void {
    this.sticky = false;
  }
}
